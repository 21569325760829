import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as jwt_decode from "jwt-decode";
import dexFreight, { Microservices } from "../../microservices";
import { Subject, Subscription } from "rxjs";
import { AppConfig } from "../../app.config";
import { ConstantsService } from "../contants/constants.service";
import { ListenerService } from "../listener/listener.service";
import { AuthService } from "../back-services/auth.service";
import { UserService } from "src/app/user.service";
import { CompanyService } from "src/app/company.service";
@Injectable()
export class MainService {
  hdr: Hdr;
  httpOptions: any;
  loads: any[];
  title: string;
  public user: any;
  public company: any;
  private loading: Subject<boolean> = new Subject();
  loading$ = this.loading.asObservable();
  equipmentsType: Array<equipentType> = [];
  listeners: Subscription[] = [];
  constructor(
    public cts: ConstantsService,
    private http: HttpClient,
    public ac: AppConfig,
    public listener: ListenerService,
    public micro: Microservices,
    public snackbar: MatSnackBar,
    private authService: AuthService,
    private userService: UserService,
    private companyService: CompanyService,
  ) {
    this.loads = [];
    if (localStorage.role != "super-admin" && localStorage.role != undefined)
      this.getUserGlobal();

    this.listener.addListener("updateUserGlobalMainService",this.getUserGlobal.bind(this));
  }

  setupListeners () {
    this.listeners.push(
      this.userService.user.subscribe(user => this.user = user),
      this.companyService.company.subscribe(company => this.company = company),
      this.companyService.companyLogo.subscribe(logo => this.company.logo = logo),
      this.authService.token.onClear(() => {
        localStorage.clear();
        sessionStorage.clear();
        
        this.listener.dispatchEvent('unsubscribeObs@loading');
      })
    );
  }

  removeListeners () {
    this.listeners.forEach(listener => listener.unsubscribe());
  }

  //* Clears all the application state
  async clear() {
    this.authService.clear();
    // this.userService.clear();
    // this.authService.clear();
    // this.companySettingsService.clear();
    // this.documentsService.clear();
  }

  /**
   * This function marks the fields of a reactive form as visited
   * @param formGroup
   */
  formGroupVisited(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) this.formGroupVisited(control);
    });
  }
  /**
   * This function is to validate the required fields of a reactive form
   * @param formGroup
   */
  validateFormGroup(formGroup: UntypedFormGroup): boolean {
    this.formGroupVisited(formGroup);
    return formGroup.valid;
  }

  /**
   * Function to show a quick alert
   * @param message
   */
  showQuickAlert(message: string, duration?: number): void {
    this.snackbar.open(message, "", { duration: duration || 600 });
  }

  /**
   * Return an array of equipments from the  enum('EQUIPMENT_NAMES) in the back
   */
  async getEquipmentsType(operation: "mx" | "us" = 'us') {
    this.equipmentsType = [];
    const equipments = await dexFreight.Enums.getMap(operation === "us" ? "EQUIPMENT_NAMES" : "EQUIPMENT_NAMES_MX");
    const keys: Array<any> = Object.keys(equipments);
    const value: Array<any> = Object.values(equipments);
    for (let index = 0; index < value.length; index++) {
      this.equipmentsType.push({
        name: value[index],
        value: keys[index],
      });
    }
    return this.equipmentsType;
  }

  async getPaymentMethods() {
    try {
      const payments = await dexFreight.Enums.getMap("PAYMENT_METHODS");
      return payments;
    } catch (e) {
      console.log(e);
    }
  }

  async getPaymentTerms() {
    try {
      const terms = await dexFreight.Enums.getMap("PAYMENT_TERMS");
      return terms;
    } catch (e) {
      console.log(e);
    }
  }
  /**
+     *  With this function the load is displayed on the page;
+     * @param opt boolean value to display the loading. true = SHOW, false = HIDE;
+     * @author 🔥
+     */
  hideOrShowLoading(opt: boolean) {
    this.loading.next(opt);
  }
  getItemLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  cloneObject(objeto, modifier) {
    for (let key in modifier) {
      if (modifier[key] instanceof Array) {
        objeto[key] = [...modifier[key]];
        for (let i = 0; i < objeto[key].length; i++) {
          if (typeof modifier[key][i] === "object") {
            this.cloneObject(objeto[key][i], modifier[key][i]);
          }
        }
      } else if (
        typeof modifier[key] === "object" &&
        !(modifier[key] instanceof Date)
      ) {
        objeto[key] = {};
        this.cloneObject(objeto[key], modifier[key]);
      } else {
        objeto[key] = modifier[key];
      }
    }
  }

  getMainUserClone() {
    let user = {};
    this.cloneObject(user, this.user);
    return user;
  }

  getMainCompanyClone() {
    let company = {};
    this.cloneObject(company, this.company);
    return company;
  }

  async getUserGlobal() {
    try {
      this.listener.dispatchEvent("getDocuments@inProfile");
      return await this.userService.user.get();
    } catch (excUserGlobal) {
      console.log({ excUserGlobal });
    }
  }

  setHeaderCors() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }),
    };
  }

  /**
   * Funtion to set required validators from a form
   * @param form
   */
  setRequiredValidators(form: UntypedFormGroup): void {
    for (const key in form.controls) {
      form.get(key).setValidators(Validators.required);
      form.get(key).updateValueAndValidity();
    }
  }

  /**
   * Function to remove validators of all fields of formControl type of a reactive form.
   * @param form
   */
  removeValidatorsFormGroup(form: UntypedFormGroup): void {
    Object.values(form.controls).forEach((field: UntypedFormControl) => {
      field.clearValidators();
    });
  }

  /**
   *
   * Funtion to check if the fields of a reactive form have been changed by returning an object with the modified fields
   * @param form
   */
  checkModifiedFields(form: UntypedFormGroup): Object {
    let obj = {};
    let filters = Object.entries(form.controls).filter(
      (field) => field[1].dirty
    );
    filters.forEach((element: any) => {
      element[1] = element[1].value;
      const [key, value] = element;
      obj[key] = value;
    });
    return obj;
  }

  /**
   * this function decode jwt token
   * @param token token to decode
   */
  getDecodedAccessToken(token): any {
    try {
      return jwt_decode(token);
    } catch (error) {
      console.log({ error });
    }
  }

  /**
   * this decode the US states from url code (shipment list query params)
   */
  decodeStates(a, b) {
    const str =
      a
        .toString(2)
        .padStart(Math.floor(this.cts.getUSStates().length / 2), "0") +
      b
        .toString(2)
        .padStart(Math.floor(this.cts.getUSStates().length / 2) + 1, "0");
    const states = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === "1") states.push(this.cts.getUSStates()[i]);
    }
    return states;
  }

  imgBroke(objeto, type) {
    if (type === "user") objeto.profile.picture = "/assets/img/avatar.png";
    else if (type === "company") objeto.logo = "/assets/img/avatar.png";
  }

  /* USER FUNCTIONS */

  // get user by id
  getUser(id) {
    return this.http.get(this.ac.dxfr8.api + "users/" + id);
  }

  // Invite companies
  inviteCompanies(obj) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "shipments/invite",
      obj,
      this.hdr
    );
  }

  // accept or disbled users for my company
  associateUsers(obj) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "companies/associates/new",
      { company: obj },
      this.hdr
    );
  }

  // upload profile picture
  uploadPhoto(image) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "users/profile/picture",
      image,
      this.hdr
    );
  }

  // upload company logo
  uploadCompanyLogo(logo) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.apiObject.api + "/companies/upload-logo",
      logo,
      this.hdr
    );
  }
  /**
   * get company info by MC number
   * @param query_string
   */
  async searchFMCSAByMC(query_string) {
    const html: any = await this.http.post(
      `https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=MC_MX&query_string=${query_string.replace(
        /[^0-9]/g,
        ""
      )}`,
      {},
      {
        headers: {
          Accept:
            "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3",
          "Content-Type": "application/x-www-form-urlencoded",
          cookie:
            "_ga=GA1.2.147677382.1566595302; _ga=GA1.3.147677382.1566595302; __utma=195784507.147677382.1566595302.1566595362.1566595362.1; __utmz=195784507.1566595362.1.1.utmcsr=(direct)|utmccn=(direct)|utmcmd=(none); ASPSESSIONIDQGBDBBQQ=ABJFGNPAAIKAFCMAGOGPMLHE; AWSALB=EzpDsIfjUYuJIvJf1l++R6ZGR9p0EWkKCFMRNYuRWz0G9EmM4+9NSFp5a5n8YE3JwJIWZgYzfZquGh+f8+M/q/hcAugveHOlzymp9bycxtkl6yKZbk7PeDhRxodX",
          origin: "https://safer.fmcsa.dot.gov",
          referer: "https://safer.fmcsa.dot.gov/query.asp",
          "sec-fetch-mode": "navigate",
          "sec-fetch-site": "same-origin",
          "sec-fetch-user": "?1",
          "upgrade-insecure-requests": "1",
          "user-agent":
            "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.90 Safari/537.36",
        },
      }
    );
    const data = {};
    html.data.replace(
      new RegExp(
        '<a class="querylabel.*?".*?>(.+?)</a>.+?<td.*?>(.+?)</td>',
        "sgi"
      ),
      ($0, label, field) => {
        data[label.trim()] = field
          .replace(/\\r|\\n|(?:&[a-z0-9]+;|(<.+?>))/g, "")
          .replace(/\s+/g, " ")
          .trim();
      }
    );

    return data;
  }


  deniedDoc(obj) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "shipments/deny-doc",
      obj,
      this.hdr
    );
  }

  editUserInfo(user) {
    this.setHeaderCors();
    return this.http.post(
      this.ac.dxfr8.api + "users/edit",
      { user: user },
      this.httpOptions
    );
  }

  /* SHIPMENTS FUNCTIONS */

  // add a new load to the loads array
  addLoad(load) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "shipments/create",
      { shipment: load },
      this.hdr
    );
  }
  // edit a load
  editShipment(load) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "shipments/edit",
      { shipment: load },
      this.hdr
    );
  }

  // Get location by zipcode
  getLocationByZip(zipcode) {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=AIzaSyBRj3F0Fm97UhNsImo_ZCGWtud4FnL8Nd0`
    );
  }

  // return the load identified with id
  getLoad(id) {
    this.setHdr();
    return this.http.get(this.ac.dxfr8.api + "shipments/" + id, this.hdr);
  }
  // soft delete a load
  removeShipment(shipment) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "shipments/remove",
      { shipment: shipment },
      this.hdr
    );
  }

  /*BIDS*/
  // accept or decline bids
  postAuctionBids(_bid) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "bids/auction",
      { bid: _bid },
      this.hdr
    );
  }
  // get users
  getUsers(obj) {
    return this.http.post(this.ac.dxfr8.api + "users/fetch", { query: obj });
  }
  // Enable or disable equipments
  equipmentUpdate(equipment) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "equipments/update",
      { equipment: equipment },
      this.hdr
    );
  }

  // delete an equipment from my company
  removeEquipments(equipment) {
    this.setHdr();
    return this.http.post(
      this.ac.dxfr8.api + "equipments/remove",
      { equipment: equipment },
      this.hdr
    );
  }

  // set token to header
  setHdr() {
    this.hdr = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  /* past data bewtween components    */

  // set data with a key and value
  setLocalValue(key: string, _value, keep?: boolean) {
    this.loads[key] = _value;
    if (keep) localStorage[key] = JSON.stringify(_value);
  }
  // get the data using the key  at the setlocalvalue function
  getLocalValue(key: string, keep?: Boolean) {
    const _value = this.loads[key]
      ? this.loads[key]
      : JSON.parse(localStorage.getItem(key));
    this.loads[key] = _value;
    if (!keep) {
      delete this.loads[key];
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(_value));
    }
    return _value;
  }

  setTitle(_title) {
    localStorage.setItem("@title-navigation@", _title);
    this.title = _title;
  }

  getTitle() {
    return this.title ? this.title : localStorage.getItem("@title-navigation@");
  }

  getCityFromLocation({ lat, lng }) {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBRj3F0Fm97UhNsImo_ZCGWtud4FnL8Nd0`
    );
  }

  getLatLng(zipCode) {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=AIzaSyBRj3F0Fm97UhNsImo_ZCGWtud4FnL8Nd0`
    );
  }
  // clear object befored send it
  async cleanObject(object: any) {
    let array;
    if (object) {
      array = Object.keys(object);
    }
    if (!array) return;
    for (let i = 0, key; (key = array[i++]);) {
      if (typeof object[key] === "object" && !(object[key] instanceof Date)) {
        await this.cleanObject(object[key]);
      }
      if (
        !object[key] ||
        JSON.stringify(object[key]) === "{}" ||
        (object[key] instanceof Array && object[key].length == 0)
      ) {
        delete object[key];
      }
    }
  }

  ramdonText(length) {
    const ref =
      "$@$!%*?&abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ$@$!%*?&";
    let str = "";
    for (var i = 0; i < length; i++) {
      str += ref.charAt(Math.floor(Math.random() * ref.length));
    }
    return str;
  }

  getShipmentProgress(current) {
    const status = [
      "new",
      "open",
      "awarded",
      "readyForPickup",
      "inTransit",
      "delivered",
    ];
    const index = status.indexOf(current);
    return ((index + 1) / status.length) * 100;
  }

  async invoiceDashboardStats(payload, token) {
    return await this.micro.DexCore.Invoices.stats(payload, token);
  }

  async invoiceDashboardCalendar(payload, token) {
    return await this.micro.DexCore.Invoices.calendar(payload, token);
  }

  async invoiceCalendarStatsDetails(payload, token) {
    return await this.micro.DexCore.Invoices.statsDetails(payload, token);
  }
  getMxnValue() {
    let url = "https://api.exchangerate.host/convert?from=USD&to=MXN";
    return this.http.get(`${url}`);
  }

  /**
   * Method to copy a value to clipboard
   * @param value
   */
  copyText(value: string) {
    const { workingRules } = this.micro.getCurrentSetting;

    let selector = document.createElement("textarea");
    selector.style.position = "fixed";
    selector.value = value;
    document.body.appendChild(selector);
    selector.focus();
    selector.select();
    document.execCommand("copy");
    document.body.removeChild(selector);

    sessionStorage.lang
    this.showQuickAlert(!['mx', 'co'].includes(workingRules) ? "Copied to clipboard!" : '¡Copiado al portapapeles!');
  }
}

interface Hdr {
  headers: {
    Authorization: string;
  };
}

export interface equipentType {
  name: string;
  value: string;
}
