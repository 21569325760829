import { Injectable } from '@angular/core';
import { ServicesDrayage } from '../../interfaces';
import dexfreight, { Microservices } from '../../microservices';
import { HttpClient } from '@angular/common/http';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { MainService } from '../main/main.service';
import { DrayageNegotiationService } from './drayage-negotiation.service';
import { AlertsService } from 'src/app/alerts.service';
import { EnumsService } from 'src/app/enums.service';

@Injectable({
  providedIn: 'root'
})
export class DrayageService {

  constructor(
    public micro: Microservices,
    private _ms: MainService,
    public http: HttpClient,
    private _drayageNegotiationService: DrayageNegotiationService,
    private alertsService: AlertsService,
    private enumsService: EnumsService
  ) { }

  async getAllServices(token: string) {
    return await this.enumsService.drayageServices.get();
  }

  getPaymentByState(state: string, payment): number {
    return ['new', 'open'].includes(state) ? payment?.open : payment?.close;
  }

  /**
   * It's to return the payment status of a drayage shipment
   * @param shipmentState
   * @returns
   */
  getPaymentStatus(shipmentState: string): 'open' | 'close' {
    return ['new', 'open', 'cancelled'].includes(shipmentState) ? 'open' : 'close';
  }

  /**
   * It's to search a service and return your quantity
   * @param serviceName
   */
  qtyService(services: any[], serviceName: string): number {
    return services?.find(service => service.type === serviceName)?.qty;
  }

  /**
   * It's to calculate the fsc service price
   */
  getPriceFsc(services: any[]): number {
    if (services?.length > 0) {
      let dry = services.find(service => service?.type.toLowerCase() === 'drayage');
      // let fsc = services.find(service => service?.type.toLowerCase() === 'fsc');

      !dry ? dry = { type: 'Drayage', rate: 0 } : null;
      // !fsc ? fsc = { type: 'FSC', rate: 0 } : null;

      // return Number(dry.rate * fsc.rate / 100);
      return Number(dry.rate)
    }
  }

  /**
   * It's to calculate the total price of a service
   */
  totalServicePrice(service, allServices, fixedServices?): number {
    const { type, qty, rate } = service;
    const serviceQuantity = qty || service.quantity || 1;

    let total = 0;

    if (type && type.toLowerCase() === 'fsc') {
      total += this.getPriceFsc(fixedServices) * serviceQuantity;
    } else {
      total += rate * serviceQuantity;
    }

    return total;
  }

  /**
   * It's to calculate the total price of services without allServices array
   */
  getTotalPriceServices(services: any[], otherServices?: any[]): number {
    let total = 0;

    if (services?.length > 0) {
      total += this.calculateServiceTotal(services);
    }

    if (otherServices?.length > 0) {
      total += this.calculateOtherServiceTotal(otherServices);
    }

    return Number(total);
  }

  /**
   * It's to calculate the total price of the drayage
   */
  totalDrayagePrice(allServices: any[], services: any[], otherServices?: any[]): number {
    let total = 0;

    if (services?.length > 0) {
      total += this.calculateServiceTotal(services);
    }

    if (otherServices?.length > 0) {
      total += this.calculateOtherServiceTotal(otherServices);
    }

    return Number(total);
  }

  calculateServiceTotal(services: any[]): number {
    let total = 0;

    services.forEach(service => {
      const { qty, rate } = service;
      const serviceQuantity = (qty || service.quantity) || 1;

      total += Number(rate) * Number(serviceQuantity);
    });

    return Number(total);
  }

  calculateOtherServiceTotal(otherServices: any[]): number {
    let total = 0;

    otherServices.forEach(otherService => {
      total += Number(otherService.rate) * Number(otherService.qty || otherService.quantity);
    });

    return Number(total);
  }


  /**
   * It's to search a bid in an all negotitation of a drayage
   */
  bidSearch(bidId: string, negotiations?: any[]): boolean {
    if (negotiations?.length > 0) {
      return negotiations.find(bid => bid?.id === bidId);
    }
  }

  acceptedNewCharges(load) {
    return dexfreight.Drayage.acceptedService(load._id, load.payment.close, localStorage.token);
  }

  getSkyAngelTracking() {
    return this.http.get('http://sta.skyangel.com.mx:8085/api/tracks/v1/unidades').toPromise();
  }

  async getDrayageDetails(drayageId: string): Promise<void> {
    try {
      return await this.micro.getShipment(drayageId, localStorage.token);
    } catch (error) {
      console.log(error);
    }
  }

  async acceptOffer(
    hasDocument: boolean,
    loadCompanyId: string,
    drayageId: string,
    offer: any,
    rateProntoPago?: any
  ): Promise<void> {
    swal({
      type: 'question',
      title: this.micro.dictionary.DO_YOU_WANT_TO_ACCEPT_THIS_RATE,
      showCancelButton: true,
      cancelButtonText: this.micro?.dictionary?.CANCEL_BUTTON,
      confirmButtonColor: this._ms.ac.config.settings.colors['green-dxf'],
    }).then(async (res: any) => {
      if (res.value) {
        if (hasDocument) {
          try {
            this._ms.hideOrShowLoading(true)
            const message =
              loadCompanyId === this._ms.company._id
                ? this.micro.dictionary.YOU_HAVE_SUCCESSFULLY_BOOKED_THIS_SHIPMENT
                : this.micro.dictionary.OFFER_SENT_SUCCESSFULLY

            await this._drayageNegotiationService.acceptNegotiation(
              drayageId,
              offer,
              message,
              localStorage.token,
              rateProntoPago
            )
          } catch (error) {
            console.log(error);
          } finally {
            setTimeout(() => {
              this._ms.listener.dispatchEvent('getOffers@dashboard-mex')
              this._ms.listener.dispatchEvent('getNotifications@dashboard-mex');
              this._ms.listener.dispatchEvent('getUnreadMessages@dashboard-mex')
              this._ms.hideOrShowLoading(false)
            }, 500);
          }
        } else {
          this.alertsService.displayCarrierDocumentUploadRequest()
        }
      }
    })
  }

  async declineOffer(loadId: string, bidId: string): Promise<void> {
    const brokerOptions = {
      'Shipment Covered': this.micro.dictionary?.SHIPMENT_COVERED,
      'Offer too high': this.micro.dictionary?.OFFER_TOO_HIGH,
      'Offer declined': this.micro.dictionary?.OFFER_DECLINED,
    };
    const carrierOptions = {
      'Truck Covered': this.micro.dictionary?.TRUCK_COVERED,
      'Offer declined': this.micro.dictionary?.OFFER_DECLINED,
    }
    const options: SweetAlertOptions = {
      text: this.micro.dictionary.ARE_YOU_SURE_TO_DECLINED_THIS_BID,
      showCancelButton: true,
      confirmButtonColor: '#a5ce58',
      cancelButtonColor: '#0022fc',
      confirmButtonText: this.micro.dictionary.YES,
      cancelButtonText: 'No',
      type: 'question',
      input: 'select',
      inputPlaceholder: this.micro?.dictionary?.SELECT_REASON,
      inputOptions: localStorage.role !== 'carrier' ? brokerOptions : carrierOptions,
      inputClass: 'text_md',
      reverseButtons: true,
    }
    swal(options).then(async (result) => {
      if (result.value !== undefined) {
        this._ms.hideOrShowLoading(true)
        try {
          await dexfreight.Drayage.declineBid(loadId, bidId, result.value, localStorage.token);
        } catch (error) {
          console.error({ error })
        } finally {
          setTimeout(() => {
            this._ms.listener.dispatchEvent('getOffers@dashboard-mex')
            this._ms.listener.dispatchEvent('getNotifications@dashboard-mex');
            this._ms.listener.dispatchEvent('getUnreadMessages@dashboard-mex')
            this._ms.hideOrShowLoading(false)
          }, 500);
        }
      }
    });
  }
}
