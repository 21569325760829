import { Injectable } from '@angular/core';
import { CacheListener } from './@utils/cache-listener';
import { Company } from 'interfaces/common/db/models/companies';
import { Microservices } from '@microservices';
import { UserService } from './user.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public company: CacheListener<Company & { _id: string } | null> = new CacheListener(null, this.syncCompany.bind(this));
  public companyLogo: CacheListener<string | null> = new CacheListener(null, this.syncCompanyLogo.bind(this));

  private listeners: Subscription[] = [];

  constructor(
    private userService: UserService,
    private micro: Microservices
  ) { }

  setupListeners () {
    this.listeners.push(
      this.company.syncWhenPropertyChanges(this.userService.user, 'companyId'),
      this.companyLogo.syncWhenChanges(this.company),
      
      this.companyLogo.bind(this.company),
      this.company.bind(this.userService.user)
    );
  }

  removeListeners () {
    this.listeners.forEach(listener => listener.unsubscribe());
  }

  async syncCompany () {
    const user = await this.userService.user.get();

    return await this.micro.DexCore.Company.get(user.companyId);
  }

  async syncCompanyLogo () {
    const company = await this.company.get();

    return await this.micro.DexCore.Company.logo(company._id);
  }
}
