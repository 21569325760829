
import * as Socket from './socket.js';
import { inject, Injectable } from '@angular/core'
// import * as Socket from 'unete-io/socket';
import { InspectService } from './services';

interface RecursiveProxyOptions {
    recursive: true;
    path?: string[];

    apply?: (path: string[], args: any[]) => any;
    get?: (path: string[]) => any;
    new?: (path: string[], args: any[]) => any;
}

interface ElasticProxyOptions {
    recursive?: false;

    set?: (path: string, prop: any) => void;
    get?: (path: string) => any;
    apply?: (args: any[]) => any;
    new?: (args: any[]) => any;
}

export class ElasticProxy {

    static new<T>(options: ElasticProxyOptions | RecursiveProxyOptions) {
        if (options.recursive) return ElasticProxy.newRecursive(options);
        const opts: ElasticProxyOptions = options as ElasticProxyOptions;

        return new Proxy(function () { }, {

            get(target, prop: string, receiver) {
                return opts.get && opts.get(prop);
            },

            set(target, prop: string, value, receiver) {
                opts.set && opts.set(prop, value)

                return true;
            },

            apply(target, thisArg, args) {
                return opts.apply && opts.apply(args);
            },

            construct(target, args) {
                return opts.new && opts.new(args);
            }

        });
    }

    static newRecursive(options: RecursiveProxyOptions) {
        if (!options.path) options.path = [];

        return ElasticProxy.new({
            recursive: false,

            get(prop: string) {
                const result = options.get && options.get([...options.path, prop]);
                return result || ElasticProxy.new({
                    recursive: true,
                    path: [...options.path, prop],
                    apply: options.apply,
                    new: options.new
                })
            },

            apply(args: any[]) {
                return options.apply && options.apply(options.path, args);
            },

            new(args: any[]) {
                return options.new && options.new(options.path, args);
            }
        })
    }

}
interface Log {
    time: Date,
    nonce: number,
    data: {
        activeQueries: number[],
        averageQueryDuration: number[],
        endpointStats: {
            [key: string]: {
                totalDuration: number,
                avgDuration: number,
                timesCalled: number
            }
        }
    }
}

function getLogDetails(calls: Call[], interval = 10) {
    if (calls.length === 0) return null;

    const startingTime = new Date(calls[0].startDate).getTime();
    const endingTime = new Date(calls[calls.length - 1].endDate).getTime();
    const result: {
        activeQueries: number[],
        averageQueryDuration: number[],
        endpointStats: {
            [key: string]: {
                totalDuration: number,
                avgDuration: number,
                timesCalled: number
            }
        }
    } = { activeQueries: [], averageQueryDuration: [], endpointStats: {} };

    for (let i = startingTime; i < endingTime; i += interval) {
        const activeQueries = calls.filter(
            call => {
                return i >= new Date(call.startDate).getTime() && i < new Date(call.endDate).getTime()
            }
        );
        result.activeQueries.push(activeQueries.length);
        result.averageQueryDuration.push(activeQueries.reduce((prev, cur) => prev + cur.duration, 0) / activeQueries.length);
    }

    calls.forEach((call) => {
        const path = call.path.join("/");
        result.endpointStats[path] = result.endpointStats[path] || {
            totalDuration: 0,
            avgDuration: 0,
            timesCalled: 0
        };

        result.endpointStats[path].timesCalled++;
        result.endpointStats[path].totalDuration += call.duration;
        result.endpointStats[path].avgDuration = result.endpointStats[path].totalDuration / result.endpointStats[path].timesCalled;
    })

    return result;
}

let nonce = 0;


export let calls: Call[] = [];
// ...
// setInterval(async () => {
//     if (calls.length === 0) return;
//     await dexFreight.Analytics.notifyLogs({
//         time : new Date(),
//         nonce,
//         data: getLogDetails(calls)
//     }, localStorage.token);
//     nonce++;
//     calls = [];
// }, 60000);

interface Call {
    host: string;
    path: string[];
    startDate: Date;
    endDate: Date;
    duration: number;
}

function translate(name: string, data: any = {}) {
    try {
        let msg = JSON.parse(sessionStorage.dictionary)[name];
        for (const fieldName in data) {
            msg = msg.replace(new RegExp(`{{${name}}}`, "g"), data[fieldName]);
        }

        return msg;
    } catch (error) {
        console.log(error);
    }
}

export function UneteDebugClient(url: string) {
    const unete = Socket(url, {}, {
        private_functions: {
            // $before_client_call: (args) => console.log("HOLA", args)
        }
    });
    // const unete = Socket(url)

    return ElasticProxy.new({
        recursive: true,
        get(path: string[]) {
            if (path.join(".") === "$sock") return unete.$sock;
        },
        async apply(path: string[], args: any[]) {
            let method = unete;
            for (const key of path) {
                method = method[key];
            }
            const startingDate = new Date();
            try {
                var res = await eval(`method(...args)`);
            } catch (exc) {
                const endingDate = new Date();
                try {
                    var message = JSON.parse(exc || exc.message)
                } catch (e) {
                    let lastChance = typeof exc === 'string' ? exc : exc.message;
                    let translateMessage = translate(lastChance)
                    if (!translateMessage) {
                        console.error("This message dont have translation", exc.message || exc);
                        throw exc
                    } else {
                        throw new Error(translateMessage);
                    }
                }
                throw new Error(translate(message.errorCode, message.data))
                // calls.push({
                //     host: url,
                //     path,
                //     startDate: startingDate,
                //     endDate: endingDate,
                //     duration: endingDate.getTime() - startingDate.getTime()
                // })
            }
            const endingDate = new Date();

            // console.info(`${path.join('.')} - ${new Date(endingDate.getTime() - startingDate.getTime()).getMilliseconds()}ms`)
            // calls.push({
            //     host: url,
            //     path,
            //     startDate: startingDate,
            //     endDate: endingDate,
            //     duration: endingDate.getTime() - startingDate.getTime()
            // })

            return res;
        }
    })
}

// Unete Service
@Injectable()
export class UneteIO {
    static inspect = new InspectService()
    constructor() { }

    static socket(url) {
        console.log(url)
        const unete = Socket(url, {}, {
            private_functions: {
                $before_client_call: (args) => this.createRequestReport(args),
                $after_client_call: (args) => this.modifyTime(args),
                $after_client_error: (args) => this.modifyRequest(args.iid, 'error', args.error)
            }
        });
        // const unete = Socket(url)

        return ElasticProxy.new({
            recursive: true,
            get(path: string[]) {
                if (path.join(".") === "$sock") return unete.$sock;
            },
            async apply(path: string[], args: any[]) {
                let method = unete;
                for (const key of path) {
                    method = method[key];
                }
                const startingDate = new Date();
                try {
                    var res = await eval(`method(...args)`);
                } catch (exc) {
                    const endingDate = new Date();
                    try {
                        var message = JSON.parse(exc || exc.message)
                    } catch (e) {
                        let lastChance = typeof exc === 'string' ? exc : exc.message;
                        let translateMessage = translate(lastChance)
                        if (!translateMessage) {
                            console.error("This message dont have translation", exc.message || exc);
                            throw exc
                        } else {
                            throw new Error(translateMessage);
                        }
                    }
                    throw new Error(translate(message.errorCode, message.data))
                    // calls.push({
                    //     host: url,
                    //     path,
                    //     startDate: startingDate,
                    //     endDate: endingDate,
                    //     duration: endingDate.getTime() - startingDate.getTime()
                    // })
                }
                const endingDate = new Date();

                // console.info(`${path.join('.')} - ${new Date(endingDate.getTime() - startingDate.getTime()).getMilliseconds()}ms`)
                // calls.push({
                //     host: url,
                //     path,
                //     startDate: startingDate,
                //     endDate: endingDate,
                //     duration: endingDate.getTime() - startingDate.getTime()
                // })

                return res;
            }
        })
    }

    static createRequestReport(request: any) {
        const { iid, path, port, hash } = request;
        let stack: string;

        this.inspect.add({
            id: iid,
            start: new Date().getTime(),
            end: 0,
            service: port,
            hash,
            endpoint: path.join('.'),
            stack
        })
    }

    static modifyRequest(iid: string, property: string, value: any) {
        // @ts-ignore
        this.inspect.modify(iid, [property, value])
    }

    static modifyTime({ iid }: any) {
        this.inspect.modify(iid, ['end', new Date().getTime()])
    }

    static get inspector() {
        return this.inspect;
    }
}