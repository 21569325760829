import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import dexfreight, { Microservices } from '../../microservices';
import { MainService } from '../main/main.service';

interface Domain {
  email?: string;
  active?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DomainsService {

  constructor(
    private ms: MainService,
    public micro: Microservices
  ) { }

  /**
   * Function to create email domain 
   * @param email 
   */
  async createDomain(email: string, enableEmails: boolean): Promise<void> {
    try {
      this.ms.hideOrShowLoading(true);

      const res = await dexfreight.Domains.creationDOmain(localStorage.companyId, email, enableEmails, localStorage.token);
      res && swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.DOMAIN_HAS_BEEN_CREATED, 'success');
    } catch (e) {
      swal('Error', e?.message || e, 'error');
      console.log(e);
    } finally {
      this.ms.hideOrShowLoading(false);
    }
  }

  /**
   * Function to update email domain 
   * @param email 
   */
  async updateDomain(payload: Domain): Promise<void> {
    try {
      this.ms.hideOrShowLoading(true);

      const res = await dexfreight.Domains.updateDomain(localStorage.companyId, payload, localStorage.token);
      res && swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.DATA_HAS_BEEN_UPDATED, 'success');
    } catch (e) {
      swal('Error', e?.message || e, 'error');
      console.log(e);
    } finally {
      this.ms.hideOrShowLoading(false);
    }
  }

  /**
   * Funtion to get companyId domain information
   * @param companyId 
   */
  async getDomainData(companyId: string): Promise<void> {
    return await dexfreight.Domains.getDomain(companyId, localStorage.token);
  }
}
