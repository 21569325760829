import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class InspectService {
  private call: Map<string, FrontendRequest> = new Map();
  private subscription: Subscription;
  private startTime: number;
  constructor() { }

  start() {
    if (this.subscription) this.subscription.unsubscribe();

    this.startTime = Date.now();
    this.call.clear();
  }

  end(title: string) {
    let reportFront: FrontendProcReport = {
      type: title,
      start: this.startTime,
      end: Date.now(),
      requests: this.calls
    };

    console.log({
      ...reportFront,
      calls: reportFront.requests.map(call => ({
        ...call,
        duration: call.end - call.start
      })),
      duration: reportFront.end - reportFront.start
    });

    // fetch('https://mx-api.dexcarga.io:14443/digestFrontendReport', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ report: reportFront })
    // }).catch(e => console.error(e))
  }

  add(call: FrontendRequest) {
    this.call.set(call.id, call)
  }

  modify(id: string, data: [FrontendRequestKeys, FrontendRequestTypes]) {
    if (!this.call.has(id)) return;
    // @ts-ignore
    this.call.get(id)[data[0]] = data[1];
  }

  get calls() {
    return Array.from(this.call.values()).filter(req => req.end > 0)
  }
}

interface FrontendProcReport {
  type: string; // Rute Front
  start: number;
  end: number;
  requests: FrontendRequest[]
}

interface FrontendRequest {
  id: string;
  start: number;
  end: number;
  service: string;
  endpoint: string;
  hash: any;
  error?: string;
  stack?: string;
}

type FrontendRequestKeys = keyof FrontendRequest;
type FrontendRequestTypes = FrontendRequest[keyof FrontendRequest];
