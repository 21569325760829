import { Injectable } from '@angular/core';
import dexfreight from '../../microservices';

@Injectable({
  providedIn: 'root'
})
export class TemplateNotificationService {

  constructor() { }

  /**
   * mark a category as active or inactive
   * @param payload 
   * @returns 
   */
  activeInactiveCategory(payload) {
    return dexfreight.Templates.updateAndCreateTemplate(payload, localStorage.token);
  }

  getCategories() {
    return dexfreight.Templates.getCategory(localStorage.token);
  }
}
