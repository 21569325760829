import { Injectable } from '@angular/core';
import dexFreight, { observablesSocket } from '../../microservices';


interface DrayageChatPayload {
  negotiation: string,
  carriers: string,
  carrierCompanyId: string,
  shipperCompanyId: string,
  shipperId: string
}

interface FTLChatPayload {
  carriers: string,
  carrierCompanyId: string,
  companyId: string,
  shipperId: string
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor() { }

  /**
   * create or retrieve a drayage shipment chat
   * @param loadId
   * @param payload
   * @returns
   */
  drayageChat(loadId: string, payload: DrayageChatPayload) {
    return dexFreight.Chat.drayageChat(loadId, payload, localStorage.token);
  }

  /**
   * create or retrieve an FTL shipment chat
   * @param loadId
   * @param payload
   * @returns
   */
  ftlChat(loadId: string, payload: FTLChatPayload) {
    return dexFreight.Chat.shipmentChat(loadId, payload, localStorage.token);
  }

  /**
   * get count of unread message
   * @param chatId
   * @returns
   */
  getChatUnreadMessages(chatId: string) {
    return dexFreight.Chat.readMessageCount(chatId, localStorage.token);
  }

  /**
   * get count of unread messages by shipment
   * @param loadId
   * @returns
   */
  getChatUnreadMessagesByShipment(loadId: string) {
    return observablesSocket.Chat.shipmentReadMessageCountTotal(loadId, localStorage.token);
  }

  /**
   * get count of unread messages by user
   * @returns
   */
  getChatUnreadMessagesByUser() {
    return observablesSocket.Chat.readMessageCountTotal(localStorage.token);
  }

  /**
   * get all chats by user
   * @returns
   */
  getUserChats() {
    return observablesSocket.Chat.getUserChats(localStorage.token);
  }

  /**
   * mark all messages of a chat as readed
   * @param chatId
   * @returns
   */
  readMessages(chatId: string) {
    return observablesSocket.Chat.readMessage(chatId, localStorage.token);
  }

  /**
   * get all messages of a chat
   * @param chatId
   * @param pagination
   * @returns
   */
  getMessages(chatId: string, pagination: { skip: number, limit: number }) {
    return observablesSocket.Chat.getMessageHistory(chatId, pagination, localStorage.token);
  }

  /**
   * this send a message
   * @param chatId
   * @param message
   * @returns
   */
  sendMessage(chatId: string, message: string, userReceivedId: string) {
    return dexFreight.Chat.sendMessage(chatId, message, localStorage.token, userReceivedId)
  }

  /**
   * return an observable that receive all new messages
   * @returns
   */
  getMessageStream() {
    return observablesSocket.Chat.getMessageStream(localStorage.token)
  }
}
