export default [
    {
        "name": "Alabama",
        "abrv": "AL",
        "cities": [
            "Birmingham",
            "Montgomery",
            "Mobile",
            "Huntsville",
            "Tuscaloosa",
            "Hoover",
            "Dothan",
            "Auburn",
            "Decatur",
            "Madison",
            "Florence",
            "Gadsden"
        ]
    },
    {
        "name": "Alaska",
        "abrv": "AK",
        "cities": [
            "Anchorage"
        ]
    },
    {
        "name": "American Samoa",
        "abrv": "AS",
        "cities": []
    },
    {
        "name": "Arizona",
        "abrv": "AZ",
        "cities": [
            "Phoenix",
            "Tucson",
            "Mesa",
            "Chandler",
            "Glendale",
            "Scottsdale",
            "Gilbert",
            "Tempe",
            "Peoria",
            "Surprise",
            "Yuma",
            "Avondale",
            "Goodyear",
            "Flagstaff",
            "Buckeye",
            "Lake Havasu City",
            "Casa Grande",
            "Sierra Vista",
            "Maricopa",
            "Oro Valley",
            "Prescott",
            "Bullhead City",
            "Prescott Valley",
            "Marana",
            "Apache Junction"
        ]
    },
    {
        "name": "Arkansas",
        "abrv": "AR",
        "cities": [
            "Little Rock",
            "Fort Smith",
            "Fayetteville",
            "Springdale",
            "Jonesboro",
            "North Little Rock",
            "Conway",
            "Rogers",
            "Pine Bluff",
            "Bentonville"
        ]
    },
    {
        "name": "California",
        "abrv": "CA",
        "cities": [
            "Los Angeles",
            "San Diego",
            "San Jose",
            "San Francisco",
            "Fresno",
            "Sacramento",
            "Long Beach",
            "Oakland",
            "Bakersfield",
            "Anaheim",
            "Santa Ana",
            "Riverside",
            "Stockton",
            "Chula Vista",
            "Irvine",
            "Fremont",
            "San Bernardino",
            "Modesto",
            "Fontana",
            "Oxnard",
            "Moreno Valley",
            "Huntington Beach",
            "Glendale",
            "Santa Clarita",
            "Garden Grove",
            "Oceanside",
            "Rancho Cucamonga",
            "Santa Rosa",
            "Ontario",
            "Lancaster",
            "Elk Grove",
            "Corona",
            "Palmdale",
            "Salinas",
            "Pomona",
            "Hayward",
            "Escondido",
            "Torrance",
            "Sunnyvale",
            "Orange",
            "Fullerton",
            "Pasadena",
            "Thousand Oaks",
            "Visalia",
            "Simi Valley",
            "Concord",
            "Roseville",
            "Victorville",
            "Santa Clara",
            "Vallejo",
            "Berkeley",
            "El Monte",
            "Downey",
            "Costa Mesa",
            "Inglewood",
            "Carlsbad",
            "San Buenaventura (Ventura)",
            "Fairfield",
            "West Covina",
            "Murrieta",
            "Richmond",
            "Norwalk",
            "Antioch",
            "Temecula",
            "Burbank",
            "Daly City",
            "Rialto",
            "Santa Maria",
            "El Cajon",
            "San Mateo",
            "Clovis",
            "Compton",
            "Jurupa Valley",
            "Vista",
            "South Gate",
            "Mission Viejo",
            "Vacaville",
            "Carson",
            "Hesperia",
            "Santa Monica",
            "Westminster",
            "Redding",
            "Santa Barbara",
            "Chico",
            "Newport Beach",
            "San Leandro",
            "San Marcos",
            "Whittier",
            "Hawthorne",
            "Citrus Heights",
            "Tracy",
            "Alhambra",
            "Livermore",
            "Buena Park",
            "Menifee",
            "Hemet",
            "Lakewood",
            "Merced",
            "Chino",
            "Indio",
            "Redwood City",
            "Lake Forest",
            "Napa",
            "Tustin",
            "Bellflower",
            "Mountain View",
            "Chino Hills",
            "Baldwin Park",
            "Alameda",
            "Upland",
            "San Ramon",
            "Folsom",
            "Pleasanton",
            "Union City",
            "Perris",
            "Manteca",
            "Lynwood",
            "Apple Valley",
            "Redlands",
            "Turlock",
            "Milpitas",
            "Redondo Beach",
            "Rancho Cordova",
            "Yorba Linda",
            "Palo Alto",
            "Davis",
            "Camarillo",
            "Walnut Creek",
            "Pittsburg",
            "South San Francisco",
            "Yuba City",
            "San Clemente",
            "Laguna Niguel",
            "Pico Rivera",
            "Montebello",
            "Lodi",
            "Madera",
            "Santa Cruz",
            "La Habra",
            "Encinitas",
            "Monterey Park",
            "Tulare",
            "Cupertino",
            "Gardena",
            "National City",
            "Rocklin",
            "Petaluma",
            "Huntington Park",
            "San Rafael",
            "La Mesa",
            "Arcadia",
            "Fountain Valley",
            "Diamond Bar",
            "Woodland",
            "Santee",
            "Lake Elsinore",
            "Porterville",
            "Paramount",
            "Eastvale",
            "Rosemead",
            "Hanford",
            "Highland",
            "Brentwood",
            "Novato",
            "Colton",
            "Cathedral City",
            "Delano",
            "Yucaipa",
            "Watsonville",
            "Placentia",
            "Glendora",
            "Gilroy",
            "Palm Desert",
            "Cerritos",
            "West Sacramento",
            "Aliso Viejo",
            "Poway",
            "La Mirada",
            "Rancho Santa Margarita",
            "Cypress",
            "Dublin",
            "Covina",
            "Azusa",
            "Palm Springs",
            "San Luis Obispo",
            "Ceres",
            "San Jacinto",
            "Lincoln",
            "Newark",
            "Lompoc",
            "El Centro",
            "Danville",
            "Bell Gardens",
            "Coachella",
            "Rancho Palos Verdes",
            "San Bruno",
            "Rohnert Park",
            "Brea",
            "La Puente",
            "Campbell",
            "San Gabriel",
            "Beaumont",
            "Morgan Hill",
            "Culver City",
            "Calexico",
            "Stanton",
            "La Quinta",
            "Pacifica",
            "Montclair",
            "Oakley",
            "Monrovia",
            "Los Banos",
            "Martinez"
        ]
    },
    {
        "name": "Colorado",
        "abrv": "CO",
        "cities": [
            "Denver",
            "Colorado Springs",
            "Aurora",
            "Fort Collins",
            "Lakewood",
            "Thornton",
            "Arvada",
            "Westminster",
            "Pueblo",
            "Centennial",
            "Boulder",
            "Greeley",
            "Longmont",
            "Loveland",
            "Grand Junction",
            "Broomfield",
            "Castle Rock",
            "Commerce City",
            "Parker",
            "Littleton",
            "Northglenn"
        ]
    },
    {
        "name": "Connecticut",
        "abrv": "CT",
        "cities": [
            "Bridgeport",
            "New Haven",
            "Stamford",
            "Hartford",
            "Waterbury",
            "Norwalk",
            "Danbury",
            "New Britain",
            "Meriden",
            "Bristol",
            "West Haven",
            "Milford",
            "Middletown",
            "Norwich",
            "Shelton"
        ]
    },
    {
        "name": "Delaware",
        "abrv": "DE",
        "cities": [
            "Wilmington",
            "Dover"
        ]
    },
    {
        "name": "District Of Columbia",
        "abrv": "DC",
        "cities": [
            "Washington"
        ]
    },
    {
        "name": "Federated States Of Micronesia",
        "abrv": "FM",
        "cities": []
    },
    {
        "name": "Florida",
        "abrv": "FL",
        "cities": [
            "Jacksonville",
            "Miami",
            "Tampa",
            "Orlando",
            "St. Petersburg",
            "Hialeah",
            "Tallahassee",
            "Fort Lauderdale",
            "Port St. Lucie",
            "Cape Coral",
            "Pembroke Pines",
            "Hollywood",
            "Miramar",
            "Gainesville",
            "Coral Springs",
            "Miami Gardens",
            "Clearwater",
            "Palm Bay",
            "Pompano Beach",
            "West Palm Beach",
            "Lakeland",
            "Davie",
            "Miami Beach",
            "Sunrise",
            "Plantation",
            "Boca Raton",
            "Deltona",
            "Largo",
            "Deerfield Beach",
            "Palm Coast",
            "Melbourne",
            "Boynton Beach",
            "Lauderhill",
            "Weston",
            "Fort Myers",
            "Kissimmee",
            "Homestead",
            "Tamarac",
            "Delray Beach",
            "Daytona Beach",
            "North Miami",
            "Wellington",
            "North Port",
            "Jupiter",
            "Ocala",
            "Port Orange",
            "Margate",
            "Coconut Creek",
            "Sanford",
            "Sarasota",
            "Pensacola",
            "Bradenton",
            "Palm Beach Gardens",
            "Pinellas Park",
            "Coral Gables",
            "Doral",
            "Bonita Springs",
            "Apopka",
            "Titusville",
            "North Miami Beach",
            "Oakland Park",
            "Fort Pierce",
            "North Lauderdale",
            "Cutler Bay",
            "Altamonte Springs",
            "St. Cloud",
            "Greenacres",
            "Ormond Beach",
            "Ocoee",
            "Hallandale Beach",
            "Winter Garden",
            "Aventura"
        ]
    },
    {
        "name": "Georgia",
        "abrv": "GA",
        "cities": [
            "Atlanta",
            "Columbus",
            "Augusta-Richmond County",
            "Savannah",
            "Athens-Clarke County",
            "Sandy Springs",
            "Roswell",
            "Macon",
            "Johns Creek",
            "Albany",
            "Warner Robins",
            "Alpharetta",
            "Marietta",
            "Valdosta",
            "Smyrna",
            "Dunwoody"
        ]
    },
    {
        "name": "Guam",
        "abrv": "GU",
        "cities": []
    },
    {
        "name": "Hawaii",
        "abrv": "HI",
        "cities": [
            "Honolulu"
        ]
    },
    {
        "name": "Idaho",
        "abrv": "ID",
        "cities": [
            "Boise City",
            "Nampa",
            "Meridian",
            "Idaho Falls",
            "Pocatello",
            "Caldwell",
            "Coeur d'Alene",
            "Twin Falls"
        ]
    },
    {
        "name": "Illinois",
        "abrv": "IL",
        "cities": [
            "Chicago",
            "Aurora",
            "Rockford",
            "Joliet",
            "Naperville",
            "Springfield",
            "Peoria",
            "Elgin",
            "Waukegan",
            "Cicero",
            "Champaign",
            "Bloomington",
            "Arlington Heights",
            "Evanston",
            "Decatur",
            "Schaumburg",
            "Bolingbrook",
            "Palatine",
            "Skokie",
            "Des Plaines",
            "Orland Park",
            "Tinley Park",
            "Oak Lawn",
            "Berwyn",
            "Mount Prospect",
            "Normal",
            "Wheaton",
            "Hoffman Estates",
            "Oak Park",
            "Downers Grove",
            "Elmhurst",
            "Glenview",
            "DeKalb",
            "Lombard",
            "Belleville",
            "Moline",
            "Buffalo Grove",
            "Bartlett",
            "Urbana",
            "Quincy",
            "Crystal Lake",
            "Plainfield",
            "Streamwood",
            "Carol Stream",
            "Romeoville",
            "Rock Island",
            "Hanover Park",
            "Carpentersville",
            "Wheeling",
            "Park Ridge",
            "Addison",
            "Calumet City"
        ]
    },
    {
        "name": "Indiana",
        "abrv": "IN",
        "cities": [
            "Indianapolis",
            "Fort Wayne",
            "Evansville",
            "South Bend",
            "Carmel",
            "Bloomington",
            "Fishers",
            "Hammond",
            "Gary",
            "Muncie",
            "Lafayette",
            "Terre Haute",
            "Kokomo",
            "Anderson",
            "Noblesville",
            "Greenwood",
            "Elkhart",
            "Mishawaka",
            "Lawrence",
            "Jeffersonville",
            "Columbus",
            "Portage"
        ]
    },
    {
        "name": "Iowa",
        "abrv": "IA",
        "cities": [
            "Des Moines",
            "Cedar Rapids",
            "Davenport",
            "Sioux City",
            "Iowa City",
            "Waterloo",
            "Council Bluffs",
            "Ames",
            "West Des Moines",
            "Dubuque",
            "Ankeny",
            "Urbandale",
            "Cedar Falls"
        ]
    },
    {
        "name": "Kansas",
        "abrv": "KS",
        "cities": [
            "Wichita",
            "Overland Park",
            "Kansas City",
            "Olathe",
            "Topeka",
            "Lawrence",
            "Shawnee",
            "Manhattan",
            "Lenexa",
            "Salina",
            "Hutchinson"
        ]
    },
    {
        "name": "Kentucky",
        "abrv": "KY",
        "cities": [
            "Louisville/Jefferson County",
            "Lexington-Fayette",
            "Bowling Green",
            "Owensboro",
            "Covington"
        ]
    },
    {
        "name": "Louisiana",
        "abrv": "LA",
        "cities": [
            "New Orleans",
            "Baton Rouge",
            "Shreveport",
            "Lafayette",
            "Lake Charles",
            "Kenner",
            "Bossier City",
            "Monroe",
            "Alexandria"
        ]
    },
    {
        "name": "Maine",
        "abrv": "ME",
        "cities": [
            "Portland"
        ]
    },
    {
        "name": "Marshall Islands",
        "abrv": "MH",
        "cities": []
    },
    {
        "name": "Maryland",
        "abrv": "MD",
        "cities": [
            "Baltimore",
            "Frederick",
            "Rockville",
            "Gaithersburg",
            "Bowie",
            "Hagerstown",
            "Annapolis"
        ]
    },
    {
        "name": "Massachusetts",
        "abrv": "MA",
        "cities": [
            "Boston",
            "Worcester",
            "Springfield",
            "Lowell",
            "Cambridge",
            "New Bedford",
            "Brockton",
            "Quincy",
            "Lynn",
            "Fall River",
            "Newton",
            "Lawrence",
            "Somerville",
            "Waltham",
            "Haverhill",
            "Malden",
            "Medford",
            "Taunton",
            "Chicopee",
            "Weymouth Town",
            "Revere",
            "Peabody",
            "Methuen",
            "Barnstable Town",
            "Pittsfield",
            "Attleboro",
            "Everett",
            "Salem",
            "Westfield",
            "Leominster",
            "Fitchburg",
            "Beverly",
            "Holyoke",
            "Marlborough",
            "Woburn",
            "Chelsea"
        ]
    },
    {
        "name": "Michigan",
        "abrv": "MI",
        "cities": [
            "Detroit",
            "Grand Rapids",
            "Warren",
            "Sterling Heights",
            "Ann Arbor",
            "Lansing",
            "Flint",
            "Dearborn",
            "Livonia",
            "Westland",
            "Troy",
            "Farmington Hills",
            "Kalamazoo",
            "Wyoming",
            "Southfield",
            "Rochester Hills",
            "Taylor",
            "Pontiac",
            "St. Clair Shores",
            "Royal Oak",
            "Novi",
            "Dearborn Heights",
            "Battle Creek",
            "Saginaw",
            "Kentwood",
            "East Lansing",
            "Roseville",
            "Portage",
            "Midland",
            "Lincoln Park",
            "Muskegon"
        ]
    },
    {
        "name": "Minnesota",
        "abrv": "MN",
        "cities": [
            "Minneapolis",
            "St. Paul",
            "Rochester",
            "Duluth",
            "Bloomington",
            "Brooklyn Park",
            "Plymouth",
            "St. Cloud",
            "Eagan",
            "Woodbury",
            "Maple Grove",
            "Eden Prairie",
            "Coon Rapids",
            "Burnsville",
            "Blaine",
            "Lakeville",
            "Minnetonka",
            "Apple Valley",
            "Edina",
            "St. Louis Park",
            "Mankato",
            "Maplewood",
            "Moorhead",
            "Shakopee"
        ]
    },
    {
        "name": "Mississippi",
        "abrv": "MS",
        "cities": [
            "Jackson",
            "Gulfport",
            "Southaven",
            "Hattiesburg",
            "Biloxi",
            "Meridian"
        ]
    },
    {
        "name": "Missouri",
        "abrv": "MO",
        "cities": [
            "Kansas City",
            "St. Louis",
            "Springfield",
            "Independence",
            "Columbia",
            "Lee's Summit",
            "O'Fallon",
            "St. Joseph",
            "St. Charles",
            "St. Peters",
            "Blue Springs",
            "Florissant",
            "Joplin",
            "Chesterfield",
            "Jefferson City",
            "Cape Girardeau"
        ]
    },
    {
        "name": "Montana",
        "abrv": "MT",
        "cities": [
            "Billings",
            "Missoula",
            "Great Falls",
            "Bozeman"
        ]
    },
    {
        "name": "Nebraska",
        "abrv": "NE",
        "cities": [
            "Omaha",
            "Lincoln",
            "Bellevue",
            "Grand Island"
        ]
    },
    {
        "name": "Nevada",
        "abrv": "NV",
        "cities": [
            "Las Vegas",
            "Henderson",
            "Reno",
            "North Las Vegas",
            "Sparks",
            "Carson City"
        ]
    },
    {
        "name": "New Hampshire",
        "abrv": "NH",
        "cities": [
            "Manchester",
            "Nashua",
            "Concord"
        ]
    },
    {
        "name": "New Jersey",
        "abrv": "NJ",
        "cities": [
            "Newark",
            "Jersey City",
            "Paterson",
            "Elizabeth",
            "Clifton",
            "Trenton",
            "Camden",
            "Passaic",
            "Union City",
            "Bayonne",
            "East Orange",
            "Vineland",
            "New Brunswick",
            "Hoboken",
            "Perth Amboy",
            "West New York",
            "Plainfield",
            "Hackensack",
            "Sayreville",
            "Kearny",
            "Linden",
            "Atlantic City"
        ]
    },
    {
        "name": "New Mexico",
        "abrv": "NM",
        "cities": [
            "Albuquerque",
            "Las Cruces",
            "Rio Rancho",
            "Santa Fe",
            "Roswell",
            "Farmington",
            "Clovis"
        ]
    },
    {
        "name": "New York",
        "abrv": "NY",
        "cities": [
            "New York",
            "Buffalo",
            "Rochester",
            "Yonkers",
            "Syracuse",
            "Albany",
            "New Rochelle",
            "Mount Vernon",
            "Schenectady",
            "Utica",
            "White Plains",
            "Hempstead",
            "Troy",
            "Niagara Falls",
            "Binghamton",
            "Freeport",
            "Valley Stream"
        ]
    },
    {
        "name": "North Carolina",
        "abrv": "NC",
        "cities": [
            "Charlotte",
            "Raleigh",
            "Greensboro",
            "Durham",
            "Winston-Salem",
            "Fayetteville",
            "Cary",
            "Wilmington",
            "High Point",
            "Greenville",
            "Asheville",
            "Concord",
            "Gastonia",
            "Jacksonville",
            "Chapel Hill",
            "Rocky Mount",
            "Burlington",
            "Wilson",
            "Huntersville",
            "Kannapolis",
            "Apex",
            "Hickory",
            "Goldsboro"
        ]
    },
    {
        "name": "North Dakota",
        "abrv": "ND",
        "cities": [
            "Fargo",
            "Bismarck",
            "Grand Forks",
            "Minot"
        ]
    },
    {
        "name": "Northern Mariana Islands",
        "abrv": "MP",
        "cities": []
    },
    {
        "name": "Ohio",
        "abrv": "OH",
        "cities": [
            "Columbus",
            "Cleveland",
            "Cincinnati",
            "Toledo",
            "Akron",
            "Dayton",
            "Parma",
            "Canton",
            "Youngstown",
            "Lorain",
            "Hamilton",
            "Springfield",
            "Kettering",
            "Elyria",
            "Lakewood",
            "Cuyahoga Falls",
            "Middletown",
            "Euclid",
            "Newark",
            "Mansfield",
            "Mentor",
            "Beavercreek",
            "Cleveland Heights",
            "Strongsville",
            "Dublin",
            "Fairfield",
            "Findlay",
            "Warren",
            "Lancaster",
            "Lima",
            "Huber Heights",
            "Westerville",
            "Marion",
            "Grove City"
        ]
    },
    {
        "name": "Oklahoma",
        "abrv": "OK",
        "cities": [
            "Oklahoma City",
            "Tulsa",
            "Norman",
            "Broken Arrow",
            "Lawton",
            "Edmond",
            "Moore",
            "Midwest City",
            "Enid",
            "Stillwater",
            "Muskogee"
        ]
    },
    {
        "name": "Oregon",
        "abrv": "OR",
        "cities": [
            "Portland",
            "Eugene",
            "Salem",
            "Gresham",
            "Hillsboro",
            "Beaverton",
            "Bend",
            "Medford",
            "Springfield",
            "Corvallis",
            "Albany",
            "Tigard",
            "Lake Oswego",
            "Keizer"
        ]
    },
    {
        "name": "Palau",
        "abrv": "PW",
        "cities": []
    },
    {
        "name": "Pennsylvania",
        "abrv": "PA",
        "cities": [
            "Philadelphia",
            "Pittsburgh",
            "Allentown",
            "Erie",
            "Reading",
            "Scranton",
            "Bethlehem",
            "Lancaster",
            "Harrisburg",
            "Altoona",
            "York",
            "State College",
            "Wilkes-Barre"
        ]
    },
    {
        "name": "Puerto Rico",
        "abrv": "PR",
        "cities": []
    },
    {
        "name": "Rhode Island",
        "abrv": "RI",
        "cities": [
            "Providence",
            "Warwick",
            "Cranston",
            "Pawtucket",
            "East Providence",
            "Woonsocket"
        ]
    },
    {
        "name": "South Carolina",
        "abrv": "SC",
        "cities": [
            "Columbia",
            "Charleston",
            "North Charleston",
            "Mount Pleasant",
            "Rock Hill",
            "Greenville",
            "Summerville",
            "Sumter",
            "Goose Creek",
            "Hilton Head Island",
            "Florence",
            "Spartanburg"
        ]
    },
    {
        "name": "South Dakota",
        "abrv": "SD",
        "cities": [
            "Sioux Falls",
            "Rapid City"
        ]
    },
    {
        "name": "Tennessee",
        "abrv": "TN",
        "cities": [
            "Memphis",
            "Nashville-Davidson",
            "Knoxville",
            "Chattanooga",
            "Clarksville",
            "Murfreesboro",
            "Jackson",
            "Franklin",
            "Johnson City",
            "Bartlett",
            "Hendersonville",
            "Kingsport",
            "Collierville",
            "Cleveland",
            "Smyrna",
            "Germantown",
            "Brentwood"
        ]
    },
    {
        "name": "Texas",
        "abrv": "TX",
        "cities": [
            "Houston",
            "San Antonio",
            "Dallas",
            "Austin",
            "Fort Worth",
            "El Paso",
            "Arlington",
            "Corpus Christi",
            "Plano",
            "Laredo",
            "Lubbock",
            "Garland",
            "Irving",
            "Amarillo",
            "Grand Prairie",
            "Brownsville",
            "Pasadena",
            "McKinney",
            "Mesquite",
            "McAllen",
            "Killeen",
            "Frisco",
            "Waco",
            "Carrollton",
            "Denton",
            "Midland",
            "Abilene",
            "Beaumont",
            "Round Rock",
            "Odessa",
            "Wichita Falls",
            "Richardson",
            "Lewisville",
            "Tyler",
            "College Station",
            "Pearland",
            "San Angelo",
            "Allen",
            "League City",
            "Sugar Land",
            "Longview",
            "Edinburg",
            "Mission",
            "Bryan",
            "Baytown",
            "Pharr",
            "Temple",
            "Missouri City",
            "Flower Mound",
            "Harlingen",
            "North Richland Hills",
            "Victoria",
            "Conroe",
            "New Braunfels",
            "Mansfield",
            "Cedar Park",
            "Rowlett",
            "Port Arthur",
            "Euless",
            "Georgetown",
            "Pflugerville",
            "DeSoto",
            "San Marcos",
            "Grapevine",
            "Bedford",
            "Galveston",
            "Cedar Hill",
            "Texas City",
            "Wylie",
            "Haltom City",
            "Keller",
            "Coppell",
            "Rockwall",
            "Huntsville",
            "Duncanville",
            "Sherman",
            "The Colony",
            "Burleson",
            "Hurst",
            "Lancaster",
            "Texarkana",
            "Friendswood",
            "Weslaco"
        ]
    },
    {
        "name": "Utah",
        "abrv": "UT",
        "cities": [
            "Salt Lake City",
            "West Valley City",
            "Provo",
            "West Jordan",
            "Orem",
            "Sandy",
            "Ogden",
            "St. George",
            "Layton",
            "Taylorsville",
            "South Jordan",
            "Lehi",
            "Logan",
            "Murray",
            "Draper",
            "Bountiful",
            "Riverton",
            "Roy"
        ]
    },
    {
        "name": "Vermont",
        "abrv": "VT",
        "cities": [
            "Burlington"
        ]
    },
    {
        "name": "Virgin Islands",
        "abrv": "VI",
        "cities": []
    },
    {
        "name": "Virginia",
        "abrv": "VA",
        "cities": [
            "Virginia Beach",
            "Norfolk",
            "Chesapeake",
            "Richmond",
            "Newport News",
            "Alexandria",
            "Hampton",
            "Roanoke",
            "Portsmouth",
            "Suffolk",
            "Lynchburg",
            "Harrisonburg",
            "Leesburg",
            "Charlottesville",
            "Danville",
            "Blacksburg",
            "Manassas"
        ]
    },
    {
        "name": "Washington",
        "abrv": "WA",
        "cities": [
            "Seattle",
            "Spokane",
            "Tacoma",
            "Vancouver",
            "Bellevue",
            "Kent",
            "Everett",
            "Renton",
            "Yakima",
            "Federal Way",
            "Spokane Valley",
            "Bellingham",
            "Kennewick",
            "Auburn",
            "Pasco",
            "Marysville",
            "Lakewood",
            "Redmond",
            "Shoreline",
            "Richland",
            "Kirkland",
            "Burien",
            "Sammamish",
            "Olympia",
            "Lacey",
            "Edmonds",
            "Bremerton",
            "Puyallup"
        ]
    },
    {
        "name": "West Virginia",
        "abrv": "WV",
        "cities": [
            "Charleston",
            "Huntington"
        ]
    },
    {
        "name": "Wisconsin",
        "abrv": "WI",
        "cities": [
            "Milwaukee",
            "Madison",
            "Green Bay",
            "Kenosha",
            "Racine",
            "Appleton",
            "Waukesha",
            "Eau Claire",
            "Oshkosh",
            "Janesville",
            "West Allis",
            "La Crosse",
            "Sheboygan",
            "Wauwatosa",
            "Fond du Lac",
            "New Berlin",
            "Wausau",
            "Brookfield",
            "Greenfield",
            "Beloit"
        ]
    },
    {
        "name": "Wyoming",
        "abrv": "WY",
        "cities": [
            "Cheyenne",
            "Casper"
        ]
    }
]
