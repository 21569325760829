import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Subject, Observable, ReplaySubject } from 'rxjs';


@Injectable()
export class PastDataService {

    // Observable string sources
    private shipmentShared = new ReplaySubject<any>();
    private tracking = new Subject<any>();
    private lastLocation = new Subject<any>();
    private updateList = new Subject();
    private close = new Subject();
    private closeModalInProfile = new Subject();
    private updateListBids = new Subject();
    private closeModalInSummary = new Subject();
    private navShipList = new Subject();
    private goToDetails = new Subject();
    shipmentShared$ = this.shipmentShared.asObservable();
    lastLocation$ = this.lastLocation.asObservable();
    tracking$ = this.tracking.asObservable();
    updateList$ = this.updateList.asObservable();
    close$ = this.close.asObservable();
    closeModalInProfile$ = this.closeModalInProfile.asObservable();
    updateListBids$ = this.updateListBids.asObservable();
    closeModalInSummary$ = this.closeModalInSummary.asObservable();
    navList$ = this.navShipList.asObservable();
    goToDetails$ = this.goToDetails.asObservable();

      navigatedList(text, ship?){
        this.navShipList.next(text);
      }

      listen(data?: any) {
         this.shipmentShared.next(data);
      }
      public getLastLocation(data?) {
        this.lastLocation.next(data);
      }

      reDraw(data?) {
        this.tracking.next(data);
      }

      created(who) {
        this.updateList.next(who);
      }

      closeModal() {
        this.close.next({});
      }

      closeModalProfile() {
        this.closeModalInProfile.next({});
      }

      updateListOfBids(data) {
        this.updateListBids.next(data);
      }
      closeModalLoadSummary() {
        this.closeModalInSummary.next({});
      }

    }
