import { Injectable } from '@angular/core';
import dexfreight, { Microservices } from '../../microservices';
import { MainService } from '../main/main.service';

import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class capacityService {

  constructor(
    private ms: MainService,
    public micro: Microservices) { }

  /**
   * This function is to get my equipments/capacity.
   */
  async getEquipments(): Promise<any[]> {
    try {
      const equipments = await this.micro.DexCore.Equipment.myEquipments(localStorage.getItem('token'));
      return equipments && equipments.reverse();
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Function to disable an equipment/capacity
   * @param equipmentId
   */
  async disableEquipment(equipmentId: string): Promise<void> {
    this.ms.hideOrShowLoading(true);

    try {
      await this.micro.DexCore.Equipment.disable(equipmentId, localStorage.getItem('token'));
      swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.EQUIPMENT_DISABLED_SUCCESSFULLY, 'success');
      this.ms.listener.dispatchEvent('refreshList@inEquipment');
    } catch (error) {
      console.log(error);
      swal('Error', error, 'error');
    } finally {
      this.ms.hideOrShowLoading(false);
    }
  }

  /**
   * Function to create capacity/equipment
   * @param capacityInformation
   * @param location
   */
  async createCapacity(capacityInformation) {
    this.ms.hideOrShowLoading(true);

    try {
      const equipmentToCreqate = { ...capacityInformation }
      delete equipmentToCreqate.eqData._id;
      const equipmentCreated = await dexfreight.Equipment.createPostCapacity(equipmentToCreqate, localStorage.token);
      this.ms.listener.dispatchEvent('refreshList@inEquipment', true);
      this.ms.listener.dispatchEvent('closeCreateCapacityDialog');
      this.ms.listener.dispatchEvent('closeCreateCapacityDialog@equipmentList');
      this.ms.listener.dispatchEvent('search@createCapacity', { capacity: { ...equipmentCreated[0] }, id: 2 });
      this.ms.listener.dispatchEvent('reloadEquipments@shipmentSearch', true);
      swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.YOUR_CAPACITY_HAS_BEEN_CREATED, 'success');
    } catch (error) {
      console.log(error);
      swal('Error', error.message || error, 'error');
    } finally {
      this.ms.hideOrShowLoading(false);
    }
  }

  /**
   * Function to edit capacity/equipment
   * @param capacityInformation
   * @param location
   */
  async editCapacity(capacityInformation): Promise<void> {
    const equipmentId = capacityInformation.eqData._id;
    delete capacityInformation.eqData._id;
    delete capacityInformation.eqLocation.qty;

    swal({
      title: this.micro.dictionary.ARE_YOU_SURE_TO_EDIT_THIS_EQUIPMENT,
      type: 'question',
      showCancelButton: true,
      cancelButtonText: this.micro?.dictionary?.CANCEL_BUTTON,
      confirmButtonColor: this.ms.ac.config.settings.colors['green-dxf']
    }).then(async (sres: any) => {
      if (sres.value) {
        this.ms.hideOrShowLoading(true);

        try {
          await dexfreight.Equipment.editCapacity(equipmentId, capacityInformation, localStorage.token);
          this.ms.listener.dispatchEvent('refreshList@inEquipment');
          this.ms.listener.dispatchEvent('closeCreateCapacityDialog@equipmentList');
          swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.EQUIPMENT_EDITED_SUCCESSFULLY, 'success');
        } catch (error) {
          console.log(error)
          swal('Error', error.message || error, 'error');
        } finally {
          this.ms.hideOrShowLoading(false);
        }
      }
    });
  }

  /**
   * This function remove an equipment by _id
   * @param _id
   */
  async removeEquipment(equipmentId: string): Promise<void> {
    swal({
      title: this.micro.dictionary.ARE_YOU_SURE_TO_DELETE_THE_EQUIPMENT,
      type: 'question',
      showCancelButton: true,
      cancelButtonText: this.micro?.dictionary?.CANCEL_BUTTON,
      confirmButtonColor: this.ms.ac.config.settings.colors["primary"]
    }).then(async sres => {
      if (sres.value) {
        this.ms.hideOrShowLoading(true);

        try {
          await this.micro.DexCore.Equipment.remove(equipmentId, localStorage.getItem('token'));
          swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.EQUIPMENT_REMOVED_SUCCESSFULLY, 'success');
          this.ms.listener.dispatchEvent('refreshList@inEquipment');
        } catch (error) {
          console.log(error);
          swal('Error', error, 'error');
        } finally {
          this.ms.hideOrShowLoading(false)
        }
      }
    });
  }
}
