import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Microservices } from '../../microservices';
import { MainService } from '../main/main.service';


@Injectable({
  providedIn: 'root'
})
export class BidServiceService {

  private documentsTrue = localStorage.getItem('@haveDocuments');

  constructor(private _http: HttpClient, private ms: MainService,
    public micro: Microservices) { }


  /**
   * accept shipmentas shipper
   */
  acceptAsShipper(bidId) {
    if (this.documentsTrue === "true") {
      return this.micro.DexCore.Bid.accept(bidId, localStorage.token);
    } else {
      throw new Error('No documents');
    }
  }
}
