import { Injectable } from '@angular/core';
import { Microservices } from '@microservices';
import { User } from 'interfaces/common/db/models/auth';
import { AuthService } from './services/back-services/auth.service';
import { BehaviorSubject, Subscription, distinctUntilChanged } from 'rxjs';
import { CacheListener } from './@utils/cache-listener';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: CacheListener<User | null> = new CacheListener(null, this.syncUser.bind(this));
  listeners: Subscription[] = [];

  constructor(
    private micro: Microservices,
    private authService: AuthService
  ) { }

  setupListeners () {
    this.listeners.push(
      //* When the token changes, sync the user
      this.authService.token
        .pipe(distinctUntilChanged((prev, cur) => prev === cur))
        .subscribe(() => this.syncUser()),

      //* When the token is cleared, clear the user
      this.user.bind(this.authService.token)
    );
  }

  removeListeners () {
    this.listeners.forEach(listener => listener.unsubscribe());
  }

  async syncUser (_user?: User) {
    const token = await this.authService.getToken();
    const user = _user ?? await this.micro.DexAuth.user(token);

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('userId', user._id);
    localStorage.setItem('role', user.role);
    localStorage.setItem('companyId', user.companyId);

    return user;
  }

  clear () {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('companyId');

    this.user.clear();
  }

}
