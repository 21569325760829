import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Microservices } from '@microservices';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { RoutesApp } from './constants/Routes.constants';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private listeners: Subscription[] = [];

  constructor(
    private micro: Microservices,
    private router: Router
  ) {}

  setupListeners () {

  }

  removeListeners () {

  }

  displayCarrierDocumentUploadRequest () {
    swal({
      title: this.micro.dictionary.DOCUMENTS,
      type: "info",
      showConfirmButton: false,
      html: `<p>${this.micro.dictionary.UPLOAD_YOUR_INSURANCE_W9_AND_AUTHORITY_DOCUMENTS_TO_COMPLETE_A_SHIPMENT_NEGOTIATION}</p>
      <button id="close" class="btn btn-primary">
    Subir documentos
      </button>`,
      animation: false,
      customClass: "animated fadeIn docsInfoColor",
      onBeforeOpen: () => {
        const content = swal.getContent();
        const $ = content.querySelector.bind(content);
        const close = $("#close");
        close.addEventListener("click", () => {
          this.router.navigate([RoutesApp.Settings.Hub.Documents]);
          swal.close();
        });
      },
    });
  }



  displayCarrierDriverSetupRequest () {
    swal({
      title: "Driver",
      type: "info",
      showConfirmButton: false,
      html:
        "<p>You have not added a driver, Click on the button below to continue</p>" +
        '<button id="close" class="btn btn-primary">' +
        "Add Driver" +
        "</button>",
      animation: false,
      customClass: "animated fadeIn",
      onBeforeOpen: () => {
        const content = swal.getContent();
        const $ = content.querySelector.bind(content);
        const close = $("#close");
        close.addEventListener("click", () => {
          this.router.navigate([RoutesApp.Assets.Driver]);
          swal.close();
        });
      },
    });
  }
}
