import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from '../../error-handlers/error-handler-service';
import { catchError, tap, takeLast } from 'rxjs/operators';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CarrierSearch {
    errorHandle;
    shipmentsSearch$: ReplaySubject<any> = new ReplaySubject();
    companyVariables;
    resetSkip$: BehaviorSubject<number> = new BehaviorSubject(0);
    constructor(private http: HttpClient, private errorService: HttpErrorHandler) {
        this.errorHandle = this.errorService.createHandleError('Carrier Search')
    }

    /**
    * Filter shipments as carrier using backend analytics
    * @param filter : searchShipmentAsCarrier
    * @returns 
    */
    carrierShipmentsSearch(filter: Partial<searchShipmentsAsCarrier>, type: 'Scroll' = null) {
        let errorHandler: HandleError;
        const apiUrl = `${environment.recommendationEngineHost}/recommendation_engine/model/shipment_search/`;
        return this.http.post<any[]>(apiUrl, filter)
            .pipe(
                catchError(this.errorHandle('carrierShipmentSearch')),
                takeLast(1),
                tap(({ shipments }) => this.shipmentsSearch$.next({ shipments, type })),
            ).toPromise();
    }




}

export interface searchShipmentsAsCarrier {
    options: {
        available: boolean,
        bySettings: boolean,
        byCapacity: boolean,
        byActive: boolean,
        bufferSize: number,
        limit: number,
        skip: number,
        filters?: boolean,
        name?: string,
        equipmentId?: string,
        shipmentId?: string
        sort?: { [key: string]: number },
    },
    features: {
        createdAt?: {
            from_date: string,
            to_date: string
        },
        status?: Array<string>,
        rate?: {},
        tripDistance?: {},
        equipment?: Array<string>,
        weight?: {},
        src?: string,
        favorites?: Array<string>,
        pickup?: {
            from_date: string,
            to_date: string
        },
        updatedAt?: {
            from_date: string,
            to_date: string
        },
        companyList?: Array<string>,
        originCoordinates?: [number, number],
        destinationCoordinates?: [number, number],
        radius?: number,
        originZipCode?: string,
        destinationZipCode?: string,
        originState?: string,
        destinationState?: string
    },
    mode: string,
    matchPercentage: number
}