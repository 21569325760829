import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, pipe, ReplaySubject, Subject, Subscription, merge, from, of } from 'rxjs';
import { catchError, filter, retry, tap, scan, delay, mergeMap, map, distinct, toArray, finalize, switchMap, count } from 'rxjs/operators';
import { MainService } from '../main/main.service';
import { searchShipmentsAsCarrier } from '../back-services/carrier-search.service';
import { environment } from '../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../error-handlers/error-handler-service';
import dexfreight, { Microservices } from '../../microservices';
import { Notification } from 'src/app/@types/Notification.type';

declare let InitialTime
@Injectable()
export class ObservablesService {

    subscriptions: Subscription[] = [];
    private handleError: HandleError;
    // FTL OBS
    carrierShip: Subject<any> = new Subject();
    myShipments: BehaviorSubject<any> = new BehaviorSubject(null);
    availableShipments: ReplaySubject<any> = null;
    newShipments: ReplaySubject<any> = null;
    superAdminShipments: ReplaySubject<any> = null;
    notifications: ReplaySubject<Notification> = null;
    superAdminCompanies: ReplaySubject<any> = null;
    carrierAvailableShipments: ReplaySubject<any> = null;
    shipmentAvailableFromSearch = []
    //DRAYAGE OBS
    drayageShipments: ReplaySubject<any> = null;
    myDrayages: ReplaySubject<any> = null;
    drayageNewShipments: ReplaySubject<any> = null;
    drayageAvailableShipments: ReplaySubject<any> = null;
    filtersInsearch = [];
    private drayageShipments$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    //PREMIUM
    tours = []
    myLoads: boolean = true;

    constructor(
        public micro: Microservices,
        private ms: MainService,
        httpErrorHandler: HttpErrorHandler,
        private http: HttpClient) {
        this.notifications = new ReplaySubject();
        this.handleError = httpErrorHandler.createHandleError('ObservableService')
    }

    async initObservables(token) {
        await this.initMyShipments(token);
        await this.initAvailableShipments(token);
    }

    get drayageList() {
        return this.drayageShipments$;
    }


    getDrayageTest(skip: number = 0, myDrayages: boolean = false) {
        return forkJoin(this.micro.Obs.Drayage.Rx.availableDrayages({}, { available: myDrayages, skip, limit: 100 }, localStorage.getItem('token')))
            .pipe(
                mergeMap(([ob1]) => merge(ob1)),
                distinct((shipment) => shipment._id),
                toArray(),
                tap((shipments) => this.drayageShipments$.next(this.myLoads !== myDrayages ? [...shipments] : [...(this.drayageShipments$.value ?? []), ...shipments])),
                finalize(() => this.myLoads = myDrayages),
            )
    }

    deleteDrayagesFromObservable(drayageIds: string[]) {
        from(this.drayageShipments$.value).pipe(
            filter((load: any) => !drayageIds.includes(load._id)),
            toArray(),
            tap((drayages) => this.drayageShipments$.next([...drayages])),
        ).subscribe()
    }
    //Drayage Observables

    // CHECK TO REMOVE
    async initDrayageNewShipments(token) {
        if (!this.drayageNewShipments || this.drayageNewShipments.closed) {
            try {
                this.drayageNewShipments = await this.micro.Obs.Drayage.Rx.drayageFeed(token) as ReplaySubject<any>;
                // this.drayageNewShipments = await dexfreight.Drayage.Rx.drayageFeed(token) as ReplaySubject<any>;
                this.micro.sendTimeToLoad('DrayageNewShipments', Date.now() - InitialTime)
                const newShipsSubs = this.drayageNewShipments.pipe(
                    map(load => {
                        const { payment: { open } } = load || {};

                        load.rate = this.getTotalDrayageRate(open?.services, open?.otherServices);
                        return this.micro.setShipment(load)
                    }),
                    tap((load) => this.drayageShipments$.next([load, ...this.drayageShipments$.value])),
                ).subscribe();
                this.subscriptions.push(newShipsSubs);
            } catch (error) {
                throw error;
            }

        }
    }

    //FTL Obs
    async initAvailableShipments(token) {
        try {
            if (!this.availableShipments || this.availableShipments.closed) {
                this.availableShipments = new ReplaySubject();
                if (localStorage.role === "carrier") {
                    const obs = await this.micro.DexCore.Shipment.Rx.shipments({}, { available: true, bufferSize: 100 }, token);
                    obs.subscribe(data => {
                        for (const shipment of data) this.availableShipments.next(shipment);
                    }, null, () => { this.availableShipments.complete(); this.micro.sendTimeToLoad('InitAvailableShipments', Date.now() - InitialTime) });
                }
                const availableShipsSubs = this.availableShipments.subscribe(ship => { if (ship) this.micro.setShipment(ship) });
                this.subscriptions.push(availableShipsSubs);
            }
        } catch (error) {
            throw error;
        }
    }
    async initMyShipments(token) {
        if (!this.myShipments.value || this.myShipments.closed) {
            try {
                const obs = await this.micro.DexCore.Shipment.Rx.shipments({}, { bufferSize: 100 }, token) as ReplaySubject<any>;

                const myShipsSubs = obs.pipe(
                    switchMap(ship => from(ship)),
                    filter((shipment: any) => shipment.payment),
                    tap((shipment) => this.micro.setShipment(shipment)),
                    toArray(),
                    tap(shipment => this.myShipments.next([...shipment])),
                    finalize(() => this.ms.listener.dispatchEvent('loadShipments'))
                ).subscribe()

                // obs.subscribe(data => {
                //     for (const shipment of data) this.myShipments.next(shipment);
                // }, null, () => { this.myShipments.complete(), this.micro.sendTimeToLoad('InitMyShipments', Date.now() - InitialTime), console.log(this.myShipments.value)})
                this.subscriptions.push(myShipsSubs);
            } catch (error) {
                throw error;
            }
            // this.myShipments
            //     .pipe(filter(ship => ship.payment))
            //     .subscribe(ship => { if (ship) this.micro.setShipment(ship) });
        }
    }

    async initNewShipments(token, filter?) {
        if (!this.newShipments || this.newShipments.closed) {
            try {
                this.newShipments = await this.micro.DexCore.Shipment.Rx.shipmentFeed(filter, token);
                this.micro.sendTimeToLoad('InitNewShipments', Date.now() - InitialTime)
            } catch (error) {
                throw error;
            }
            const newShipsSubs = this.newShipments.subscribe(ship => {
                if (ship) {
                    this.micro.setShipment(ship)
                }
            });
            this.subscriptions.push(newShipsSubs);
        }
    }

    /**
     * Fast search of availables shipments for carrier
     * @param obj obj whit the filter options linke (ByCapacity,ByPrevius and BySettings)
     * @param token
     */
    async carrierFastShipmentSearch(obj, token) {
        const object = obj ? { ...obj, available: true } : { available: true };
        this.shipmentAvailableFromSearch = await dexfreight.Shipments.Searches.search({}, { ...object }, token);
        this.carrierAvailableShipments = new ReplaySubject()
        this.shipmentAvailableFromSearch.forEach(data => {
            this.micro.setShipment(data);
            this.carrierAvailableShipments.next(data);
        });
        this.carrierAvailableShipments.complete()
    }

    /**
 * Filter obs of  shipments for carrier
 * @param obj obj whit the filter query
 * @param token
 */
    async carrierSearchByFilter(obj, radius, token) {
        const options = radius ? { ...radius, available: true } : { available: true };
        this.shipmentAvailableFromSearch = await dexfreight.Shipments.Searches.search(obj, { ...options }, token);
        // console.log(this.shipmentAvailableFromSearch);
        this.carrierAvailableShipments = new ReplaySubject();
        this.shipmentAvailableFromSearch.forEach(data => {
            this.micro.setShipment(data);
            this.carrierAvailableShipments.next(data);
        })
        this.carrierAvailableShipments.complete();
    }



    /**
     * Filter shipments as carrier using backend analytics
     * @param filter : searchShipmentAsCarrier
     * @returns
     * @deprecated Moved to carrier-search.service.ts
     */
    carrierShipmentsSearch(filter: searchShipmentsAsCarrier) {
        const apiUrl = `${environment.recommendationEngineHost}/recommendation_engine/model/shipment_search/`;
        return this.http.post<any[]>(apiUrl, filter)
            .pipe(
                catchError(this.handleError('carrierShipmentSearch'))
            );
    }


    /**
     * Get previous searches in shipment search
     * @returns promise
     */
    getPreviousSearches() {
        const apiUrl = `${environment.recommendationEngineHost}/recommendation_engine/model/search_history/`;
        return this.http.post<any[]>(apiUrl, {})
            .pipe(
                catchError(this.handleError('carrierShipmentSearch')),
                retry(1)
            );
    }



    async initSuperAdminShipments(token) {
        if (!this.superAdminShipments || this.superAdminShipments.closed) {
            try {
                this.superAdminShipments = await this.micro.DexManager.Shipment.list({}, token);
            } catch (error) {
                throw error;
            }

            const saShipsSubs = this.superAdminShipments.subscribe(ship => this.micro.setShipment(ship));
            this.subscriptions.push(saShipsSubs);
        }
    }
    async initSuperAdminCompanies(token) {
        if (!this.superAdminCompanies || this.superAdminCompanies.closed) {
            try {
                this.superAdminCompanies = await this.micro.DexManager.Company.list(token);
            } catch (error) {
                throw error;
            }
        }
    }


    async initNotifications(token) {
        if (!this.notifications)
            this.notifications = new ReplaySubject();

        try {
            let cachedSubscriber = this.notifications;
            this.micro.RealTime.$sock.on('connect', async () => await this.micro.RealTime.Notifications.subscribe(token, (notif: Notification) => {
                if (cachedSubscriber === this.notifications) this.notifications.next(notif);
            }));
            await this.micro.RealTime.Notifications.subscribe(token, (notif: Notification) => {
                if (cachedSubscriber === this.notifications && notif) this.notifications.next(notif);
            });
            const notificationSubs = this.notifications
                .subscribe(async notif => {
                    if (notif && !notif.tags.includes('drayage') && notif.data.shipment) {
                        await this.updateShipment(notif.data.shipment);
                    } else {
                        if (notif) await this.updateDrayage(notif.data.uniqueId);
                    }

                });
            this.subscriptions.push(notificationSubs);
        } catch (error) {
            throw error;
        }
    }
    /**
     * @param filters array of filter used in the query in shipment search
     * @param filter
     * @param radio
     */
    setFiltersInsearch(filters, filter, radio) {
        this.filtersInsearch = filters
        localStorage.queryInSearch = localStorage.setItem('queryForSearchCarrierShipments', JSON.stringify({ filter: filter, radio: radio, filters: filters }))
    }
    /**
     * Return de list of filters
     */
    getFiltersInSearch() {
        return this.filtersInsearch;
    }

    /**
     * Remove de list of filters
     */
    removeFiltersInSearch() {
        this.filtersInsearch = [];
        localStorage.removeItem('queryForSearchCarrierShipments')
    }

    removeShipment(shipment: string, shipmentType = 'ftl') {
        this.micro.removeShipment(shipment);
        if (shipmentType === 'ftl') {
            // (<any>this.myShipments)._buffer = (<any>this.myShipments)._buffer.filter(s => s._id !== shipment && s.uniqueId !== shipment);
            let newList = (this.myShipments.value as Array<any>).filter(s => s._id !== shipment && s.uniqueId !== shipment);
            this.myShipments.next(newList);
        } else {
            const ships = (<any>this.drayageShipments)._events;
            const ship = (<any>this.drayageShipments)._events.find((ship: any) => ship._id === shipment || ship.uniqueId === shipment);
            const index = ships.indexOf(ship);
            ships.splice(index, 1);
        }
    }

    async updateShipment(shipment: string) {
        const load = await this.micro.updateShipment(shipment, localStorage.getItem("token"));
        if (localStorage.getItem('role') === 'carrier') return this.carrierShip.next(load);
        //! ------------- MY SHIPMENTS --------------
        {
            if ((<any>this.myShipments)) {
                const ships = (<any>this.myShipments)?._events;
                if (ships) {

                    const ship = (<any>this.myShipments)._events.find(s => s._id === shipment);
                    if (ship && localStorage.role === 'carrier') {
                        if ((<any>this.carrierAvailableShipments)) {
                            (<any>this.carrierAvailableShipments)._events.unshift(ship);
                        }
                    }
                    if (ship) {
                        const index = ships.indexOf(ship);
                        ships.splice(index, 1);
                        if (ships) {
                            ships.unshift(ship);
                        }
                        return;
                    }

                }
            }
        }

        //! ----------- AVAILABLE SHIPMENTS ---------
        {
            if (localStorage.role != 'carrier') {
                if ((<any>this.availableShipments)) {
                    const ships = (<any>this.availableShipments)._events;
                    if (ships) {
                        const ship = (<any>this.availableShipments)._events.find(s => s._id === shipment);
                        if (ship) {
                            const index = ships.indexOf(ship);

                            if (ship._tradeRegistry.state === "open") {
                                ships.splice(index, 1);
                                if (ships) {
                                    ships.unshift(ship);
                                }
                            } else {
                                (<any>this.myShipments)._events.unshift(ship);
                            }

                            return;
                        }
                    }
                }
            }
            else {
                if ((<any>this.carrierAvailableShipments)) {
                    const ships = (<any>this.carrierAvailableShipments)._events;
                    if (ships) {
                        const ship = ships.find(s => s._id === shipment);
                        const index = ships.indexOf(ship);

                        if (load._tradeRegistry.state === "open") {
                            ships.splice(index, 1);
                            if (ships) {
                                ships.unshift(load);
                            }
                        } else {
                            (<any>this.myShipments)._events.unshift(load);
                            // (<any>this.carrierAvailableShipments).unshift(ship);
                        }

                        return;
                    }
                }
            }

        }

        //! ----------- NEW SHIPMENTS ---------
        {
            if ((<any>this.newShipments)) {
                const ships = (<any>this.newShipments)._events;
                if (ships) {
                    const ship = (<any>this.newShipments)._events.find(s => s._id === shipment);
                    if (ship) {
                        const index = ships.indexOf(ship);
                        if (localStorage.role != 'carrier') {
                            if (ship._tradeRegistry.state === "open") {
                                ships.splice(index, 1);
                                if (ships) {
                                    ships.unshift(ship);
                                }
                            } else {
                                (<any>this.myShipments)._events.unshift(ship);
                            }
                            return;
                        }
                        else {
                            if (ship._tradeRegistry.state === "open") {
                                ships.splice(index, 1);
                                if (ships) {
                                    (<any>this.newShipments)._events.unshift(ship);
                                }
                            } else {
                                (<any>this.carrierAvailableShipments)._events.unshift(ship);
                            }

                        }


                    }
                }
            }
        }
    }
    async updateDrayage(uniqueId: string) {
        try {
            let shipment = await dexfreight.Drayage.getByUniqueId(uniqueId, localStorage.token);

            await this.micro.setShipment(shipment);
            //! ------------- MY SHIPMENTS --------------
            {
                if ((<any>this.drayageShipments)) {
                    const ships = (<any>this.drayageShipments)._events;
                    if (ships) {
                        const ship = (<any>this.drayageShipments)._events.find(s => s.uniqueId === uniqueId);
                        if (ship) {
                            const index = ships.indexOf(ship);
                            ships.splice(index, 1);
                            if (ships) {
                                ships.unshift(shipment);
                            }
                            return;
                        }
                    }
                }
            }
            //! ----------- AVAILABLE SHIPMENTS ---------
            {
                if ((<any>this.drayageAvailableShipments)) {
                    const ships = (<any>this.drayageAvailableShipments)._events;
                    if (ships) {
                        const ship = (<any>this.drayageAvailableShipments)._events.find(s => s._id === shipment);
                        if (ship) {
                            const index = ships.indexOf(shipment);

                            if (ship.state === "open") {
                                ships.splice(index, 1);
                                if (ships) {
                                    ships.unshift(shipment);
                                }
                            } else {
                                (<any>this.drayageAvailableShipments)._events.unshift(shipment);
                            }

                            return;
                        }
                    }
                }
            }

            //! ----------- NEW SHIPMENTS ---------
            if ((<any>this.drayageNewShipments)) {
                const ships = (<any>this.drayageNewShipments)._events;
                if (ships) {
                    const ship = (<any>this.drayageNewShipments)._events.find(s => s.uniqueId === uniqueId);
                    if (ship) {
                        const index = ships.indexOf(ship);

                        if (shipment.state === "open") {
                            ships.splice(index, 1);
                            if (ships) {
                                (<any>this.drayageNewShipments)._events.unshift(shipment);
                            }
                        }
                        return;
                    }
                }
            }

        } catch (error) {
            console.log(error);

        }

    }

    getTotalDrayageRate(services: any[], otherServices?: any[]): number {
        let total = 0;

        if (services?.length > 0) total += this.calculateServiceTotal(services);
        if (otherServices?.length > 0) total += this.calculateOtherServiceTotal(otherServices);

        return total;
    }

    private calculateServiceTotal(services: any[], allServices?: any[]): number {
        let total = 0;

        services.forEach(service => {
            const { type, qty, rate } = service;
            const serviceQuantity = (qty || service.quantity) || 1;

            total += rate * serviceQuantity;
        });

        return total;
    }

    private calculateOtherServiceTotal(otherServices: any[]): number {
        let total = 0;

        otherServices.forEach(otherService => {
            total += Number(otherService.rate * otherService.qty);
        });

        return total;
    }


    /**
     * Unsubscribe obs when logout
     */
    unsubscribe() {
        this.myShipments = null;
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        })
    }
}
