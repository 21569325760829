import { Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { DashboardGuard } from './guards/dashboard.guard';
import { NotDashboardGuard } from './guards/not-dashboard.guard';

export const ROUTES: Routes = [
  {
    path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [NotDashboardGuard]
  },
  {
    path: 'login', loadComponent: () => import('./login/login.component').then(m => m.LoginComponent), canActivate: [NotDashboardGuard]
  },
  {
    path: 'app', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [DashboardGuard]
  },
  {
    path: 'faq', loadChildren: () => import('./f-a-q/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'pricing-tool', loadChildren: () => import('./premium/premium.module').then(m => m.PremiumModule),
  },
  {
    path: 'tour-planning', loadChildren: () => import('./premium/premium.module').then(m => m.PremiumModule),
  },
  {
    path: 'pricing-tool',   loadChildren: () => import('./premium/premium.module').then(m => m.PremiumModule), 
  },
  {
    path: 'tour-planning',   loadChildren: () => import('./premium/premium.module').then(m => m.PremiumModule), 
  },
  {
    path: 'error', component: ErrorComponent
  },
  {
    path: '**', component: ErrorComponent
  }
];
