import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from '../../error-handlers/error-handler-service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import dexFreight, { Microservices } from '@microservices';
import { ReportsDashMexService } from 'src/app/dashboard-mex/components/reports.service';
import { User } from 'interfaces/common/db/models/auth';
import { BehaviorSubject, Subject } from 'rxjs';
import { CacheListener } from 'src/app/@utils/cache-listener';

interface AuthPayload {
    userId: string;
    companyId: string;
    database: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public token: CacheListener<string> = new CacheListener(localStorage.getItem('token'), this.syncToken.bind(this));

    errorHandle;
    constructor(
        private http: HttpClient,
        private errorService: HttpErrorHandler,
        private _reportDashMex: ReportsDashMexService,
        private micro: Microservices
    ) {
        this.errorHandle = this.errorService.createHandleError('Token Authorization')
    }

    /**
    * Get token for recommendation engine
    * @param payload : Authentication payload
    * @returns 
    */
    recommendationEngineAuth(payload: AuthPayload) {
        const apiUrl = `${environment.recommendationEngineHost}/recommendation_engine/user/authentication`;
        return this.http.post<any[]>(apiUrl, payload)
            .pipe(
                catchError(this.errorHandle('RecommendationEngineAuthError'))
            ).toPromise();
    }

    /**
    * Get token for analytics
    * @param payload : Authentication payload
    * @returns 
    */
    analyticsAuth(payload: AuthPayload) {
        const apiUrl = `${environment.tourPlanningHost}/analytics_charts/user/authentication`;
        return this.http.post<any[]>(apiUrl, payload)
            .pipe(
                catchError(this.errorHandle('AnalyticsAuthError'))
            ).toPromise();
    }

    /**
    * Get token for Tour Planning
    * @param payload : Authentication payload
    * @returns 
    */
    tourPlanningAuth(payload: AuthPayload) {
        const apiUrl = `${environment.tourPlanningHost}/tour_planning/user/authentication`;
        return this.http.post<any[]>(apiUrl, payload)
            .pipe(
                catchError(this.errorHandle('TourPlanningAuthError'))
            ).toPromise();
    }

    async ensureToken (type: string) {
        const token = localStorage.getItem(type);
        const port = environment.production ? '12002' : '12001'
        
        if (token && !this.isTokenExpired(token)) return token;

        let res;
        switch (type) {
            case 'analyticsToken':
                res = await this.analyticsAuth(this._authenticationPayload());
                localStorage.setItem('analyticsToken', res.token ?? res.access_token);
                return res.token ?? res.access_token;
            case 'recommendationToken':
                res = await this.recommendationEngineAuth(this._authenticationPayload());
                localStorage.setItem('recommendationToken', res.token ?? res.access_token);
                return res.token ?? res.access_token;
            case 'tourPlanningToken':
                res = await this.tourPlanningAuth(this._authenticationPayload());
                localStorage.setItem('tourPlanningToken', res.token ?? res.access_token);
                return res.token ?? res.access_token;
            case 'skyAngelToken':
                res = await dexFreight.Tracking.getSkyAngelToken(localStorage.token);
                localStorage.setItem('skyAngelToken', res.token);
                return res.token;
            case 'dashboardMexToken':
                res = await this._reportDashMex.dashboardAuthentication(port, this._authenticationPayload());
                localStorage.setItem('dashboardMexToken', res.token);
                return res.token;
            case 'dashboardMexReportToken':
                res = await this._reportDashMex.reportsAuthentication(port, this._authenticationPayload());
                localStorage.setItem('dashboardMexReportToken', res.token);
                return res.token;
        }
    }

    private _authenticationPayload () {
        return {
            userId: localStorage.userId,
            companyId: localStorage.companyId,
            database: environment.production ? 'production' : 'development'
        };
    }


    isTokenExpired(token: string): boolean {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const expirationTime = decodedToken.exp * 1000; // Convert expiration time from seconds to milliseconds
        const currentTime = Date.now();
        const timeDifference = expirationTime - currentTime;
        const oneMinuteInMilliseconds = 60 * 1000;

        return timeDifference < oneMinuteInMilliseconds;
    }

    getToken () {
        return localStorage.getItem('token');
    }

    syncToken (token?: string) {
        if (token) localStorage.setItem('token', token);
        else return localStorage.getItem('token');

        return token;
    }

    clear () {
        localStorage.removeItem('token');
        this.token.clear();
    }

    async logout () {
        try {
            await this.micro.DexAuth.logout(localStorage.token);
        } catch (error) {
            console.log(error);
        }
        
        this.token.clear();
        window.location.href = '/'
    }

}