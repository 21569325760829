
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import dexfreight, { Microservices } from '../../microservices';
import { ObservablesService } from '../observables/observables.service';
import { MainService } from '../main/main.service'

import swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class DrayageNegotiationService {

  constructor(
    private ms: MainService,
    private dialog: MatDialog,
    private obs: ObservablesService,
    public micro: Microservices) { }

  getDryServices(drayageId: string, token: string) {
    return dexfreight.Drayage.getServicesDrayageActive(token, drayageId);
  }

  getDryServicesBidding(drayageId: string, token: string) {
    return dexfreight.Drayage.getServicesBidding(drayageId, token);
  }

  addTemporalServicesNegotiation(drayageId: string, carrierCompanyId: string, services: ServiceDry, token: string) {
    return dexfreight.Drayage.addTemporalServicesNegotiation(drayageId, carrierCompanyId, services, token);
  }

  addTemporalService(drayageId: string, service: ServiceDry, token: string) {
    return dexfreight.Drayage.addTemporalServices(drayageId, service, token);
  }

  addChargeablesService(drayageId: string, service: ServiceDry, token: string) {
    return dexfreight.Drayage.addChargeablesServices(drayageId, service, token);
  }

  removeTemporalService(drayageId: string, service: ServiceDry, token: string): any {
    return dexfreight.Drayage.removeServiceTemporal(drayageId, service, token);
  }

  removeServiceTemporalNegotiation(drayageId: string, carrierCompanyId: string, services: ServiceDry, token: string) {
    return dexfreight.Drayage.removeServiceTemporalNegotiation(drayageId, carrierCompanyId, services, token);
  }

  removeChargeablesService(drayageId: string, service: ServiceDry, token: string) {
    return dexfreight.Drayage.removeServiceChargeable(drayageId, service, token);
  }

  async negotiation(loadId, offer, token) {
    try {
      this.ms.hideOrShowLoading(true)
      await dexfreight.Drayage.negotiation(loadId, offer, token);
      swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.OFFER_SENT_SUCCESSFULLY, 'success');
      this.updateDrayage(loadId);
    } catch (e) {
      console.log(e);
      swal('Error', e?.message || e, 'error');
    } finally {
      setTimeout(() => {
        this.ms.listener.dispatchEvent('getOffers@dashboard-mex')
        this.ms.listener.dispatchEvent('getNotifications@dashboard-mex');
        this.ms.listener.dispatchEvent('getUnreadMessages@dashboard-mex')
        this.ms.hideOrShowLoading(false)
      }, 500);
    }
  }

  async acceptNegotiation(loadId, offer, message, token, rateProntoPago = null) {
    try {
      await dexfreight.Drayage.acceptRate(loadId, offer, token, rateProntoPago);
      swal(this.micro.dictionary.SUCCESS, message, 'success');
      this.updateDrayage(loadId);
    } catch (e) {
      console.log(e);
      swal('Error', e?.message || e, 'error');
    }
  }

  async acceptNegotiationFromAutobid(loadId, offer, message, token) {
    try {
      if (!offer.accepted) {
        await dexfreight.Drayage.toBookBid(loadId, offer, localStorage.token)
      } else {
        await dexfreight.Drayage.acceptCarrierBidAsBroker(loadId, offer, localStorage.token);
      }
      swal(this.micro.dictionary.SUCCESS, message, 'success');
      this.updateDrayage(loadId);
    } catch (e) {
      console.log(e);
      swal('Error', e?.message || e, 'error');
    }
  }

  async editServices(loadId, payment, token, rateProntoPago = null) {
    try {
      await dexfreight.Drayage.editServices(loadId, payment, token, rateProntoPago);
      swal(this.micro.dictionary.SUCCESS, this.micro.dictionary.PRICING_HAS_BEEN_UPDATED, 'success');
      await this.updateDrayage(loadId);
    } catch (e) {
      console.log(e);
      swal('Error', e?.message || e, 'error');
    }
  }

  async updateDrayage(loadId) {
    await this.obs.updateDrayage(loadId);
    this.ms.listener.dispatchEvent('updatePayment@NewDrayage');
    this.ms.listener.dispatchEvent('Re-GeneratedRate@NewDetails');
    this.ms.listener.dispatchEvent('closeNegotiationEditServicesDialogRef@NewDrayage');
    this.ms.listener.dispatchEvent('closeAutobidModal@NewDrayage');
    this.ms.listener.dispatchEvent('update@drayageList', { show: true });
    this.ms.listener.dispatchEvent('updateNegotiations@NewDrayage');
    this.ms.listener.dispatchEvent('RefreshDocuments@NewDetails');
    this.ms.listener.dispatchEvent('RefreshTopBar@NewDetails');
    this.ms.listener.dispatchEvent('updateServicesPaymentTable@NewDrayage');
    this.ms.listener.dispatchEvent('updatePaymentStatus@NewDrayage');
    this.dialog.closeAll();
  }
}
interface ServiceDry {
  qty: string;
  type: string;
  rate: string;
  units: string;
  active: boolean;
}
