import { EventEmitter, Injectable } from '@angular/core';
import Cluster from '@googlemaps/markerclustererplus';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { Shipment } from '../../interfaces/ShipmentFLT.interface';

@Injectable({
    providedIn: 'root'
})
export class GoogleMap {

    private map: google.maps.Map;
    private geoCode: google.maps.Geocoder;
    private poly: google.maps.Polyline;
    private latLngBounds: google.maps.LatLngBounds;
    private latLng: google.maps.LatLng;
    private clustering: Cluster;
    private circle: google.maps.Circle;
    // Obs | EventEmitter
    private filter: EventEmitter<StateFilter> = new EventEmitter();
    public filters: BehaviorSubject<Partial<IFilterMap>> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];
    public uniqueFilter: EventEmitter<any> = new EventEmitter();
    public shipmentSelect: EventEmitter<any> = new EventEmitter();
    public shipmentCenter: EventEmitter<any> = new EventEmitter();
    public companySelect: EventEmitter<any> = new EventEmitter();
    public hasCustom: EventEmitter<boolean> = new EventEmitter(false);
    // Variables
    private loads: Shipment[];
    private customMarkers: CustomMarker[];
    private pointsBrokers: Object = {};

    constructor() { }


    /**
     * @param id id of DOM Element
     * @param type { TypeMaps }
     * @description Init the map.
     * @author 🔥
     */
    public init(id: string, type: TypeMaps = 'Tracking'): void {
        let spaceToDraw = document.getElementById(`${id}`);

        this.map = new google.maps.Map(spaceToDraw, {
            center: { lat: 37.090, lng: -95.712 },
            zoom: 3,
            disableDefaultUI: true,
            styles: new Date().getHours() >= 18 ? NightMode : null,
            gestureHandling: 'greedy',
        });

        // if (type === 'Carrier') this.initEventCarrierMap();
    }


    public initEventFilterMap(options: OptionsAutocompleteFilter, to: string, map = this.map) {
        let input;
        options.element.forEach(elem => {
            if (elem.nodeName === "INPUT") {
                input = elem
            }
        });
        let autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(input, options)
        autocomplete.addListener('place_changed', () => {
            let place = autocomplete.getPlace();
            place.address_components.forEach((addr, index) => {
                if (addr.types.includes('administrative_area_level_1')) {
                    addr['to'] = to;
                    addr['latLng'] = [place.geometry.location.lat(), place.geometry.location.lng()];
                    // addr['zipCode'] = place.address_components[index + 1].types.includes('postal_code') ? place.address_components[index + 1].long_name : place.address_components[index + 2].long_name || null;
                    map.setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                    map.setZoom(5)
                    this.drawMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }, map);
                    this.filters.next(<IFilterMap><unknown>addr)
                }
            })
        })
    }

    /**
     * @description Only for Carrier users. They can click on the map and get a filter to shipments by state, city.
     * @author 🔥
     */
    private initEventCarrierMap(): void {
        let result: google.maps.GeocoderResult;

        this.geoCode = new google.maps.Geocoder();

        this.map.addListener("click", async ({ latLng: { lat, lng } }) => {
            await this.geoCode.geocode({ address: `${lat()},${lng()}` }, (results, status) => {
                if (status !== "OK") return console.log('Google not found any match');

                // result = results.filter(place => place.types.includes(this.map.getZoom() >= 5 ? 'locality' : 'administrative_area_level_1'))[0]; // Old Version
                result = this.map.getZoom() > 5 ? results.filter(place => place.types.includes('locality'))[0] : null;
                if (!result) return console.log('Google not found any match');

                let state: StateFilter = {
                    short: result.address_components[0].short_name,
                    long: result.address_components[0].long_name
                }
                this.filter.next(state)

                this.map.setCenter(result.geometry.bounds.getCenter());
                this.map.setZoom(5)
            })
        });
    }

    /**
     * @returns return Map Obj
     * @author 🔥
     */
    public get Map(): google.maps.Map {
        return this.map;
    }
    /**
     * @returns Cluster Obj
     * @author 🔥
     */
    public get Cluster(): Cluster {
        return this.clustering;
    }
    /**
     * @returns Custom Obj
     * @author 🔥
     */
    public get CustomMarker(): CustomMarker[] {
        return this.customMarkers
    }
    /**
     * @param path Array of points (lat,lng) { LatLngLocations }
     * @description Get a array of locations and draw a line on the map
     * @author 🔥
     */
    public drawBounds(path): void {
        this.poly = new google.maps.Polygon({
            paths: path,
            fillColor: '#8D1922',
            strokeColor: "#FFFFFF",
            strokeOpacity: 1,
            map: this.map,
            strokeWeight: 2
        });
    }

    /**
     * @param location { LatLngLocations }
     * @description Get obj { LatLngLocations } and draw a marker on the map
     * @author 🔥
     */
    public drawMarker(location: LatLngLocations, map = this.map): void {
        let marker: google.maps.Marker = new google.maps.Marker({
            map,
            position: new google.maps.LatLng(location.lat, location.lng)
        });
    }

    /**
     * @param center {LatLngLocation} or {LatLng}
     * @param radio Number. default 500
     * @description Draw a circle
     * @author 🔥
     */
    public drawCircle(center: LatLngLocations | google.maps.LatLng, radio: number = 500): void {
        this.circle = new google.maps.Circle({
            center,
            strokeColor: "#FFFFFF",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#8D1922",
            fillOpacity: 0.23,
            map: this.map,
            radius: radio
        });
        this.map.setCenter(center);
        this.map.setZoom(8);
    }

    /**
     * @description Return an Observable to subscribe
     * @returns Observable<StateFilter>
     * @author 🔥
     */
    public getStateCarrierMap(): Observable<StateFilter> {
        return this.filter.asObservable();
    }

    /**
     * @param location { LatLngLocations }
     * @param uniqueId Optional param uniqueId to bind to markers (Used only in List)
     * @description Draw a crew of markers on the map
     * @author 🔥
     */
    public drawClustering(location: Shipment[], role: string): void {
        this.resetSpecific(['circle', 'customMarkers', 'poly']);

        this.loads = location;
        let marker = location.filter(({ _tradeRegistry: { state } }) => ['open', 'new'].includes(state)).map((shipment) => {
            return new google.maps.Marker({
                title: shipment.uniqueId,
                position: new google.maps.LatLng(shipment.shipper.lat, shipment.shipper.lng),
                icon: 'https://storage.googleapis.com/dexfreight-webapp-assets/dexImg/googleMaps/circle-marker.png',
                optimized: true,

            })
        });
        marker.map(marker => marker.addListener('click', (e) => {
            this.uniqueFilter.emit(marker.getTitle())
            if (role !== 'carrier') this.shipmentCenter.next(marker);
        }));

        if (this.clustering && this.clustering.getMarkers().length > 0) {
            this.clustering.addMarkers(marker, false);
        } else {
            this.clustering = new Cluster(this.map, marker, {
                imagePath: "https://storage.googleapis.com/dexfreight-webapp-assets/dexImg/googleMaps/m"
            });
        }
        this.hasCustom.emit(false)
        if (role !== 'carrier') this.notOverLappingMarkers()
    }

    /**
     * @param info TypeInfo
     * @param loads Array of Shipment
     * @description Create a Custom Marker showing the Info the user want (Only Carrier)
     * @author 🔥
     */
    public selectInfo(info: TypeInfo, loads: Shipment[]): void {
        this.resetSpecific(['clustering', 'customMarkers']);
        this.customMarkers = loads.map(load => {
            return new CustomMarker({ lat: load.shipper.lat, lng: load.shipper.lng }, this.map, {
                shipment: load,
                title: info,
                role: 'carrier'
            });
        });
        this.customMarkers.map(marker => marker.selectShip.subscribe(ship => this.shipmentSelect.next(ship)));
        this.hasCustom.emit(this.customMarkers.length > 0 ? true : false);
    }

    /**
     * @param carriers Array of [Equipments, Carriers]
     * @param center {LatLngLocation} 
     * @description Create a Custom Markers of Carriers and Shipment, subscribe to click event on that markers
     * @author 🔥
     */
    public shipmentMarker(carriers: any[], center: LatLngLocations, prop: [string, BrokerInfoType, string, boolean?]) {
        this.resetSpecific(['clustering']);

        this.pointsBrokers[prop[1]] = carriers.map(custom => {
            return new CustomMarker({
                lat: prop[1] === 'Past booked shipments' ? custom[prop[0]].coordinates[1] : (custom[prop[0]].lat || custom[prop[0]].latitude || custom[prop[0]].coordinates[0]),
                lng: prop[1] === 'Past booked shipments' ? custom[prop[0]].coordinates[0] : (custom[prop[0]].lng || custom[prop[0]].longitude || custom[prop[0]].coordinates[1])
            }, this.map, {
                role: 'broker',
                searchCarrier: true,
                company: custom.company,
                html: prop[2]
            })
        });

        this.customMarkers = [];
        for (let [key, value] of Object.entries(this.pointsBrokers)) {
            this.customMarkers.push(...(<Array<CustomMarker>>value));
        }

        if (prop[3]) {
            this.customMarkers.push(...[new CustomMarker(center, this.map, { role: 'broker', searchCarrier: false })]);
            this.customMarkers.map(custom => {
                let subs = custom.companyInvite.subscribe(this.companySelect);
                this.subscriptions.push(subs);
            });
            this.searchMarkerUnited();
        }
    }

    /**
     * @description Check if that option existe as property in the Obj. If exist hide or show his info.
     * @param option BrokerInfoType 
     * @author 🔥
     */
    switchInfoBroker(option: BrokerInfoType) {
        let hasProp = this.pointsBrokers[option];

        if (hasProp) {
            for (let custom of hasProp) {
                let hasMap = (<CustomMarker>custom).getMap();
                !hasMap ? (<CustomMarker>custom).setMap(this.map) : (<CustomMarker>custom).setMap(null);
            }
        } else {
            console.error('Thats types of carriers its not exist in this search')
        }
    }
    /**
     * 
     */
    drawRoute(route: google.maps.DirectionsRequest) {
        let directionRender = new google.maps.DirectionsRenderer({ map: this.map });
        let directionService = new google.maps.DirectionsService().route(route, (result, status) => {
            if (status !== 'OK') return console.error('Route not found');
            directionRender.setDirections(result)
        })

    }

    /**
     * @description Clear all Custom Markers
     * @author 🔥
     */
    public clearCustoms() {
        if (this.customMarkers && this.customMarkers.length > 0) {
            this.subscriptions.map(custom => custom.unsubscribe());
            this.customMarkers.map(custom => { custom.reset(); custom.clearMemory(); });
            this.customMarkers = [];
        }
    }
    /**
     * @description Remove the Polyline
     * @author 🔥
     */
    public clearPolyline() {
        if (this.poly) {
            this.poly.setMap(null);
        }
    }

    /**
     * @description Loop an array of marker and change a little his LatLng
     * @author 🔥
     */
    private notOverLappingMarkers(): void {
        google.maps.event.addListener(this.clustering, 'clusterclick', (cluster, ev) => {
            if (cluster.getMarkers().length > 1) {
                let markers = cluster.getMarkers();
                let OverLapping = true;

                markers.slice(1).reduce((prevMarker: google.maps.Marker, marker: google.maps.Marker) => {
                    if (marker.getPosition().equals(prevMarker.getPosition()) === false) {
                        OverLapping = false;
                    }

                    return marker;
                }, markers[0]);

                if (OverLapping) {
                    for (let i = 0; i < markers.length; i++) {
                        markers[i].setPosition(new google.maps.LatLng(markers[i].getPosition().lat() + Number(`0.00${(-1 + i) + i * 5000}`), markers[i].getPosition().lng() + Number(`0.00${(5 + i) + i * 5000}`)));
                    }
                }
            }
        });
    }


    /**
     * @description Save marker with the same location and change it for ramdom location within a radio
     * @author 🔥
     */
    searchMarkerUnited() {
        if (this.customMarkers && this.customMarkers.length > 1) {
            let markers = {};
            this.customMarkers.slice(1).reduce((prevMarker, marker) => {
                if (marker.getPosition().equals(prevMarker.getPosition()) === true) {
                    let id = prevMarker.getPosition().lat();
                    !markers[id] ? markers[id] = [marker] : markers[id].push(marker);
                }
                return marker;
            }, this.customMarkers[0]);

            if (Object.keys(markers).length > 0) {
                for (let key in markers) {
                    let ramdomPositions = this.generateRandomPoints({ 'lat': markers[key][0].getPosition().lat(), 'lng': markers[key][0].getPosition().lng() }, 1000, this.customMarkers.length);
                    for (let i = 0; i < markers[key].length; i++) {
                        markers[key][i].position = new google.maps.LatLng(ramdomPositions[i].lat, ramdomPositions[i].lng);
                        markers[key][i].draw()
                    }
                }
            }
        }
    }

    /**
     * @description Resize the map on div
     * @author 🔥
     */
    public resize(): void {
        google.maps.event.trigger(this.map, "resize");
    }

    /**
     * @description Reset the map
     * @author 🔥
     */
    public reset(): void {
        if (this.poly) this.clearPolyline();
        if (this.circle) this.circle.setMap(null);
        if (this.clustering) this.clustering.clearMarkers();
        if (this.customMarkers && this.customMarkers.length > 0) this.clearCustoms();
        if (this.map) this.map.setZoom(3);
    }
    /**
     * @param name {VariableType}
     * @description Get a name and search if it exists, if it exists then it clears
     * @todo Active the posibility to send an array of {VariableType} 
     * @author 🔥
     */
    public resetSpecific(name: VaribleType[]) {
        let obj: Record<VaribleType, any> = {
            'clustering': () => this.clustering && this.clustering.getMarkers().length > 0 && this.clustering.clearMarkers(),
            'customMarkers': () => this.customMarkers && this.customMarkers.length > 0 && this.clearCustoms(),
            'map': () => this.map && this.map.setZoom(3),
            'poly': () => this.poly && this.clearPolyline(),
            'circle': () => this.circle && this.circle.setMap(null),
        }
        for (let key of name) {
            obj[key]();
        }
    }

    /**
    * Generates number of random geolocation points given a center and a radius.
    * @param  {Object} center A JS object with lat and lng attributes.
    * @param  {number} radius Radius in meters.
    * @param {number} count Number of points to generate.
    * @return {array} Array of Objects with lat and lng attributes.
    */
    generateRandomPoints(center, radius, count) {
        var points = [];
        for (var i = 0; i < count; i++) {
            points.push(this.generateRandomPoint(center, radius));
        }
        return points;
    }


    /**
    * Generates number of random geolocation points given a center and a radius.
    * Reference URL: http://goo.gl/KWcPE.
    * @param  {Object} center A JS object with lat and lng attributes.
    * @param  {number} radius Radius in meters.
    * @return {Object} The generated random points as JS object with lat and lng attributes.
    */
    generateRandomPoint(center, radius) {
        var x0 = center.lng;
        var y0 = center.lat;
        // Convert Radius from meters to degrees.
        var rd = radius / 111300;

        var u = Math.random();
        var v = Math.random();

        var w = rd * Math.sqrt(u);
        var t = 2 * Math.PI * v;
        var x = w * Math.cos(t);
        var y = w * Math.sin(t);

        var xp = x / Math.cos(y0);

        return { 'lat': y + y0, 'lng': xp + x0 };
    }
}

/**
 * 
 *  CUSTOM MARKER
 *  @todo Add algorith to separate markers if they stay together in the same position
 */
class CustomMarker extends google.maps.OverlayView {
    div: HTMLDivElement;
    position: google.maps.LatLng;
    options: MarkersOptions;
    companyInvite: EventEmitter<CompanyCarrier> = new EventEmitter();
    selectShip: EventEmitter<any> = new EventEmitter();
    // filterByShip: EventEmitter<any> = new EventEmitter(); // This is not necessary for now
    constructor(location: LatLngLocations, private map: google.maps.Map, optionsMarker?: MarkersOptions) {
        super();
        this.position = new google.maps.LatLng(location.lat, location.lng);
        this.options = optionsMarker;
        this.setMap(map)
    }


    /**
     * Trigger when we add a Marker
     * @description Trigger the draws method according props and addListener (click) to every marker.
     */
    onAdd(): void {
        if (this.options.role !== 'carrier' && !this.options.searchCarrier) { this.drawForBroker(); }
        if (this.options.role !== 'carrier' && this.options.searchCarrier) { this.drawCarrierOnCircle(); }
        if (this.options.role === 'carrier') this.drawForCarrier();

        this.getPanes().overlayMouseTarget.appendChild(this.div);
        google.maps.event.addDomListener(this.div, 'click', (e) => {
            if (this.options.role !== 'carrier') {
                this.checkIfCheckIsTrue(e.target);
                this.companyEmitted(e)
            } else {
                this.selectShip.emit(this.options.shipment);
                // this.filterByShip.emit(this.options.shipment.uniqueId);
            }
        })
    }

    /**
     * @param e Event usually if a HTMLElement
     * @description Check every child node if exist one with the name Input
     */
    checkIfCheckIsTrue(e) {
        if ((<HTMLElement>e).childNodes.length > 1) {
            for (let element of e.childNodes) {
                if (element['nodeName'] === "INPUT") {
                    if (!(<HTMLInputElement>element).checked) this.carrierClassToggle(e);
                }
            }
        } else if (e.nodeName === "INPUT" && !e.checked) {
            this.carrierClassToggle(e);
        } else if (e.offsetParent.classList.contains('closed') && e.checked) {
            this.carrierClassToggle(e);
        } else {
            this.checkIfCheckIsTrue(e.parentNode);
        }
    }

    /**
     * @param e Event usually if a HTMLElement
     * @description Check on the actual Element if has the Carrier class if not we search in the parent Element
     */
    findClass(e) {
        if ((<HTMLDivElement>e.classList.contains('Carrier'))) {
            return this.companyInvite.emit({ company: this.options.company, type: e.classList.value });
        } else {
            this.findClass(e.parentNode);
        }
    }

    /**
     * @param e Event usually if a HTMLElement
     * @description Check if the actual Element it's a Input Element and emit the company value if not we search to the parent Element
     */
    companyEmitted(e) {
        if (e.target.nodeName === "INPUT") {
            this.companyInvite.emit({ company: this.options.company, checked: e.target.checked });
        } else {
            this.findClass(e.target);
        }
    }

    /**
     * Trigger every time with the re-render map
     * @description Check if the Marker's at screen if not we set it a display none, this way no re-render every time when his not on screen
     */
    draw(): void {
        const position = this.getProjection().fromLatLngToDivPixel(this.position)!;
        const display = Math.abs(position.x) < 4000 && Math.abs(position.y) < 4000 ? "block" : "none";
        if (this.div && display === "block") {
            this.div.style.left = `${position.x - 25}px`;
            this.div.style.top = `${position.y - 25}px`;
        }

        if (this.div && this.div.style.display !== display) {
            this.div.style.display = display;
        }

    }

    /**
     * @description Draw Marker to Carrier.
     */
    drawForCarrier(): void {
        this.div = document.createElement('div');
        this.div.innerHTML = `<b>${this.titleOperation(this.options.title)}</b>`;
        this.div.classList.add('Shipments')
    }

    /**
     * @description Draw the Main shipment for Broker. (has a box in the center)
     */
    drawForBroker(): void {
        this.div = document.createElement('div');
        this.div.classList.add('Shipment')
        let icon = document.createElement('i');
        icon.classList.add(...['fas', 'fa-box']);
        this.div.appendChild(icon);
    }

    /**
     * @description Draw all points (Carriers) inside the radius. His content its variable to Class Props. Check props type
     */
    drawCarrierOnCircle() {
        this.div = document.createElement('div');
        this.div.classList.add(...['Carrier', 'closed']);
        let carrier = document.createElement('div');
        carrier.style.height = '16px';
        carrier.style.overflow = 'hidden'
        carrier.innerHTML = `${this.options.html ? this.options.html : "<img src='https://storage.googleapis.com/dexfreight-webapp-assets/dexImg/googleMaps/circle-marker.png' />"}` +
            `<span>${this.options.company && this.options.company.identity && this.options.company.identity.name}</span>` +
            `<input type="checkbox" />`;
        this.div.appendChild(carrier)
    }

    /**
     * @description Trigger when remove the marker of map
     */
    onRemove(): void {
        if (this.div.parentElement) {
            this.div.parentElement.removeChild(this.div);
            this.companyInvite.unsubscribe()
        }
    }

    /**
     * @description Return position of marker
     * @returns lat and lng
     */
    getPosition() {
        return this.position;
    }

    /**
     * @description return if marker's draggable
     * @returns Boolean
     */
    getDraggable() {
        return false;
    }
    /**
     * @description Remove the marker of map. Triggering the onRemove method
     */
    reset() {
        this.setMap(null)
    }
    /**
     * @description Clear Div variable
     */
    clearMemory() {
        this.div = null;
    }
    /**
     * @description Return Map
     * @returns map
     */
    getMap() {
        return this.map
    }

    /**
     * @param title TypeInfo
     * @description Return a title for Carrier marker by title param
     * @returns String
     */
    titleOperation(title: TypeInfo) {
        let caseObj: Record<TypeInfo, any> = {
            'Distance (mi)': `${this.options.shipment._tripDistance} (mi)` || 0,
            'Match': `${this.options.shipment['score'] && this.options.shipment['score']['score'] || '0'}%`,
            'Price/Mile ($/mi)': `${Number(this.options.shipment._pricePerMile).toFixed(2)} ($/mi)` || 'No Available',
            'Rate': `${this.options.shipment.payment.rate ? `$${this.options.shipment.payment.rate}` : 'Negotiable'}`,
            'Equipment': this.options.shipment.loadInfo.equipmentType[0].capitalize() || 'No Available',
        }
        return caseObj[title];
    }

    /**
     * @param e Event. Usually it's a HTMLElement
     * @description Find the main container who has the Carrier class and change the sibling class (open to closed OR closed to open).
     */
    carrierClassToggle(e) {
        if (e.classList.contains('Carrier')) {
            if ((<HTMLDivElement>e).classList.contains('closed')) {
                (<HTMLDivElement>e).classList.replace('closed', 'open');
            } else {
                (<HTMLDivElement>e).classList.replace('open', 'closed');
            }
        } else {
            this.carrierClassToggle(e.parentNode)
        }
    }

    /**
     * @description Draw a PERICO dancing in the map. Only trigger if in the props param pass a joke in true.
     */
    jokeFunction(): void {
        var img = document.createElement('img');
        img.src = "https://cultofthepartyparrot.com/parrots/hd/parrot.gif";
        img.height = 20;
        img.width = 20
        this.div = document.createElement('div');
        this.div.style.position = "absolute";
        this.div.style.borderRadius = "10px";
        this.div.style.border = "2px solid #f00";
        this.div.appendChild(img)
        this.getPanes().overlayMouseTarget.appendChild(this.div);
        google.maps.event.addDomListener(this.div, 'click', () => {
            google.maps.event.trigger(this, 'click')
        });
        this.draw();
    }
}

// =====================
//      INTERFACES
// =====================


export interface StateFilter {
    short?: string
    long?: string
}

export interface LatLngLocations {
    lat: number
    lng: number
}

export interface MarkersOptions {
    role: 'carrier' | 'shipper' | 'broker',
    shipment?: Shipment,
    company?: any,
    title?: TypeInfo,
    html?: HTMLElement | string,
    searchCarrier?: boolean
    joke?: boolean
}

export interface CompanyCarrier {
    company: object,
    checked?: boolean,
    type?: string
}

export interface IFilterMap {
    long_name: string
    short_name: string
    type?: Array<string>
    latLng: [number, number]
    zipCode?: string
    to: string
}
export interface OptionsAutocompleteFilter extends google.maps.places.AutocompleteOptions {
    element: any
}
export type VaribleType = 'clustering' | 'circle' | 'poly' | 'customMarkers' | 'map';
export type TypeMaps = "Broker" | "Shipper" | "Carrier" | "Tracking" | "Show" | "Filter";
export type TypeInfo = "Equipment" | "Rate" | "Price/Mile ($/mi)" | "Distance (mi)" | "Match";
export type BrokerInfoType = 'Past booked shipments' | 'Capacity available' | 'Carrier Working Zones' | 'Active bids' | 'Headquaters Location' | 'Shipment In Transit' | 'Previous Search' | string;
const NightMode = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
];