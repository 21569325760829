export const RoutesApp: Routes = {
    Profile: '/app/profile',
    Faq: '/app/faq',
    Accounts: '/app/accounts',
    Dashboard: '/app/dashboard',
    Chat: '/app/chatting/chatList',
    Shipment: {
        FTL: {
            Create: '/app/shipment/create',
            List: '/app/shipment/list',
            Details: (id, action?) => action ? `/app/shipment/details/ftl/${id}?action=${action}` : `/app/shipment/details/ftl/${id}`,
            My: '/app/shipment/myShipments',
            Search: '/app/shipment/search'
        },
        Drayage: {
            Create: '/app/shipment/drayage/create',
            List: '/app/shipment/drayage/list',
            Details: (id: number | string) => `/app/shipment/drayage/newdetails/${id}`,
            My: '/app/shipment/drayage/myDrayages',
            CostPresets: '/app/shipment/drayage/cost-presets',
            Import: "/app/shipment/drayage/csvHistory"
        }
    },
    Project: {
        Create: '/app/project/create',
        List: '/app/project/list',
        Details: (id: number | string) => `/app/project/details/${id}`,
    },
    SuperAdmin: {
        Companies: '/app/super-admin/companies',
        Analytics: '/app/super-admin/analytics',
        Details: '/app/super-admin/details',
        Login: '/admin-login',
        Payments: '/app/super-admin/payments',
        Invoices: '/app/super-admin/invoice-list'

    },
    Assets: {
        Driver: '/app/assets/driver',
        Equipment: '/app/assets/equipment',
        BrokerList: '/app/assets/favorite-brokers',
        DriverProfile: (id) => `/app/assets/driver-profile/${id}`
    },
    Invoice: {
        List: '/app/invoice/list',
        Details: (id) => `/app/invoice/details/${id}`,
        PaymentPlugin: '/app/invoice/payment-plugin'
    },
    Login: '',
    Premium: {
        Pricing: '/app/premium/pricing',
        TourPlanning: '/app/premium/tour-planning',
        PricingSpecific: (id) => `/app/premium/pricing/${id}`
    },
    Settings: {
        Hub: {
            Documents: '/app/settings/hub/documents',
            Setup: '/app/settings/hub/set-ups',
        },
        Main: '/app/settings',
        Locations: '/app/settings/locations',
        Integrations: '/app/settings/integrations',
        ShipmentPreference: '/app/settings/shipmentspreferences',
        User: '/app/settings/users',
        Crypto: '/app/settings/crypto/account'
    },
    Tracking: {
        Container: (id, containerId) => `app/tracking/${id}/${containerId}`,
        General: '/app/tracking',
        Specific: (id) => `/app/tracking/${id}`
    },
    Cpin: '/cpin',
    Rating: '/rating'
}

interface Routes {
    Dashboard: string
    Profile: string
    Accounts: string
    Chat: string
    Faq: string
    Invoice: {
        List: string
        Details: (id: number | string) => string
        PaymentPlugin: string
    }
    Settings: {
        Main: string
        Hub: {
            Documents: string
            Setup: string
        }
        User: string
        Locations: string
        Integrations: string
        ShipmentPreference: string
        Crypto: string
    }
    Tracking: {
        General: string
        Specific: (id: number | string) => string
        Container: (id: number | string, container: number | string) => string
    }
    Shipment: {
        FTL: {
            Create: string
            List: string
            Details: (id: number | string, action?: string) => string
            My: string,
            Search: string
        }
        Drayage: {
            Create: string
            List: string
            Details: (id: number | string) => string
            CostPresets: string
            My: string,
            Import: string
        }
        Update?: (id: number) => string
    }
    Project: {
        Create: string
        List: string
        Details: (id: number | string) => string
    },
    SuperAdmin: {
        Details: string
        Login: string
        Companies: string
        Payments: string
        Analytics: string
        Invoices: string
    }
    Premium: {
        PricingSpecific: (info: any) => string
        Pricing: string
        TourPlanning: string
    }
    Assets: {
        Driver: string
        Equipment: string
        BrokerList: string
        DriverProfile: (id: string) => string
    }
    Cpin?: string
    Rating?: string
    Login: string
}
