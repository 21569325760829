export const environment = {
    production: false,
    prov: false,
    hostIp: 'http://fast-src.dexcarga.com',
    // apiPort: 5000, // camilo
    apiPort: 2000, // danny
    // apiPort: 9000, // Carlos
    // apiPort: 4000, // Jesus Escorcia
    local: true,

    analyticsURL: 'https://analytics.dexfreight.io',  
    tourPlanningHost: 'https://analytics.dexfreight.io/api/v1/dev',
    analyticsHost: 'https://analytics.dexfreight.io/api/v1/dev',
    recommendationEngineHost: 'https://analytics.dexfreight.io/api/v1/dev',
  
    prefixUrl: 'https://fast-',
    domain: '.dexcarga.com',
    plaid_pk: 'f0ac777f7b0e6c45f2afd93acc723d',

    authUrl: 'https://fast-auth.dexcarga.com',
    coreUrl: 'https://fast-core.dexcarga.com',
    realtimeUrl: 'https://fast-realtime.dexcarga.com',
    scheduleUrl: 'https://fast-schedule.dexcarga.com',
    integrationsUrl: 'https://fast-integrations.dexcarga.com',
    docsUrl: 'https://fast-docs.dexcarga.com',
    managerUrl: 'https://fast-manager.dexcarga.com',
    srcUrl: 'https://fast-src.dexcarga.com'
};
