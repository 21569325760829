import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateFormatService {
    static DATE_UNITS = {
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    }
    constructor() {
    }

    static getSecondsDiff(timestamp) {
        return (Date.now() - timestamp) / 1000
    }

    static getUnitAndValueDate(secondsElapsed) {
        for (const [unit, secondsInUnit] of Object.entries(this.DATE_UNITS)) {
            if (secondsElapsed >= secondsInUnit || unit === "second") {
                const value = Math.floor(secondsElapsed / secondsInUnit) * -1
                return { value, unit }
            }
        }
    }

    static getTimeAgo(timestamp, lang) {
        let language = lang

        const rtf = new Intl['RelativeTimeFormat'](language)

        const secondsElapsed = this.getSecondsDiff(timestamp)
        const { value, unit } = this.getUnitAndValueDate(secondsElapsed)
        return rtf.format(value, unit as any)
    }

    static formatDate(date, { timeZone = 'America/Bogota', language = localStorage.user.settings && JSON.parse(localStorage.user).settings.lang === 'en' ? 'en' : 'es', monthType = 'short' }: { timeZone?: string, language?: string, monthType?: Intl.DateTimeFormatOptions['month'] }) {
        const rtf = new Intl.DateTimeFormat(language, {
            timeZone,
            month: monthType,
            day: 'numeric',
            year: 'numeric',
        }).format(date)

        return rtf;
    }
}