import { Injectable } from '@angular/core';
import { Settings } from 'interfaces/common/db/models/companies';
import { AuthService } from './services/back-services/auth.service';
import { Microservices } from '@microservices';
import { Subscription } from 'rxjs';
import { CacheListener } from './@utils/cache-listener';
import { UserService } from './user.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {

  companySettings: CacheListener<Settings | null> = new CacheListener(null, this.syncCompanySettings.bind(this));
  private listeners: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private micro: Microservices
  ) {}

  setupListeners () {
    this.listeners.push(
      this.companySettings.syncWhenChanges(this.companyService.company),
      this.companySettings.bind(this.companyService.company)
    );
  }

  removeListeners () {
    this.listeners.forEach(listener => listener.unsubscribe());
  }
  
  async syncCompanySettings () {
    const token = await this.authService.token.get();
    
    return await this.micro.Obs.Companies.Settings.getSettings(token);
  }

  clear () {
    this.companySettings.clear();
  }
}
