import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services';
import { Observable, throwError, of, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import dexFreight from '@microservices';
import { ReportsDashMexService } from 'src/app/dashboard-mex/components/reports.service';

@Injectable()
export default class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private _reportDashMex: ReportsDashMexService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!environment.production) {
            req = req.clone({
                url: req.url.replace('93', '92')
            })
        }

        if (req.url.includes("/authentication"))
            return next.handle(req);
        
        return from(this.addToken(req)).pipe(
            switchMap(req => next.handle(req)),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }

    async addToken(req) {
        let token;

        if (req.url.includes('analytics_charts')) {
            token = await this.authService.ensureToken('analyticsToken');
        } else if (req.url.includes('tour_planning')) {
            token = await this.authService.ensureToken('tourPlanningToken');
        } else if (req.url.includes('recommendation_engine')) {
            token = await this.authService.ensureToken('recommendationToken');
        } else if (req.url.includes('skyangel')) {
            token = await this.authService.ensureToken('skyAngelToken');
        } else if (req.url.includes('api/v1/dashboard/login_dashboard')) {
            //token = await this.ensureToken('dashboardMexToken');
        } else if (req.url.includes('/api/v1/reports')) {
            token = await this.authService.ensureToken('dashboardMexReportToken');
        }

        if (!token) return req;

        return req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
        });
    }

    private getAuthTokens(): Observable<any> {
        try {
            const port = environment.production ? '12002' : '12001'
            const payload = {
                userId: localStorage.userId,
                companyId: localStorage.companyId,
                database: environment.production ? 'production' : 'development'
            }

            return from(Promise.all(
                [this.authService.recommendationEngineAuth(payload),
                this.authService.analyticsAuth(payload),
                this.authService.tourPlanningAuth(payload),
                // @ts-ignore
                dexFreight.Tracking.getSkyAngelToken(localStorage.token),
                this._reportDashMex.dashboardAuthentication(port, payload),
                this._reportDashMex.reportsAuthentication(port, payload),]
            )).pipe(
                tap(tokens => {
                    const [
                        recommendationToken, 
                        analyticsToken, 
                        tourPlanningToken, 
                        skyAngelToken,  
                        dashboardMexToken,
                        dashboardMexReportToken
                    ] = tokens.map(res => res?.token || res?.access_token);
                    localStorage.setItem("recommendationToken", recommendationToken);
                    localStorage.setItem("analyticsToken", analyticsToken);
                    localStorage.setItem("tourPlanningToken", tourPlanningToken);
                    localStorage.setItem("skyAngelToken", skyAngelToken?.token);
                    localStorage.setItem('dashboardMexToken', dashboardMexToken)
                    localStorage.setItem('dashboardMexReportToken', dashboardMexReportToken)
                })
            )
        } catch (error) {
            return of(error);
        }
    }
}
