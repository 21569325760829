import { Injectable } from "@angular/core";
import { LangEN } from "interfaces/common/meta/interfaces";
import dexFreight, { Microservices } from "../../microservices";
import { CacheListener } from "src/app/@utils/cache-listener";
import { CompanySettingsService } from "src/app/company-settings.service";
import { Subscription } from "rxjs";

@Injectable()
export class LanguageServices {
  public language: CacheListener<string> = new CacheListener(location.host.split('.').includes('dexcarga') ? 'es' : 'en', this.syncLanguage.bind(this))
  
  private listeners: Subscription[] = [];
  private weightUnits: any;

  constructor(
    private micro: Microservices,
    private companySettingsService: CompanySettingsService
  ) { }

  setupListeners() {
    this.listeners.push(
      this.language.syncWhenPropertyChanges(this.companySettingsService.companySettings, 'lang')
    );
  }

  removeListeners() {
    this.listeners.forEach(listener => listener.unsubscribe());
    this.listeners = [];
  }
  
  async syncLanguage (lang?: string) {
    const companySettings = await this.companySettingsService.companySettings.get();
    return lang ?? companySettings.lang ?? localStorage.getItem('lang') ?? this.defaultLanguage();
  }

  async syncDictionary() {
    const settings = await this.companySettingsService.companySettings.get();

    return await this.getDictionary(settings.lang);
  }

  async getDictionary(language: string) {
    switch (language) {
      case "es":
        return (await import("../../@lang/es")).ESLang.result as any as LangEN;
      case "en":
        return (await import("../../@lang/en")).ENLang.result as any as LangEN;
      case "co":
        return (await import("../../@lang/co")).COLang.result as any as LangEN;
      default:
        return (await import("../../@lang/en")).ENLang.result as any as LangEN;
    }
  }

  /**
   * Function to transform lb to kg or kg to lbs
   * @param metric
   * @returns metric transformet to the value in user.metric (lb or kg)
   */
  transformWeight(metric) {

    this.weightUnits = this.micro.getCurrentSetting.weight
    // value of a kg in lbs
    const kgToLbs: number = 2.2046;
    try {
      if (this.weightUnits === "kg") {
        return metric * kgToLbs;
      } else return metric;
    } catch (error) {
      console.log(error);
    }
  }

  defaultLanguage() {
    return location.host.split('.').includes('dexcarga') ? 'es' : 'en';
  }
}
