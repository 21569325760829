export enum NotificationType {
    bidUpdated = 'bidUpdated',
    bidAccepted = 'bidAccepted',
    bidDeclined = 'bidDeclined',
    createDrayage = 'createDrayage',
    documentSigned = 'documentSigned',
    documentSignedRate = 'documentSignedRate',
    documentsAccepted = 'documentsAccepted',
    documentDeclined = 'documentDeclined',
    documentSignedDrayage = 'documentSignedDrayage',
    documentsAcceptedDrayage = 'documentsAcceptedDrayage',
    driverChanged = 'driverChanged',
    messageChat = 'messageChat',
    newAgreementUploaded = 'newAgreementUploaded',
    quickbooksAuthUrl = 'quickbooksAuthUrl',
    rateConfirmationGeneratedDrayage = 'rateConfirmationGeneratedDrayage',
    shipment = 'shipment',
    shipmentCreated = 'shipmentCreated',
    shipmentStageChange = 'shipmentStageChange',
    shipmentReadyForPickup = 'shipmentReadyForPickup',
    saverRateConfirmationDrayage = 'saverRateConfirmationDrayage',
    bidAcceptedDrayageChat = 'bidAcceptedDrayageChat',
    bidAcceptedDrayage = 'bidAcceptedDrayage',
    shipmentInfoUpdatedDrayage = 'shipmentInfoUpdatedDrayage',
    containerUpdatedDrayage = 'containerUpdatedDrayage',
    steamshipUpdatedDrayage = 'steamshipUpdatedDrayage',
    driverAssigned = 'driverAssigned',
    auctionStartDrayage = 'auctionStartDrayage',
    newAgreementAccepted = 'newAgreementAccepted',
    agreementDrayage = 'agreementDrayage',
    bidDeclinedDrayage = 'bidDeclinedDrayage',
    generateBol = 'generateBol',
    trackingDrayage = 'trackingDrayage',
    trackingDrayageSilent = 'trackingDrayageSilent',
    informationEditDrayageSilent = 'informationEditDrayageSilent',
    informationEditDrayage = 'informationEditDrayage',
    bidAcceptedDrayageLog = 'bidAcceptedDrayageLog',
    driverAssignedLog = 'driverAssignedLog',
    deliveredDrayage = 'deliveredDrayage',
    editedServicesPaymentTableDrayageDetails = 'editServicesDrayages',
    invoiceConfiguration = 'invoiceConfiguration'
  }
  