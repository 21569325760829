enum KeyEventCommand {
    DrayageCreate = "c",
    DrayageList = "l",
    DrayageImport = "i",
    Tracking = "r",
    Invoices = "f",
    Settings = "a",
    Logout = "o",
    Dashboard = "d",

    Autofill = "0",
    Submit = "Enter"
}

export default KeyEventCommand;