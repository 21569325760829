<div class="container">
  <main id="content" class="error-container" role="main">
    <div class="row justify_center">
      <div class="col-xl-4 col-md-6 col-10">
        <div class="error-container">
          <h1 class="error-code">404</h1>
          <p class="error-info">
            Opps, it seems that this page does not exist.
          </p>
        </div>
      </div>
    </div>
  </main>

  <footer class="page-footer">
  </footer>
</div>