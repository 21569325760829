import { Injectable } from '@angular/core';
import { Microservices } from '../../microservices';
import { MainService } from '../main/main.service';


@Injectable({
  providedIn: 'root'
})
export class MissingListByShipmentService {
  private list: Array<Missing> = [];
  private documents: boolean = JSON.parse(localStorage.getItem('@haveDocuments'));
  private actions: {};
  private role: string = localStorage.getItem('role')
  constructor(
    private ms: MainService,
    private micro: Microservices) {
  }

  /**
   * @description We get a array of action to deleted and iterate the list to find and remove it
   * @param list Array of next actions
   * @param type Array of action to delete
   * @author 🔥
   */
  removeActionOfList(list: Missing[], action: Action[]) {
    list = list.filter((value) => !action.includes(value.type));
    return list
  }

  /**
   * @description We check is the all booking is completed
   * @param shipment FTL Shipment obj
   * @author 🔥
   */
  isRateConfAccepted(shipment): boolean {
    return shipment._booking.shipper.checklist.shipper.check && shipment._booking.shipper.checklist.docs.check && shipment._booking.shipper.checklist.consignee.check && shipment._booking.shipper.checklist.appointment.check
  }

  /**
   * @description Using the company's id we can check who is the Broker and who is the Carrier. We get a list of actions can do every role
   * and depend of who is the broker o the carrier we removed that actions of the list and return the rest.
   * @param shipment FTL Shipment Obj
   * @param rateConf Rate Confirmation Document Obj
   * @param agreement Agreement Document Obj
   * @param role Role
   * @author 🔥
   */
  getListMissing(shipment?: any, rateConf?: any, agreement?: any, role?: Role) {
    const { ms } = this;
    //TODO: Added documents step
    this.actions = {
      'new': [{
        label: this.micro.dictionary.INVITE,
        type: 'INVITE'
      },
      {
        label: this.micro.dictionary.ONBOARD,
        type: 'ONBOARD'
      }, {
        label: 'Add Origin & Destination Info',
        type: 'APPOINMENT',
      }],
      'open': [{
        label: this.micro.dictionary.INVITE,
        type: 'INVITE'
      },
      {
        label: this.micro.dictionary.ONBOARD,
        type: 'ONBOARD'
      }, {
        label: 'Add Origin & Destination Info',
        type: 'APPOINMENT',
      }, {
        label: 'Negotiate',
        type: 'BID'
      }],
      'awarded': [{
        label: this.micro?.dictionary?.APPROVE_DOCUMENTS,
        type: 'APPROVED'
      }, {
        label: 'Add Origin & Destination Info',
        type: 'APPOINMENT',
      }, {
        type: 'RATE_CONFIRMATION',
        label: this.micro.dictionary.GENERATE_RATE_CONFIRMATION
      }, {
        label:  this.micro.dictionary.SIGN_AGREEMENT,
        type: 'SIGN_CONF'
      }, {
        label: 'Add Driver',
        type: 'DRIVER',
      }, {
        label:   this.micro.dictionary.SIGN_RATE_CONFIRMATION,
        type: 'SIGN_RATE',
      }]
    }

    let arr = (<Array<Missing>>this.actions[shipment._tradeRegistry.state]);
    let actToDelete: Action[] = [];
    if (role !== "carrier") {
      actToDelete.push('DRIVER', 'SIGN_CONF', 'SIGN_RATE');
      if (shipment.bids && shipment.bids.bidder && shipment.bids.bidder === role) actToDelete.push('BID');
      if (shipment._booking.shipper.checklist.appointment.check) actToDelete.push('APPOINMENT');
      if (shipment._booking.shipper.checklist.docs.check) actToDelete.push('APPROVED');
      if (this.isRateConfAccepted(shipment) && rateConf.url) actToDelete.push('RATE_CONFIRMATION');
      return this.removeActionOfList(arr, actToDelete);
    }

    if (role === "carrier") {
      actToDelete.push('INVITE', 'ONBOARD', 'APPROVED', 'APPOINMENT', 'RATE_CONFIRMATION');
      if (shipment.bids && shipment.bids.bidder && shipment.bids.bidder === role) actToDelete.push('BID');
      if (agreement && agreement.accepted) actToDelete.push('SIGN_CONF');
      if (shipment._booking.carrier.checklist.attachDriver.check) actToDelete.push('DRIVER');
      if (rateConf && rateConf.accepted) actToDelete.push('SIGN_RATE')
      return this.removeActionOfList(arr, actToDelete);
    }
  }
}

type Action = 'INVITE' | 'ONBOARD' | 'APPOINMENT' | 'DOCUMENTS' | 'BID' | 'APPROVED' | 'RATE_CONFIRMATION' | 'SIGN_RATE' | 'SIGN_CONF' | 'DRIVER';
export interface Missing {
  label?: string;
  action?: () => any | void;
  type?: Action;
  value?: string;
}
export enum Role {
  'shipper' = 'shipper',
  'broker' = 'broker',
  'carrier' = 'carrier'
}
