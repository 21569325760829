import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PrimeTrustService {
    private baseUrl = `https://dev-api.dexfreight.io:${environment.production ? '9093' : '9092'}/primetrust`;
    private accountId = '';
    private prime_trustToken = localStorage.getItem('ptToken');
    private account = {} as any;
    private accountSubject$: BehaviorSubject<any> = new BehaviorSubject(null);
    private notificationSubject$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private _http: HttpClient) {
        // this.socket = connect("wss://dev-api.dexfreight.io:7010");

        // this.socket.on('connect', () => {
        //     this.socket.emit('login', this.prime_trustToken);

        //     this.socket.on('login:error', err => {
        //         console.log(err)
        //     });

        //     this.socket.on('notification', ({ type, data }) => {
        //         console.log(data)
        //         this.notificationSubject$.next(data)
        //     })
        // });
        // this.hasPTAccout().toPromise();
        // this.getContactId().toPromise();
    }


    /**
     *
     * WE NEED TO COMMENT ALL THIS SERVICE AND ORGANISE IT BETTER
     */

    updateAccountId({ id }) {
        this.accountId = id;
    }
    get accountID() {
        return this.accountId;
    }

    getAccountObs() {
        return this.accountSubject$.asObservable();
    }

    getNotificationObs() {
        return this.notificationSubject$.asObservable();
    }

    hasPTAccout() {
        return this._http.post(this.baseUrl + '/accounts/accountIdFromDID', { token: this.prime_trustToken })
            .pipe(
                mergeMap(({ result }: any) => this.isAccountFrooze(result).pipe(
                    map((account: any) => {
                        return {
                            id: result,
                            freeze: account?.result?.body?.data?.attributes['solid-freeze'] ?? true
                        }
                    })
                )),
                tap(v => this.accountSubject$.next(v)),
                tap(v => this.updateAccountId(v))
            )
    }

    getAccountId(did: string) {
        return this._http.post(this.baseUrl + '/accounts/accountIdFromDID', { token: this.prime_trustToken, did })
    }

    getAccount(id: string) {
        return this._http.get(this.baseUrl + `/accounts/account?token=${this.prime_trustToken}&accountId=${id}`)
    }
    isAccountFrooze(id: string) {
        return this._http.get(this.baseUrl + `/accounts/account?token=${this.prime_trustToken}&accountId=${id}`)
    }

    getCashTransaccion() {
        return this._http.post(this.baseUrl + '/payments/cashTransactions', {
            token: localStorage.getItem('ptToken')
        }).pipe(
            map(({ result }: any) => result.body.data),
            map((result) => result.map(transaction => {
                let from = transaction.relationships['from-account'].links.related.split('/').reverse()[0];
                let to = transaction.relationships['to-account'].links.related.split('/').reverse()[0];
                transaction.from = from;
                transaction.to = to;
                return transaction;
            }))
        )
    }

    getAssets() {
        return this._http.get(this.baseUrl + `/assets/assets?token=${this.prime_trustToken}`)
    }
    getAssetsTransactions() {
        return this._http.get(this.baseUrl + `/assets/assetTransactions?token=${this.prime_trustToken}&accountId=${this.accountId}`)
    }
    getAccountBalance() {
        return this._http.post(this.baseUrl + '/balance/cashBalance', {
            token: this.prime_trustToken,
        })
    }
    // ASK HOW
    withdrawAsset(asset) {
        console.log(asset)
        return this._http.post(this.baseUrl + '/assets/assetWithdraw', {
            token: this.prime_trustToken,
            asset
        })
    }
    depositAsset(asset) {
        return this._http.post(this.baseUrl + '/assets/assetDeposit', {
            token: this.prime_trustToken,
            asset
        })
    }
    getAssetBalance(assetId: string) {
        return this._http.post(this.baseUrl + '/balance/assetBalance', {
            token: this.prime_trustToken,
            assetId
        })
    }
    transferAsset(asset) {
        return this._http.post(this.baseUrl + '/assets/assetTransfer', {
            token: this.prime_trustToken,
            asset
        })
    }
    getContactId() {
        return this._http.get(this.baseUrl + `/contacts/contacts?token=${this.prime_trustToken}`)
            .pipe(
                tap(({ result }: any) => this.account = result[0]),
            )
    }
    //  PLAID METHODS
    addFundingSource({ account_id, public_token, account }) {
        return this._http.post(this.baseUrl + '/payments/addFundingSource', {
            "token": this.prime_trustToken,
            "fs": {
                "plaid-public-token": public_token,
                "plaid-account-id": account_id,
                "funds-transfer-type": 'ach',
                "ach-check-type": 'personal'
            }
        })
    }

    getFundingSource() {
        return this._http.post(this.baseUrl + '/payments/transferMethods', { token: this.prime_trustToken });
    }
    getContributions() {
        return this._http.post(this.baseUrl + '/payments/contributions', { token: this.prime_trustToken })
    }
    createContribution(transfer) {
        return this._http.post(this.baseUrl + '/payments/contribution', {
            token: this.prime_trustToken,
            transfer
        })
    }
    getDisbursement() {
        return this._http.post(this.baseUrl + '/payments/disbursements', {
            token: this.prime_trustToken
        })
    }
    createDisbursement(transfer) {
        return this._http.post(this.baseUrl + '/payments/disbursement', {
            token: this.prime_trustToken,
            transfer
        })
    }


    getDocumentCheck() {
        return this._http.post(this.baseUrl + '/kyc/documentsCheck', { token: this.prime_trustToken, accountId: this.accountID, contactType: 'company' })
    }
    getIdentificationCheck() {
        return this._http.post(this.baseUrl + '/kyc/identificationCheck', { token: this.prime_trustToken })
    }
    getAntiMoneyLaunderingCheck() {
        return this._http.post(this.baseUrl + '/kyc/antiMoneyLaundering', { token: this.prime_trustToken })
    }

    // Frooze
    freezeAccount() {
        return this._http.post(this.baseUrl + '/accounts/freeze', {
            token: this.prime_trustToken,
            type: 'all'
        }).pipe(
            tap(v => this.hasPTAccout().toPromise())
        )
    }

    unfreezeAccount() {
        return this._http.post(this.baseUrl + '/accounts/unfreeze', {
            token: this.prime_trustToken,
            type: 'all'
        }).pipe(
            tap(v => this.hasPTAccout().toPromise())
        )
    }

    uploadedDocument({ data, extension, label }) {
        return this._http.post(this.baseUrl + '/kyc/uploadedDocument', {
            token: this.prime_trustToken,
            document: {
                "label": label,
                "account-id": this.accountId,
                "file": data,
                "description": "test 3.33",
                "public": "true",
                "allow-download": "true",
            }
        })
    }

    documentCheck(documentId: string, type: 'front' | 'back' | 'address', kycType: string, frontId?: string) {
        const obj = {
            "contact-id": this.account.id,
            "identity": ['front', 'back'].includes(type),
            "identity-photo": ['front', 'back'].includes(type),
            "proof-of-address": ['address'].includes(type),
            "uploaded-document-id": !['back'].includes(type) && !frontId ? documentId : frontId,
            "kyc-document-type": kycType,
            "kyc-document-country": 'US'
        }

        if (['back'].includes(type) && frontId) {
            obj["backside-document-id"] = documentId;
        }

        return this._http.post(this.baseUrl + '/kyc/documentCheckKYC', {
            token: this.prime_trustToken,
            document: { ...obj }
        })
    }

    getCompanies() {
        return this._http.post(this.baseUrl + '/accounts/companies', { token: this.prime_trustToken })
    }

    getContactOwner() {
        return this._http.get(this.baseUrl + `/contacts/contacts?token=${this.prime_trustToken}&contactType=company`)
    }
    getContactPerson() {
        return this._http.get(this.baseUrl + `/contacts/contacts?token=${this.prime_trustToken}&contactType=natural_person`)
    }
    updateOwnerInformation(contact: any, contactId: string) {
        return this._http.post(this.baseUrl + '/contacts/contactUpdate', {
            token: this.prime_trustToken,
            contact,
            contactId
        })
    }
}
