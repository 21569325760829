import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { MainService } from '../main/main.service';
import { Microservices } from '../../microservices';
@Injectable()
export class RouterService {
  constructor(private router: Router, private ms: MainService, private location: Location, public micro: Microservices, private route: ActivatedRoute) {

    this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationEnd) {
        const token = localStorage.getItem('token');
        const index = event.url.indexOf('?');
        const url = index === -1 ? event.url : event.url.substring(0, index);
        let tokenInfo;
        const queryParam = this.route.snapshot.queryParams
        if (queryParam.token) {
          try {
            tokenInfo = jwt_decode(queryParam.token);
          } catch (error) {
            console.log({ error });
          }
        }
        if (token) {
          if (['/', '/admin-login', '/registry', '/reset', '/details', '/rating', '/cpin', '/invite', '/rate-confirmation', '/driver-confirmation',
            '/favorite-confirmation'].includes(url)) {
            this.ms.setTitle(this.ms.cts.getRoutes()[0].name);
            if (localStorage.role === 'super-admin') {
              this.router.navigate([this.ms.cts.getRoutes()[8].url]);
            } else {
              this.router.navigate([this.ms.cts.getRoutes()[0].url]);
            }
          }
          else if ((tokenInfo && tokenInfo.user.companyId === localStorage.companyId) && url === '/registry') {
            this.router.navigate([`/app/shipment/details/ftl/${tokenInfo.shipmentId}`])
          }
          else {
            const route = this.ms.cts.getRoutes().filter(e => url.substr(1).includes(e.url))[0];
            if (!route || !route.roles.includes(localStorage.role)) {
              this.router.navigate(['/']);
            }
            else {
              this.ms.setTitle(route.name);
            }
          }
        } else if (!token && ['/', '/admin-login', '/registry', '/reset', '/details', '/rating', '/cpin', '/rate-confirmation', '/invite', '/track', 'shipmentId',
          '/driver-confirmation', '/favorite-confirmation'].indexOf(url) === -1) {
          this.ms.clear();
          this.router.navigate(['']);
        }
      }
    });
  }

}
