import { Injectable } from '@angular/core';
import { CacheListener } from './@utils/cache-listener';
import { ServicesDrayage } from './interfaces';
import { Subscription } from 'rxjs';
import dexFreight from '@microservices';
import { AuthService } from './services/back-services/auth.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class EnumsService {

  public drayageServices: CacheListener<ServicesDrayage[]> = new CacheListener([], this.syncDrayageServices.bind(this));

  private listeners: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private companyService: CompanyService
  ) { }

  setupListeners () {
    this.listeners.push(
      this.drayageServices.syncWhenChanges(this.companyService.company),
      this.drayageServices.bind(this.companyService.company)
    );
  }

  removeListeners () {
    this.listeners.forEach(listener => listener.unsubscribe());
  }

  async syncDrayageServices () {
    const token = await this.authService.token.get();

    return await dexFreight.DataLookUp.get<ServicesDrayage>(token);
  }
}
