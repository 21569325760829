import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { MainService } from '../main/main.service';
import { ConstantsService } from '../contants/constants.service';
import { PastDataService } from '../past-data/pastData.service';

import { Shipper, Consignee, Shipment } from '../../interfaces/ShipmentFLT.interface';
import dexFreight, { Microservices } from '../../microservices';
import swal, { SweetAlertResult } from 'sweetalert2';

declare let H;

interface LocationD {
  return: any
  pickup: any
  delivery: any
}
@Directive()
@Injectable({
  providedIn: 'root'
})
export class HeremapsService {
  @Output() invite = new EventEmitter<Shipment>();
  @Output() profile = new EventEmitter<string>();
  filter: EventEmitter<string> = new EventEmitter();
  eventTrigger: EventEmitter<[string, boolean]> = new EventEmitter();
  deleteFilter: EventEmitter<any> = new EventEmitter();
  private plataform: any;
  private defaultLayer;
  private map: any;
  private markers = {}
  private carriers = {}
  private last_location: any;
  private rotation: number;
  private markersIcon = {};
  private stepRoute = new H.geo.LineString();
  private ui: any;
  private geoCoding: any;
  private router: any
  private clustering;
  private equipmentGroup = new H.map.Group()
  private groupTruck = new H.map.Group();
  private drayageGroup = new H.map.Group();
  private layerClus;
  constructor(private pastData: PastDataService, private ms: MainService,
    public micro: Microservices, private cts: ConstantsService) {
    this.plataform = new H.service.Platform({
      'apikey': '6oudu4SvQzHSKrqVYAtGQNfYJMDfMyurx5p1EQy3P6E'
    });
    this.defaultLayer = this.plataform.createDefaultLayers();
    this.markersIcon = this.cts.getIconMarkers();
    this.geoCoding = this.plataform.getSearchService();
    this.router = this.plataform.getRoutingService();
    this.groupTruck.id = 'Drivers';
  }

  subscribeToTracking() {
    let icon = new H.map.DomIcon(this.markersIcon['TypeLocation']('<i class="fa fa-truck" aria-hidden="true"></i>', 'red'))
    this.pastData.lastLocation$.subscribe(data => {
      if (data && data.length === 0) return;
      if (data && data.length >= 1) data.map(location => this.addMarkerDriver(icon, location));
      if (data && !data.length) this.addMarkerDriver(icon, data);
    });
    this.pastData.tracking$.subscribe(data => {
      this.reDraw(data)
    });
  }

  /**
   *  Draw Map 
   * @param map HTMLElement.
   * @param mapEvent Boolean. If you want active the map events.
   * @auhtor 🔥
   */
  createMap(map: HTMLElement, mapEvent?: boolean): void {
    this.map = new H.Map(map, this.defaultLayer.raster.normal.map, {
      center: { lat: 37.090, lng: -95.712 },
      pixelRatio: window.devicePixelRatio || 1,
      engineType: H.map.render.RenderEngine.EngineType.P2D,
      zoom: 3.4
    });
    window.addEventListener('resize', () => this.map.getViewPort().resize());
    window.addEventListener('resizeMapShipmentList', () => this.map.getViewPort().resize());
    if (mapEvent) this.filterListUsingMap();
    let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
    this.ui = H.ui.UI.createDefault(this.map, this.defaultLayer);
    this.map.addObject(this.drayageGroup);
    this.map.addObject(this.equipmentGroup);
    this.map.addObject(this.groupTruck);
  }

  /**
   * Update the truck position
   * @param lat Latitude
   * @param lng Longitude
   * @param driverId ID of driver
   * @auhtor 🔥
   */
  private reDraw({ lat, lng, driverId }: { lat: number, lng: number, driverId: string }): void {
    this.markers[driverId].setGeometry({ lat, lng });
    this.drawRouteStepByStep({ lat, lng })
  }

  /**
  * Add truck icon on map.
  * @param lat Latitude
  * @param lng Longitude
  * @param driverId ID of driver
  * @auhtor 🔥
  */
  private addMarkerDriver(icon, { lat, lng, driverId }: { lat: number, lng: number, driverId: string }): void {
    this.markers[driverId] = new H.map.DomMarker({ lat, lng }, { icon: icon });
    this.groupTruck.addObject(this.markers[driverId]);
    this.map.addObject(this.groupTruck);
  }

  /**
  * Add a marker on map. Depending on the type, the icon changes
  * @param lat Latitude 
  * @param lng Longitude
  * @param type Enum. 'Origin','Destination', 'Stops'
  * @auhtor 🔥
  */
  addMarker({ lat, lng }: { lat: number, lng: number }, type?: 'A' | 'B' | 'S' | any, colorM?): void {
    let color = type == 'A' ? '#7FC5FB' : type == 'B' ? '#cb2a36' : colorM;
    let x = new H.map.DomIcon(this.markersIcon['TypeLocation'](type, color));
    let marker = new H.map.DomMarker({ lat, lng }, { icon: x });
    marker.id = `${type}`;
    this.map.addObject(marker);
  }

  /**
   * Add a marker on map. Depending on the type, the icon changes. Only Drayage
   * @param latitude Latitude 
   * @param longitude Longitude
   * @param type Enum. 'Origin','Destination', 'Delivery'
   * @auhtor 🔥
   */
  addMarkerDrayage({ latitude, longitude }: { latitude: number, longitude: number }, type?: 'A' | 'S' | 'B'): void {
    let color = type == 'A' ? '#7FC5FB' : type == 'B' ? '#cb2a36' : 'orange';
    let x = new H.map.DomIcon(this.markersIcon['TypeLocation'](type, color));
    let marker = new H.map.DomMarker({ lat: latitude, lng: longitude }, { icon: x });
    marker.id = `${type}`;
    this.map.addObject(marker);
  }

  /**
   * Return a property with the coordinates
   * @param index Number
   * @param location Coordinates object of shipment
   * @author 🔥
   */
  private routeRequestParamsF = (index, location): Object => { return { [`waypoint${index}`]: `geo!${location.lat},${location.lng}` } };

  /**
   * Create a object to build a request with all coordinates
   * @param shipment Array of Coordinates 
   * @author 🔥
   */
  private constructRequest(shipment: Array<any>): Object {
    let routeRequestParams = {
      mode: 'fastest;truck;traffic:enabled;',
      representation: 'display',
      routeattributes: 'waypoints',
      'metricSystem': 'imperial'
    }
    shipment.map((v, i) => { Object.assign(routeRequestParams, this.routeRequestParamsF(i, v)); });
    return routeRequestParams;
  }

  /**
   *  Create a Array of coordinates (Drayage or FTL)
   * @param location Object Location of Drayge
   * @param ftl Object has {Shipper, Consignee, Stops} of Object FTL
   * @author 🔥
   */
  private mapParamsToBuildResquest(location?, ftl?: { shipper: Shipper, consignee: Consignee, stops: number }) {
    let shipment = []
    let delivery = [];
    if (ftl.shipper && ftl.consignee) {
      if (ftl.stops !== 0) {
        shipment.unshift({ lat: ftl.shipper.lat, lng: ftl.shipper.lng });
        ftl.shipper.stops.map(v => shipment.push({ lat: v.lat, lng: v.lng }));
        ftl.consignee.stops.map(v => shipment.push({ lat: v.lat, lng: v.lng }));
        shipment.push({ lat: ftl.consignee.lat, lng: ftl.consignee.lng });
        return this.constructRequest([...shipment])
      }
      else {
        shipment.unshift({ lat: ftl.shipper.lat, lng: ftl.shipper.lng });
        shipment.push({ lat: ftl.consignee.lat, lng: ftl.consignee.lng });
        return this.constructRequest([...shipment])
      }
    };
    if (location) {
      if (location.delivery.stops.length > 0) {
        delivery.unshift({ lat: location.pickup.latitude, lng: location.pickup.longitude });
        location.delivery.stops.map(v => delivery.push({ lat: v.latitude, lng: v.longitude }));
        delivery.push({ lat: location.return.latitude, lng: location.return.longitude });
        return this.constructRequest([...delivery])
      }
      else {
        delivery.unshift({ lat: location.pickup.latitude, lng: location.pickup.longitude });
        delivery.push({ lat: location.delivery.latitude, lng: location.delivery.longitude });
        if (location.return.latitude && location.return.longitude) {
          delivery.push({ lat: location.return.latitude, lng: location.return.longitude });
        }
        return this.constructRequest([...delivery])
      }
    }
  }

  /**
   * Send the object to draw route. Draw markers on Origin and Destination position
   * @param location Optional? Destructuring Location Property of Drayge Object. Please let a empty object as first parameter
   * @param { shipper , consignee, stops }  Optional? Destructuring FTL Object. If the load it's a Drayage. Please let a empty object as second parameter
   * @author 🔥
   */
  calculateRoute({ location }: { location?: LocationD } = {}, { shipper, consignee, stops }: { shipper?: Shipper, consignee?: Consignee, stops?: number } = {}): void {
    this.groupTruck = new H.map.Group();
    this.deleteSpecificObjectMap('Route');
    this.deleteSpecificObjectMap('Equipments');
    this.deleteSpecificObjectMap('Loads');
    let routeRequestParams = this.mapParamsToBuildResquest(location, { shipper, consignee, stops })
    if (shipper && consignee) { this.addMarker(shipper, 'A'); this.addMarker(consignee, 'B'); }// To add a Marker on Origin and Destination
    if (location) { this.addMarkerDrayage(location["pickup"], 'A'); this.addMarkerDrayage(location["return"], 'B'); } // To add a Marker on Pickup and Return
    this.router.calculateRoute(routeRequestParams, (result) => { this.drawALineUsingARouteResponse(result) }
    )
  }

  /**
   * Draw a Route on Map
   * @param response Response from endpoint CalculateRoute HereMaps (Only used inside this service)
   * @author 🔥
   */
  private drawALineUsingARouteResponse({ response }) {
    const line = new H.geo.LineString()
    const shape = response.route[0].shape;

    shape.map(v => { let points = v.split(','); line.pushLatLngAlt(points[0], points[1]); });
    let polyline = new H.map.Polyline(line, { style: { strokeColor: 'rgba(25, 150, 10, 0.7)', lineWidth: 4 } });
    polyline.id = 'Route'
    this.map.addObject(polyline);
    this.map.setCenter(polyline.getBoundingBox().getCenter());
    this.map.setZoom(5);
  }

  /**
   * Draw a line across the path the driver took 
   * @param _id The Load ID
   * @param time Object contain start and the end of the route (It be always be today)
   * @author 🔥
   */
  async drawLastPastRoute(_id: string, time = { start: 0, end: Date.now() }) {
    this.ms.hideOrShowLoading(true)
    let line = new H.geo.LineString();
    this.deleteSpecificObjectMap('Past Route');
    try {
      let route = await this.micro.RealTime.Tracking.getLocationHistory(_id, localStorage.getItem('token'), time);
      // if (route.length == 0) return swal('Information', 'There is no tracking information yet, this page will update once the driver starts moving to the next location. Please communicate with the driver and make sure he/she is running the dexFreight mobile app.', 'info');
      route.map(p => line.pushLatLngAlt(p[0], p[1]));
      let polyline = new H.map.Polyline(line, { style: { strokeColor: 'black', lineWidth: 2 } });
      polyline.id = 'Past Route'
      this.map.addObject(polyline);
    } catch (e) {
      console.log(e)
    } finally {
      this.ms.hideOrShowLoading(false)
    }
  }
  /**
   * Draw a line behind the driver
   * @param lat Coordinate
   * @param lng Coordinate
   * @author 🔥
   */
  private drawRouteStepByStep({ lat, lng }: { lat: number, lng: number }) {
    this.stepRoute.pushPoint({ lat, lng });
    this.stepRoute.pushPoint({ lat, lng });
    let polyline = new H.map.Polyline(this.stepRoute, { style: { lineWidth: 2 } });
    polyline.id = "StepRoute"
    this.map.addObject(polyline);
  }

  /**
   * Here we delete all objects on the map (Markers, Line, Shapes, Icons, etc...)
   * @author 🔥
   */
  deleteAllObjects() {
    this.map.removeObjects(this.map.getObjects());
  }

  /**
   * Delete a specific object using he ID
   * @param id Id of object on Map
   * @author 🔥
   */
  deleteSpecificObjectMap(id: 'Origin' | 'Destination' | 'Stops' | 'Delivery' | 'Route' |
    'Past Route' | 'Drivers' | 'StepRoute' | 'Loads' | 'Radius' | 'Equipments' | 'Drayages' | string): void {
    for (let type of this.map.getObjects()) {
      if (type.id === id) {
        this.map.removeObject(type);
      }
    }
  }

  get getAllIdOnArray(): string[] {
    let array: string[] = [];
    for (let type of this.map.getObjects()) {
      array.push(type.id)
    }
    return array;
  }

  private rotationIconMethod(location) {
    if (this.last_location) {
      const PI = 3.14159;
      const lat1 = location.lat * PI / 180;
      const lng1 = location.lng * PI / 180;
      const lat2 = this.last_location.lat * PI / 180;
      const lng2 = this.last_location.lng * PI / 180;
      const dLon = (lng2 - lng1);
      const y = Math.sin(dLon) * Math.cos(lat2);
      const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
        * Math.cos(lat2) * Math.cos(dLon);
      let brng = Math.atan2(y, x);
      brng = brng * 180 / PI;
      brng = (brng + 360) % 360;
      this.rotation = brng - 180;
    }
    if (this.rotation === -180) return;
    this.last_location = location;
  }

  /**
   * Create a layer with clustering of markers
   * @param load Array of Loads
   * @author 🔥
   */
  private clusteringLoads(load: any[]): any {
    let dataPoint = [];
    if (load['id'] == 'Drayage') {
      dataPoint.push(...load.map(ship => { return new H.clustering.DataPoint(parseFloat(<string>(ship.location.pickup.latitude)), parseFloat(<string>(ship.location.pickup.longitude)), 1, ship) }))
    } else {
      dataPoint.push(...load.map(ship => { return new H.clustering.DataPoint(parseFloat(<string>(ship.shipper.lat)), parseFloat(<string>(ship.shipper.lng)), 1, ship) }))
    }
    let defaultTheme = new H.clustering.Provider([]).getTheme();
    if (!this.clustering) {
      this.clustering = new H.clustering.Provider(dataPoint, {
        strategy: H.clustering.Provider.Strategy.DYNAMICGRID,
        clusteringOptions: {
          eps: 20,
          minWeight: 5
        },
        theme: {
          getClusterPresentation: function (cluster) {
            var clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);
            return clusterMarker;
          },
          getNoisePresentation: (noisePoint) => {
            //Mejorar este codigo
            if (load['id'] !== 'Drayage') {
              const { shipper: { lat, lng }, payment: { rate }, _id } = noisePoint.getData();
              let icon = new H.map.DomIcon(this.markersIcon["LoadsMarkers"](rate));
              let marker = new H.map.DomMarker({ lat, lng }, {
                icon: icon,
                min: noisePoint.getMinZoom()
              });
              marker.setData(noisePoint)
              marker.addEventListener('pointerenter', (e) => this.showMarkerOnMap(<HTMLElement>e.target.getIcon()['c'], 'black', 'white', _id, 'jumpShip'))
              marker.addEventListener('pointerleave', (e) => this.showMarkerOnMap(<HTMLElement>e.target.getIcon()['c'], 'white', 'black', _id, 'staticShip'))
              return marker;

            } else {
              const { location: { pickup: { latitude, longitude } }, _id } = noisePoint.getData();
              let icon = new H.map.DomIcon(this.markersIcon["LoadsMarkers"](null));
              let marker = new H.map.DomMarker({ lat: latitude, lng: longitude }, {
                icon: icon,
                min: noisePoint.getMinZoom()
              });
              marker.setData(noisePoint)
              marker.addEventListener('pointerenter', (e) => this.showMarkerOnMap(<HTMLElement>e.target.getIcon()['c'], 'black', 'white', _id, 'jumpShip'))
              marker.addEventListener('pointerleave', (e) => this.showMarkerOnMap(<HTMLElement>e.target.getIcon()['c'], 'white', 'black', _id, 'staticShip'))
              return marker;
            }
          }
        }
      });
      this.layerClus = new H.map.layer.ObjectLayer(this.clustering);
      this.map.addLayer(this.layerClus)
    } else {
      this.clustering.setDataPoints(dataPoint);
    }
    return this.clustering;
  }

  /**
   * Modify the marker on the map
   * @param e Reference HTMLElement
   * @param bckGr Background color
   * @param color Font Color
   * @param uniqueId UniqueID of Load
   * @param event Name event
   * @author 🔥
   */
  private showMarkerOnMap(e: HTMLElement, bckGr, color, uniqueId, event) {
    let opt = event == 'jumpShip';
    e.style.backgroundColor = bckGr;
    e.style.color = color;
    this.ms.listener.dispatchEvent(event, { uniqueId, type: opt ? 'add' : 'remove', opt });
    this.resetClustering()
  }
  /**
   * Delete the layer of clustering a put again.
   * @author 🔥
   */
  private resetClustering() {
    this.map.removeLayer(this.layerClus);
    this.map.addLayer(this.layerClus);
  }
  /** 
   * Draw a lot marker on the list map showing the shipments in the list.
   * @param load Shipment Objct (FTL)
   * @author 🔥
   */
  async drawLoadsFTLMap(load: any[]) {
    let cluster = this.clusteringLoads(load);
    cluster.addEventListener('tap', async (e) => {
      this.deleteSpecificObjectMap('Equipments');
      let { lat, lng } = e.target.getGeometry();
      let data = e.target.getData().getData();
      this.drawRadiusAroundShip(lat, lng);
      load['id'] !== "Drayage" ? this.drawEquipNearShip(data, await dexFreight.Shipments.getNearLocationShipment(500000, localStorage.token, data._id), 'Truck', '#298CCA', load['id']) : '';
      if (localStorage.role != "carrier") this.drawEquipNearShip(data, await this.micro.DexCore.Equipment.getNearLocation(lat, lng, 500000, localStorage.token), 'Equipment', '#298CCA', load['id']);
      if (localStorage.role != "carrier") this.drawEquipNearShip(data, await dexFreight.Companies.Settings.getNearLocationStates(lat, lng, 500000, localStorage.token), 'Carriers', 'yellow', load['id']);
      this.map.setCenter({ lat, lng });
      this.map.setZoom(5.89);
      this.ui.getBubbles().forEach(bub => this.ui.removeBubble(bub));
    })
  }

  /**
   * Draw a drayages on list map.
   * @param drayage Drayage Object
   * @author 🔥
   */
  drawLoadsDrayageMap(drayage) {
    const { location: { pickup: { longitude, latitude } }, payment: { rate } } = drayage;
    try {
      this.drayageGroup.id = "Drayages";
      this.drayageGroup.addEventListener('tap', (e) => {
        console.log(e)
      })
      let drayageIcon = new H.map.DomIcon(this.markersIcon["LoadsMarkers"](rate))
      let drayageMarker = new H.map.DomMarker({ lat: latitude, lng: longitude }, { icon: drayageIcon, data: drayage })
      this.drayageGroup.addObject(drayageMarker)
    } catch (e) {
      console.log(e)
    } finally { }
  }

  /**
   *  Draw a circle around the marker clicked to show the radius in we search carriers near.
   * @param lat number
   * @param lng number
   * @param radius Radius (Circle) around the center
   * @author 🔥
   */
  drawRadiusAroundShip(lat: number, lng: number, radius: number = 500000) {
    this.deleteSpecificObjectMap('Radius');
    let circle = new H.map.Circle({ lat, lng }, radius, {
      style: {
        fillColor: 'rgba(241, 241, 241, 0.4)',
      }
    });
    circle.id = "Radius";
    this.map.addObject(circle)
  }

  /**
   *  Draw a marker on the list map to show carrier near of the shipment clicked.
   * @param load Shipment Objct (Only FTL)
   * @param data Object to contain all information of equipment and the carrier company
   * @author 🔥
   */
  private async drawEquipNearShip(load, data, type: string, color: string, whereFrom) {
    var newLoad = {
      shipper: whereFrom === 'Drayage' ? load.location.pickup : load.shipper,
      payment: whereFrom === 'Drayage' ? load.payment.open : load.payment
    };
    let { shipper, payment } = newLoad;
    this.equipmentGroup.id = "Equipments";
    let temporalMarker = new H.map.DomMarker({ lat: shipper.lat || shipper.latitude, lng: shipper.lng || shipper.longitude }, { icon: new H.map.DomIcon(this.markersIcon["LoadsMarkers"](payment.rate || null)) });
    if (whereFrom === 'Drayage') load = await this.micro.getShipment(load._id, localStorage.getItem('token'));
    data.map((truck) => {
      this.carriers[truck.company && truck.company.admin] = new H.map.DomMarker({ lat: truck.lat || truck.consignee.lat, lng: truck.lng || truck.consignee.lng },
        { icon: new H.map.DomIcon(this.markersIcon["EquipMarker"](type, color)), data: { data: this.markersIcon["InfoWindow"]({ truck, eq: data.length }), _id: truck.company && truck.company.admin, load: truck } });
      this.equipmentGroup.addObject(this.carriers[truck.company && truck.company.admin]);
      this.map.addObject(this.equipmentGroup);
      this.map.removeLayer(this.layerClus);
    });
    this.equipmentGroup.addObject(temporalMarker)
    this.equipmentGroup.addEventListener('tap', async (point) => {
      const { lat, lng } = point.target.getGeometry();
      const { company: { details } } = point.target.getData().load;
      let x = await this.micro.getUser(point.target.getData()._id);
      point.target.data.data.childNodes[0].childNodes[1].childNodes[3].onclick = () => this.invite.emit(load);
      point.target.data.data.childNodes[0].childNodes[4].onclick = () => this.profile.emit(point.target.getData()._id);

      let bubble = new H.ui.InfoBubble({ lat, lng }, {
        content: point.target.getData().data
      });

      this.ui.getBubbles().forEach(bub => this.ui.removeBubble(bub));
      this.ui.addBubble(bubble);
      localStorage.setItem('emailToInvite', JSON.stringify({ email: x.email, dotNumber: details.dotNumber || '', mcNumber: details.mcNumber || '' }));
    }, false)
  }

  /**
   * Emit the name of city where Map zoomIn
   * @param items Response of GeoCode Service
   * @author 🔥
   */
  private filterByZoomIn({ items }): void {
    if (items[0].address.city) this.filter.next(items[0].address.city);
  }

  /**
   * Subscribe event zoomIn or zoomOut in Map
   * @author 🔥
   */
  private filterListUsingMap(): void {
    let zoomLvl: number = 4;

    this.map.addEventListener('mapviewchangeend', (e) => {
      if (zoomLvl !== this.map.getZoom() && this.map.getZoom() > 7) {
        zoomLvl = this.map.getZoom();
        let { lat, lng, alt } = e.target.getCenter();
        this.geoCoding.reverseGeocode({
          at: `${lat},${lng},${alt}` + ''
        }, (result) => { this.filterByZoomIn(result) })
      }
      if (this.map.getZoom() < 7 && localStorage.FTLFilters && JSON.parse(localStorage.FTLFilters).hasOwnProperty('origin')) {
        this.deleteFilter.emit(true);
        this.deleteSpecificObjectMap('Loads');
        this.deleteSpecificObjectMap('Radius');
        this.deleteSpecificObjectMap('Equipments');
      }
      if (this.map.getZoom() < 5.89 && this.getAllIdOnArray.includes('Radius')) {
        this.map.addLayer(this.layerClus);
        this.resetWhenViewChange();
      }
    })
  }

  /**
   * Iterate the param coords and draw a line on the map. Use this function to draw multiple lines
   * @param coords Array of Obj {lat, lng}
   * @author 🔥
   */
  addMultipleRoutes(coords, id, color) {
    const line = new H.geo.LineString();
    coords.map(x => {
      line.pushLatLngAlt(x.lat, x.lng)
    });
    let polyline = new H.map.Polyline(line, { style: { strokeColor: color, lineWidth: 3 } });
    polyline.id = id;
    this.map.addObject(polyline);
  }

  /**
   * Reset the map on list when the view change. Use this function in onDestroy cycle lifehook
   */
  resetWhenViewChange() {
    this.equipmentGroup = new H.map.Group();
    this.deleteSpecificObjectMap('Equipments');
    this.deleteSpecificObjectMap('Radius');
    this.resetMap()
  }

  /**
   * Reset the Map to default
   * @author 🔥
   */
  resetMap(): void {
    this.map.setCenter({ lat: 37.090, lng: -95.712 });
    this.map.setZoom(3.44);
    this.clustering = null
    this.carriers = {}
  }
}
