import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import dexFreight, { Microservices } from '../../microservices';

@Injectable({
    providedIn: 'root'
})
export class BrokerService {
    constructor(public micro: Microservices) { }

    /**
     * @description Get a list of brokers in the white list
     * @return Array Brokers Company
     * @author 🔥
     */
    async getBrokerWhiteList() {
        try {
            let broker = await dexFreight.BrokerList.getBrokersInfo(localStorage.token);
            broker = await Promise.all(broker.map(async broker => {
                let { groups } = await this.micro.DocumentHub.documents(broker._id);
                broker.docLength = groups.filter(document => document.docs.length > 0 && document.type !== 'rate_confirmation').length;
                return broker;
            }));
            return broker;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * @description Get a company an mutated to build a IWhiteList Obj
     * @param broker Company obj
     * @returns IWhiteList Obj
     * @author 🔥
     * @deprecated
     */
    buildObjasWhiteList(broker) {
        return {
            favorite: false,
            brokerId: broker._id,
            billToInfo: broker.billToInfo,
            identity: broker.identity,
            activeShipments: broker.stats && broker.stats.activeShipments || broker.activeShipments || 0,
            locked: false,
            notify: false,
            totalShipments: broker.stats && broker.stats.totalShipments || broker.totalShipments || 0,
        }
    }

    /**
     * @description Return an array with all broker that carrier has worked
     * @return IWhiteList array
     * @author 🔥
     * @deprecated
     */
    async getAllBrokerWorked() {
        try {
            let brokersList = await dexFreight.Shipments.getBrokerWorked(localStorage.token);
            brokersList = [...brokersList.map(broker => this.buildObjasWhiteList(broker))];
            return brokersList;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * @param IWhiteList Obj
     * @description Add a broker to white list
     * @returns IWhiteList with _id
     * @author 🔥
     * @deprecated
     */
    async addBrokerToFavorites(broker) {
        try {
            let brokerFav = await dexFreight.BrokerList.addFavoriteBroker(broker, localStorage.token);
            return brokerFav
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * @param {Object} broker
     * @description Check if that broker was added before to whitelist and use update if not add
     * @author 🔥
     * @returns IWhiteList updated
     */
    async createAndToggleBroker(broker) {
        try {
            if (broker.brokerlists.length > 0) {
                let obj = {
                    favorite: broker.brokerlists[0].favorite ? false : true,
                    brokerId: broker._id,
                    locked: false
                }
                let res = await dexFreight.BrokerList.updateFavoriteBroker(broker.brokerlists[0]._id, { ...broker.brokerlists[0], ...obj }, localStorage.token);
                return res
            } else {
                let obj = { favorite: true, brokerId: broker._id, locked: false }
                let res = await dexFreight.BrokerList.addFavoriteBroker(obj, localStorage.token);
                return res
            }
        } catch (e) {
            console.error(e)
        }
    }

    /**
     * @description Here are be all Validations need the broker before update or added to white list
     * @param broker IWhiteList obj
     * @param type ActionMutate type
     * @author 🔥
     * @deprecated
     */
    async validationBeforeUpdate(broker: IWhiteList, type: ActionMutate) {
        if (!broker['_id']) broker = await this.addBrokerToFavorites(broker);
        if (['Favorite'].includes(type) && broker.locked) return swal('Error', "You can't add a broker to favorite if it's blacklisted", 'warning');
        if (['BlackList'].includes(type) && broker.favorite) return swal('Error', "You can't add a Broker to Black List if it's whitelisted", 'warning');
        broker = {
            ...broker,
            favorite: type === 'Favorite' ? true : false,
            locked: type === 'BlackList' ? true : false,
        };
        await this.updateBroker(broker);
    }

    /**
     * @description Just save any modification on the next property (locked, favorite, notify)
     * @param broker IWhiteList Obj
     * @author 🔥
     */
    async updateBroker(broker) {
        try {
            await dexFreight.BrokerList.updateFavoriteBroker(broker._id, broker, localStorage.token);
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * @description Remove the broker of white list
     * @param _id IWhiteList _id
     * @return Boolean
     * @author 🔥
     */
    async removeBrokerFromFavorite(broker, action: 'Block' | 'Remove') {
        let res;
        try {
            if (action === 'Block') {
                if (broker.brokerlists.length > 0) {
                    res = await dexFreight.BrokerList.updateFavoriteBroker(broker.brokerlists[0]._id, { favorite: false, locked: true }, localStorage.token);
                } else {
                    res = await dexFreight.BrokerList.addFavoriteBroker({ brokerId: broker._id, favorite: false, locked: true }, localStorage.token);
                }
            }
            if (action === 'Remove') {
                res = await dexFreight.BrokerList.removeFavoriteBroker(broker.brokerlists[0]._id, localStorage.token);
            }
            console.log(res)
            return res;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * @description Request both broker's list and they join to make a single
     * @returns Array with all brokers that carrier has worked and with all broker whitelisted
     * @author 🔥
     */
    async buildBrokerList() {
        try {
            let brokerWhiteListed = await dexFreight.BrokerList.getBrokerList(localStorage.token);
            let brokersList = await dexFreight.Shipments.getBrokerWorked(localStorage.token);
            brokersList = [...new Set([
                ...brokersList.map(broker => this.buildObjasWhiteList(broker))
            ])].concat(<any>brokerWhiteListed);
            brokersList.sort(() => -1);
            return brokersList;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * get companies from fmcsa
     * @param skip pagination skip
     * @param limit pagination limit
     * @returns array with fmcsa companies
     */
    async getFMCSAList(skip: number, limit: number) {
        try {
            const fmcsa = await dexFreight.Integrations.FMCSA.getCensusList(
                localStorage.token, skip, limit
            );
            return [...fmcsa];
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * search company in FMCSA 
     * @param query search criteria
     * @returns an array with fmcsa companies 
     */
    async searchInFMCSA(query) {
        try {
            const fmcsa = await dexFreight.Integrations.FMCSA.searchCensus(
                query, localStorage.token, 0, 15
            )
            return [...fmcsa];
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    /**
     * @description Get a company id and return a boolean if exist in whiteList
     * @returns Boolean
     * @author 🔥
     */
    async carrierHasThisBrokerFav(brokerId) {
        try {
            let isFavorite = await dexFreight.BrokerList.existBroker(brokerId, localStorage.token);
            return isFavorite;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * get csv template to import brokers in broker list
     * @returns 
     */
    getCsvTemplate() {
        return dexFreight.Data.getFileCsvBroker(localStorage.getItem('token'));
    }

    /**
     * import brokers from csv file
     * @param csvFile 
     * @returns 
     */
    importBrokers(csvFile) {
        return dexFreight.BrokerList.importBroker(csvFile, localStorage.token)
    }
}

type ActionMutate = "Favorite" | "BlackList";

interface IWhiteList {
    favorite: boolean;
    brokerId: any;
    locked: boolean;
    totalShipments: number,
    notify: boolean;
    activeShipments: number;
}