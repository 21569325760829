import { Injectable } from '@angular/core';
import { CustomDate } from 'basic-date';
import { Subject } from 'rxjs';
import { toArray } from 'rxjs/operators';
import swal from 'sweetalert2';
import dexFreight, { Microservices } from '../../microservices';
import { Location } from '../../shipment/classes/location.class';
import { MainService } from '../main/main.service';
import { ConstantsService } from '../contants/constants.service';
import { PastDataService } from '../past-data/pastData.service';

declare let google;

@Injectable()
export class MapsService {
  locationMarker: any;
  location: Location;
  zipCode: any;
  latLng: {};
  lat: any;
  lng: any;
  city: any;
  state: any;
  map;
  equipments: any[] = [];
  drivers: any[] = [];
  equipBlue: any[] = [];
  markers: any = [];
  directionService: any;
  directionDisplay: any;
  textMarker;
  bounds;
  trackingLocation;
  lastLocation: any;
  geoCodeAddress = new google.maps.Geocoder();
  cityGeoCode: any;
  map2: any;
  last_location: any;
  rotation: any;
  infoWindowLocation: any;
  totalMiles: any;
  totalDuration: any;
  lineCoordinates: any[] = [];
  displayFromTrackingWithStops: any;
  driverMarker: {
    //url: '../../../assets/img/iconArrowTruck.svg',
    path: string; scale: number; fillColor: string; //<-- Car Color, you can change it
    fillOpacity: number; strokeWeight: number; rotation: any; anchor: any;
  };
  history: any;
  line: any;
  line2: any;
  totalDurationValue: any;
  arrayEquipments: { name: string; icon: string; accessorials: string[]; }[];
  equipmentLogo: string;
  markers2 = {};
  directionsDisplays = []; // this save all polylines created with the method "routeWithStops"
  directionsDisplaysKepped = []; // this save all polylines created with the method "routeWithStops"
  // private shipmentPolyline: any; @@Deprecated@
  polyline: any;
  previousLines: any;
  constructor(private cts: ConstantsService, private ms: MainService, private pastData: PastDataService, public micro: Microservices) {
    this.arrayEquipments = this.cts.getEquipments();
    this.ms.listener.addListener('bounceMyMarker@mapService', this.bounceMyMarker.bind(this));
    this.ms.listener.addListener('findMarker@mapService', this.findMarker.bind(this));
    this.ms.listener.addListener('activeMarkerDriver@mapService', this.activeMarkerDriver.bind(this));
    this.bounds = new google.maps.LatLngBounds();
    this.textMarker = null;
    this.directionService = new google.maps.DirectionsService;
    this.directionDisplay = new google.maps.DirectionsRenderer({ preserveViewport: true });
    this.lastLocation = new google.maps.Marker();
    this.driverMarker = {
      //url: '../../../assets/img/iconArrowTruck.svg',
      path: 'M229.16,365.88,118,424.86C98,435.48,76,414.43,85.77,394l146-306.11c13.27-27.81,35.05-27.84,48.41-.08L427.06,393c16.95,35.22,2.5,49.17-32.11,31l-111-58.29C268.83,357.85,244.2,357.9,229.16,365.88Z M254.66,195.6A71.34,71.34,0,1,0,326,266.94,71.34,71.34,0,0,0,254.66,195.6Z M201.59,289.13H216a.28.28,0,0,0,.29-.24,9.91,9.91,0,0,1,3-5.9.29.29,0,0,0-.2-.51H201.59A1.61,1.61,0,0,0,200,284.1v3.42A1.61,1.61,0,0,0,201.59,289.13Z M226.59,282.44a8.31,8.31,0,1,0,8.3,8.31A8.3,8.3,0,0,0,226.59,282.44Zm0,12.63a4.33,4.33,0,1,1,4.32-4.32A4.32,4.32,0,0,1,226.59,295.07Z M287.52,282.44a8.31,8.31,0,1,0,8.3,8.31A8.31,8.31,0,0,0,287.52,282.44Zm0,12.63a4.33,4.33,0,1,1,4.33-4.32A4.32,4.32,0,0,1,287.52,295.07Z M207.53,269.6h50a4.39,4.39,0,0,1,4.39,4.39v4.45a0,0,0,0,1,0,0H203.14a0,0,0,0,1,0,0V274A4.39,4.39,0,0,1,207.53,269.6Z M307.62,282.51c-.52,0-1.36-.42-1.36-.93V267.07a7.48,7.48,0,0,0-1.69-4.75l-16.06-19.68a2.48,2.48,0,0,0-2.1-1.16H269.66a1.84,1.84,0,0,0-1.84,1.84v38.15a1,1,0,0,1-1,1h-32a.37.37,0,0,0-.28.63,11.48,11.48,0,0,1,3,5.69.39.39,0,0,0,.38.33H276a.38.38,0,0,0,.37-.29c.35-1.57,2.45-8.95,11-8.95s10.38,7.33,10.67,8.93a.38.38,0,0,0,.37.31h8.79a1.67,1.67,0,0,0,1.67-1.67V283.8A1.29,1.29,0,0,0,307.62,282.51Zm-13.75-19.22H275.78a1.93,1.93,0,0,1-2-1.82V249.32a1.93,1.93,0,0,1,2-1.81h8a2.12,2.12,0,0,1,1.66.77l10.07,12.15A1.81,1.81,0,0,1,293.87,263.29Z',
      scale: 0.06,
      fillColor: '#427af4', //<-- Color, you can change it
      fillOpacity: 1,
      strokeWeight: 1,
      rotation: this.rotation,
      anchor: new google.maps.Point(250, 0),
    };
    this.locationMarker = {
      address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      lat: '',
      lng: ''
    };
  }

  ngOnInit() {
  }

  /**
   * @param {string} id DriverID
   * @param {string} mode Color to change
   * @description Change icon color
   * @author 🔥
   */
  async changeColor({ driverId, shipmentId }, color: string, timestamp) {
    let ship = await this.micro.getShipment(shipmentId);
    this.driverMarker.fillColor = color;
    this.markers2[driverId].setIcon(this.driverMarker);
    let infoDriver = await this.getDelayArrive(ship);

    this.setInfoDriverStatus(this.markers2[driverId], {
      active: infoDriver.isDelay ? '#ff0000' : '#a5ce58',
      color,
      mode: color !== '#a5ce58' ? 'Unactive' : 'Active',
      date: new CustomDate(timestamp).format(['D', 'M', 'YYYY'], '/') + ' ' + new Date(timestamp).getHours() + ':' + new Date(timestamp).getMinutes(),
      delay: infoDriver.isDelay,
      arriveTime: infoDriver.arriveTime,
      arriveMiles: infoDriver.arriveMiles,
      id: ship.uniqueId
    })
  }

  getDelayArrive(locations) {
    return new Promise<any>((done, reject) => {
      this.directionService.route({
        origin: this.markers2[locations._tracking.driverId].getPosition(),
        destination: (!locations.type ? locations.consignee.lat : locations.location.delivery.latitude) + ',' + (!locations.type ? locations.consignee.lng : locations.location.delivery.longitude),
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          const MI_TO_KM = 1.60934;
          const KM_TO_MI = 0.621371;


          let stages = [];

          for (let i = 0; i < 7; i++) {
            if (locations._tracking[`stage${i + 1}`].timestamp) {
              stages.push(locations._tracking[`stage${i + 1}`]);
            }
          }

          let duration = response.routes[0].legs[0].duration.value;
          let lastReportTime = new Date(stages[stages.length - 1].timestamp)
          let shouldArrive = new Date(locations.appointment.arrive.from.date);
          let arriveTime = response.routes[0].legs[0].duration.text;
          let arriveMiles = ['en'].includes(this.micro.getCurrentSetting.lang) ? response.routes[0].legs[0].distance.text : (response.routes[0].legs[0].distance.text.match(/[0-9]*/)[0] * MI_TO_KM).toFixed(3) + 'km';
          let timeDelay = lastReportTime.setHours(lastReportTime.getHours() + new Date(duration).getHours());
          let isDelay = timeDelay > shouldArrive.getTime();

          done({ isDelay, arriveTime, arriveMiles });
        }
      });
    });
  }

  /**
   * @param {Google Marker} driver
   * @param {Object} opts
   * @description Using the opts make a content and build info window to attached a driver.
   * @author 🔥
   */
  setInfoDriverStatus(driver: google.maps.Marker, opts) {
    const { lang } = this.micro.getCurrentSetting;
    let info = new google.maps.InfoWindow({
      content: lang === 'en' ?
        `<b>Driver is <span style="color: ${opts.color}">${opts.mode}</span></b>` +
        `<p class="mt-2"> ` +
        `<b>Last Location:</b> ${opts.date}` +
        `</br>` +
        `<b>Miles missing: ${opts.arriveMiles}</b>` +
        `</br>` +
        `<b>Estimated delivery time: ${opts.arriveTime}</b>` +
        `</br>` +
        `<b>Delayed: ${opts.delay ? 'Yes' : 'No'}</b>` +
        `</p>`
        :
        `<b>Conductor esta <span style="color: ${opts.color}">${opts.mode === 'Unactive' ? 'Inactivo' : 'Activo'}</span></b>` +
        `<p class="mt-2"> ` +
        `<b>Ultima vez activo:</b> ${opts.date}` +
        `</br>` +
        `<b>Kilometros faltantes: ${opts.arriveMiles}</b>` +
        `</br>` +
        `<b>Tiempo estimado de llegada: ${opts.arriveTime}</b>` +
        `</br>` +
        `<b>Entrega Atrasada: ${opts.delay ? 'Si' : 'No'}</b>` +
        `</p>`
    })
    let hasEvent = google.maps.event.hasListeners(driver, 'click');

    !hasEvent && driver.addListener('click', () => {
      info.map ? info.close() : info.open({ anchor: driver, map: this.map });
      info.map ? this.ms.listener.dispatchEvent('trackDriver@MapService', opts.id) : this.ms.listener.dispatchEvent('tracking@reset');
    });

    info.addListener('closeclick', () => {
      this.ms.listener.dispatchEvent('tracking@reset')
    })
  }
  /**
   * START DEMO FUNCTIONS DON'T TOUCH
  */
  activeDrivers() {
    for (let driver in this.markers2) {
      this.markers2[driver].setMap(this.map)
    }
  }
  drawRoute(locations) {
    return new Promise<void>((done, error) => {
      this.directionService.route({
        origin: (!locations.type ? locations.shipper.lat : locations.location.pickup.latitude) + ',' + (!locations.type ? locations.shipper.lng : locations.location.pickup.longitude),
        destination: (!locations.type ? locations.consignee.lat : locations.location.delivery.latitude) + ',' + (!locations.type ? locations.consignee.lng : locations.location.delivery.longitude),
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          this.directionDisplay.setMap(this.map)
          this.directionDisplay.setDirections(response);
          // this.map.setCenter(response.routes[0].bounds.getCenter());
          // this.map.setZoom(5)
          done();
        } else {
          error(status);
        }
      });
    });
  }
  deleteDrivers(id: string, on: boolean = true) {
    Object.keys(this.markers2).filter(key => on ? key !== id : key === id).map(driver => this.markers2[driver].setMap(null));
  }
  deleteRoute() {
    this.directionDisplay.setMap(null)
  }
  /**
    * END DEMO FUNCTIONS DON'T TOUCH
  */
  /**
   * Move marker and get address, city, zipcode and state
   */
  mapDraggable(idMap: string, address: string): any {
    const subject = new Subject<any>();
    this.latLng = { lat: 37.090, lng: -95.712 }
    let geocoder = new google.maps.Geocoder();


    if (address) {
      geocoder.geocode({ 'address': address }, (results: Array<any>, status: string) => {
        let data;
        if (results.length > 1) {
          results.forEach(item => {
            const format_address = address.split(',')[1].replace(' ', '');
            item.address_components.forEach(address_component => {
              const matchLong = new RegExp(`${address_component.long_name}`);
              const matchShort = new RegExp(`${address_component.short_name}`);
              if (matchLong.test(format_address) || matchShort.test(format_address)) {
                data = item;
                return;
              }
            });
          });
        } else if (results.length == 1) {
          data = results[0]
        }

        if (status === 'OK' && data) {
          let mapDraggable = new google.maps.Map(document.getElementById(idMap), {
            zoom: 8
          });
          mapDraggable.setCenter(data.geometry.location);
          let marker = new google.maps.Marker({
            map: mapDraggable,
            position: data.geometry.location,
            animation: google.maps.Animation.DROP,
            draggable: true,
          });
          this.lat = data.geometry.location.lat();
          this.lng = data.geometry.location.lng();
          this.locationMarker.address = data.formatted_address;
          this.setInfoLocationMarker(data.address_components, subject);

          google.maps.event.addListener(marker, 'dragend', () => {
            this.lat = marker.getPosition().lat();
            this.lng = marker.getPosition().lng();
            this.latLng = { lat: this.lat, lng: this.lng };
            this.locationMarker.address = data.formatted_address;
            this.geoCodeReverse(geocoder, subject);
          });
          subject.next(this.locationMarker);
        } else {
          swal('Error', this.micro.dictionary.ADDRESS_NOT_FOUND_PLEASE_USE_THE_MARKER_TO_SELECT_THE_LOCATION_IN_THE_MAP, 'question');
          return;
        }
      });
    } else {
      this.drawMap(idMap);
    }
    return subject.asObservable();
  }

  /**
   * Convert latitude and longitude in one address
   * @param subject
   * @param geoCode
   */
  geoCodeReverse(geoCode, subject?: any) {
    geoCode.geocode({ 'location': this.latLng }, (results) => {
      let data = results[0];
      this.locationMarker.address = data.formatted_address;
      this.setInfoLocationMarker(data.address_components, subject);
    });
  }

  getGeoCodeByAddress(address: string): Promise<any> {
    const GEOCODER = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      GEOCODER.geocode({ address: address }, (results, status) => {
        if (status !== 'OK') {
          reject(new Error('Could not get location.'));
          return;
        };

        const latitudeAndLongitude = {
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng()
        };

        resolve(latitudeAndLongitude);
      })
    });
  }

  getGeoCodeByZipcode(zipCode: string): Promise<any> {
    const GEOCODER = new google.maps.Geocoder();
    const request = {
      componentRestrictions: {
        postalCode: zipCode
      }
    }

    return new Promise((resolve, reject) => {
      GEOCODER.geocode(request, (results, status) => {
        if (status !== 'OK') {
          reject(new Error('Could not get location.'));
          return;
        };

        const latitudeAndLongitude = {
          latitude: results[0]?.geometry?.location?.lat(),
          longitude: results[0]?.geometry?.location?.lng()
        };

        resolve(latitudeAndLongitude);
      })
    });
  }

  /**
   * send information to the location marker
   * @param array
   */
  setInfoLocationMarker(array: any[], subject?: any) {
    let countries: string[] = ['US', 'MX', 'CA'];
    this.locationMarker.lat = this.lat;
    this.locationMarker.lng = this.lng;
    array.map((item) => {
      switch (item.types[0]) {
        case 'country':
          this.locationMarker.country = item.short_name;
          break;
        case 'administrative_area_level_1':
          this.locationMarker.state = item.short_name;
          break;
        case 'locality':
          this.locationMarker.city = item.short_name;
          break;
        case 'postal_code':
          this.locationMarker.zipCode = item.short_name;
          break;
        case 'street_number':
          this.locationMarker.address = item.short_name;
          break;
        case 'route':
          this.locationMarker.address += ' ' + item.short_name;
          break;
        default:
          this.locationMarker.zipCode = '';
      }
    });
    this.validateCountry(countries, subject);
  }

  /**
   * validate a country from an array
   * @param array
   * @param subject
   */
  validateCountry(array: any[], subject?) {
    let validate: boolean = false;
    array.map((country) => {
      if (this.locationMarker.country === country)
        validate = true;
    });

    if (validate) {
      subject.next(this.locationMarker);
    } else {
      this.formatLocationMarker();
      subject.next(this.locationMarker);
      swal('Error', `The country hasn't signal`, 'error');
    }
  }

  /**
   * set empty information to the location marker
   */
  formatLocationMarker() {
    this.locationMarker.address = '';
    this.locationMarker.country = '';
    this.locationMarker.state = '';
    this.locationMarker.city = '';
    this.locationMarker.zipCode = '';
    this.locationMarker.lat = '';
    this.locationMarker.lng = '';
  }

  drawMap2(map: HTMLElement, zoom = 4) {
    this.map2 = new google.maps.Map(map, {
      center: { lat: 37.090, lng: -95.712 },
      zoom: zoom,
      disableDefaultUI: true
    });
  }

  drawMap(idMap, zoom = 4) {
    return new Promise<void>((resolve, reject) => {
      this.textMarker = class TextMarker extends (google.maps.OverlayView as { new(marker): any; }) {
        marker: any;
        div: any;
        pos: any;
        text: any;
        infoWindow: any;

        constructor(marker) {
          super(marker);
          // super();
          this.marker = marker;
          this.div = null;
          this.pos = this.marker.position;
          this.text = this.marker.price;
          this.setMap(this.marker.map);
          // infowindow with the content of the equipment
          this.infoWindow = new google.maps.InfoWindow({
            content: 'alhjndjadna'
          });
        }

        onAdd() {
          this.div = document.createElement('div');
          this.div.classList.add('markerStyled');
          // this.div.style.position('absolute');
          this.div.innerHTML = this.text;
          this.getPanes().overlayImage.appendChild(this.div);
        }

        draw() {
          let position = this.getProjection().fromLatLngToDivPixel(this.pos);
          // let position = this.getProjection().fromLatLngToDivPixel(this.pos);
          this.div.style.left = position.x + 'px';
          this.div.style.top = position.y + 'px';
        }

        onRemove() {
          this.div.parentNode.removeChild(this.div);
        }

        active() {
          this.div && this.div.classList.add('is-active');
        }

        desactive() {
          this.div && this.div.classList.remove('is-active');
        }
      };
      this.map = new google.maps.Map(document.getElementById(idMap), {
        center: { lat: 29.749907, lng: -95.358421 }, zoom: zoom, disableDefaultUI: true
      });
      google.maps.event.addListener(this.map, 'zoom_changed', () => {
        let minFTZoomLevel = 6;
        let zoomLevel = this.map.getZoom();
        if (zoomLevel >= minFTZoomLevel) {
          this.setMapOnAll(this.map);
        } else {
          this.clearMarkers();
        }
      });
      resolve();
    });

  }

  deployMarker(idMap, lat, lng) {
    let somewhere = { lat: lat, lng: lng };
    this.map = new google.maps.Map(document.getElementById(idMap), {
      disableDefaultUI: true,
      center: somewhere, zoom: 4,
    });
    let marker = new google.maps.Marker({
      position: somewhere,
      map: this.map,
      animation: google.maps.Animation.BOUNCE,
    });
  }

  removeAllMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  /**
   * this add a text marker to map
   * @param load
   */
  drawSingleMarker(load) {
    if (this.textMarker) {
      const marker = new this.textMarker({
        position: new google.maps.LatLng(parseFloat(load.shipper.lat), parseFloat(load.shipper.lng)),
        map: this.map,
        price: load.openingRate.value ? '$ ' + load.openingRate.value : 'Negotiable',
        id: load._id
      });
      // this.bounds.extends(position);
      // mouse over event to animate the card in shipment list
      google.maps.event.addListener(marker, 'mouseover', () => {
      });
      // mouse over event to inactive the animation in  the card in shipment list
      google.maps.event.addListener(marker, 'mouseout', () => {
      });
      this.markers.push(marker);
    }
  }

  async drawMultipleMarkers(loads, idMap) {
    await this.drawMap2(idMap);
    // Loop through our array of buildings & place each one on the map
    loads.forEach((load) => {
      // Stretch our bounds to the newly found marker position
      const marker = new this.textMarker({
        position: new google.maps.LatLng(parseFloat(load.shipper.lat), parseFloat(load.shipper.lng)),
        map: this.map,
        price: load.openingRate.value ? '$ ' + load.openingRate.value : 'Negotiable',
        id: load._id
      });

      // this.bounds.extends(position);
      // mouse over event to animate the card in shipment list
      google.maps.event.addListener(marker, 'mouseover', () => {
      });
      // mouse over event to inactive the animation in  the card in shipment list
      google.maps.event.addListener(marker, 'mouseout', () => {
      });
      this.markers.push(marker);
    });
  }

  bounceMyMarker({ id }) {
    this.markers.forEach(marker => {
      if (marker.marker.id === id) {
        marker.active();
      } else if (id === '') {
        marker.desactive();
      }
    });
  }

  getRandomLocationsAround(lat, lng) {
    const n = Math.floor(Math.random() * 10);
    const distance = 1.2;
    lat = parseFloat(lat);
    lng = parseFloat(lng);

    for (let i = 0; i < n; i++)
      this.equipBlue.push({
        lat: lat + Math.random() * distance - distance / 2,
        lng: lng + Math.random() * distance - distance / 2
      });
    this.addMarker(this.equipBlue);
  }

  // find marker to zoom in and show the equipments availables
  findMarker({ id }) {
    this.markers.forEach(marker => {
      if (marker.marker.id === id) {
        this.getRandomLocationsAround(marker.pos.lat(), marker.pos.lng());
        this.map.setZoom(8);
        this.setMapOnAll(this.map);
        this.map.panTo(marker.marker.position);
      }
    });
  }

  addDriverToMap(driver) {
    if (driver.location) {
      const { lat, lng, _id } = driver.location;

      var marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        id: _id
      })
      // infowindow with the content of the equipment
      const infoWindow = new google.maps.InfoWindow({
        content: `<div style="background-color:#ffffff; padding: 0.5em;">${driver.profile.name} ${driver.profile.lastname}</div>`,
      });

      // event marker click to show  equipment information
      google.maps.event.addListener(marker, 'click', () => {
        // infoWindow.setContent(building.info);
        infoWindow.open(this.map, marker);
      });

      this.drivers.push(marker);
    }

  }

  activeMarkerDriver(id) {
    this.drivers.forEach(marker => {
      // TODO: no supe ponerle animaciones a los marcadores, ya esta la logica armada solo falta añadir animacion si coincide el id y quitarla si no coincide
      if (marker.id === id) {
        // marker.setAnimation(google.maps.Animation.BOUNCE);
      } else if (id === '') {
        // marker.desactive();
      }
    });
  }

  addSimpleMarker({ lat, lng },
    imageUrl = 'https://storage.googleapis.com/dexfreight-webapp-assets/dexImg/googleMaps/mappin.png') {
    const marker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      icon: {
        url: imageUrl
      },
    });

    this.markers.push(marker);
    return marker;
  }

  // Adds a marker to the map and push to the array.
  addMarker(equipments?) {
    const image = {
      url: '../../assets/icon/icons8-truck-26.png',
    };
    equipments.forEach(equipment => {
      var marker = new google.maps.Marker({
        position: { lat: equipment.lat, lng: equipment.lng },
        map: this.map,
        icon: image,
      });
      let contentString = '    <div class="infowindow">  ' +
        '       <div class="row header">  ' +
        '         <div class=" company col-6">  ' +
        '           <img src="https://storage.googleapis.com/dexfreight-production-webapp-assets/companies/5cacb3e80001bf1a35af7c33/logo.png?ignoreCache=22074744622476605" alt="">  ' +
        '         </div>  ' +
        '         <div class="col-4">  ' +
        '           <app-stars></app-stars>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '       <div class="row numberAvailables">  ' +
        '         <div class="col-2 icon">  ' +
        '           <span> <img src="../../../assets/img/shipmentListIcons/delivery-truck.svg" alt=""></span>  ' +
        '         </div>  ' +
        '         <div class="col">  ' +
        '           <span> <strong>3</strong> in this location </span>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '       <div class="row">  ' +
        '         <div class="col-2 icon">  ' +
        '           <span><i class="fa fa-history"></i></span>  ' +
        '         </div>  ' +
        '         <div class="col">  ' +
        '           <span> Available in <strong>4</strong> hours </span>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '       <div class="row">  ' +
        '         <div class="col-2 icon">  ' +
        '           <span> <i class="fa fa-arrow-circle-up"></i></span>  ' +
        '         </div>  ' +
        '         <div class="col">  ' +
        '           <span> <strong>NorthWest</strong> </span>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '       <div class="buttonInvite">  ' +
        '         <button class="btn dxf-btn-primary">Invite to shipment </button>  ' +
        '       </div>  ' +
        '       <div class="row">  ' +
        '         <div class="col icon">  ' +
        '           <span> <strong>Based At</strong> Miami FL </span>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '       <div class="row numberAvailables paddingAndBorder">  ' +
        '         <div class="col-2 icon">  ' +
        '           <span> <img src="../../../assets/img/shipmentListIcons/delivery-truck.svg" alt=""></span>  ' +
        '         </div>  ' +
        '         <div class="col">  ' +
        '           <span> <strong>207</strong></span>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '       <div class="row numberAvailables">  ' +
        '         <div class="col-4 icon">  ' +
        '           <span> <strong>DOT</strong> </span>  ' +
        '         </div>  ' +
        '         <div class="col">  ' +
        '           <span> <strong>MC#</strong></span>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '       <div class="row numberAvailables">  ' +
        '         <div class="col-4 icon">  ' +
        '           <span> 271834 </span>  ' +
        '         </div>  ' +
        '         <div class="col">  ' +
        '           <span> 017389168 </span>  ' +
        '         </div>  ' +
        '       </div>  ' +
        '    </div>  ';

      // infowindow with the content of the equipment
      const infoWindow = new google.maps.InfoWindow({
        content: contentString
      });

      // event marker click to show  equipment information
      google.maps.event.addListener(marker, 'click', () => {
        // infoWindow.setContent(building.info);
        infoWindow.open(this.map, marker);
      });
      this.equipments.push(marker);
    });
  }

  async zoomMarker({ id, marker }) {
    if (marker.id === { id } as any)
      await this.map.panTo(parseInt(id.shipper.lat), parseInt(id.shipper.lng));
  }

  // Sets the map on all markers in the array.
  setMapOnAll(map) {
    for (var i = 0; i < this.equipments.length; i++) {
      this.equipments[i].setMap(map);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  clearMarkers() {
    this.setMapOnAll(null);
  }

  // Shows any markers currently in the array.
  showMarkers() {
    this.setMapOnAll(this.map);
  }

  //Draw a route for point A to point B
  drawARoute(location) {
    return new Promise<void>((done, reject) => {
      this.directionDisplay.setMap(this.map);
      this.directionService.route({
        origin: location.shipper.lat + ',' + location.shipper.lng,
        destination: location.consignee.lat + ',' + location.consignee.lng,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          this.directionDisplay.setDirections(response);
          this.totalDurationValue = response.routes[0].legs[0].duration.value;
          this.map?.fitBounds(response.routes[0].bounds);
          this.map?.setZoom(5);
          //this.map.setZoom(response.routes[0].bounds.)

          done();
        } else {
          // window.alert('Directions request failed due to ' + status);
          console.log('Directions not found');
        }
      });
    });

  }

  //Animation for the tracking simulation
  driveSim(response) {
    var path = response.routes[0].overview_path;
    var maxIter = path.length;
    let taxiCab = new google.maps.Marker({
      position: path[0],
      map: this.map,
      icon: '../../assets/icon/icons8-truck-26.png'
    });

    var count = 0;
    var interval = setInterval(() => {
      const p = path[count++];

      if (p === undefined)
        return clearInterval(interval);

      taxiCab.setPosition({ lat: p.lat(), lng: p.lng() });
    }, 100);
  }

  autoComplete(idInput, idMap) {
    const options = {
      types: ['(regions)'],
      componentRestrictions: { 'country': ['US', 'MX'] }
    };
    let origen = new google.maps.places.Autocomplete((document.getElementById(idInput)), options);
    origen.addListener('place_changed', () => {
      const place = origen.getPlace();
      this.zipCode = undefined;
      this.city = undefined;
      this.cityGeoCode = undefined;
      this.state = undefined;
      if (place.types[0] === 'postal_code') {
        this.zipCode = place.name;
        this.city = place.address_components[1].long_name;
      } else if (place.types[0] === 'locality') {
        this.cityGeoCode = place.name;
      } else if (place.types[0] === 'administrative_area_level_1') {
        place.address_components.forEach(component => {
          if (component.types.includes("administrative_area_level_1")) {
            this.state = component.short_name;
          }
        })
      }
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
      if (this.lat) {
        this.deployMarker(idMap, this.lat, this.lng);
      }
      document.getElementById(idInput).focus();
      document.getElementById(idInput).blur();
    });
  };

  retrZip() {
    return this.location.zipCode;
  }

  //Draw a route with waypoints // Test for frank
  /**
   *
   * @param route Its a object with the lat and lng from origin and destination
   * @param waypts The array with the direction waypoints. Can be String address, LatLng or Place
   */
  drawARouteWithWaypoint(route: any, zoom: number, waypts?: any) {
    return new Promise<void>((done, error) => {
      let { origin, destination } = route;
      let waypoints = [];
      for (var i = 0; i < waypts.length; i++) {
        waypoints.push({
          location: waypts[i],
          stopover: false
        });
      }
      this.directionDisplay.setMap(this.map);
      this.directionService.route({
        origin: origin.latitude + ',' + origin.longitude,
        destination: destination.latitude + ',' + destination.longitude,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: 'DRIVING',
      }, (response, status) => {
        if (status === 'OK') {
          this.map.setCenter(response.routes[0].bounds.getCenter());
          this.map.setZoom(5)
          this.totalMiles = response.routes[0].legs[0].distance.value;
          this.totalDuration = response.routes[0].legs[0].duration.text;
          this.directionDisplay.setDirections(response);
        } else {
          error(status);
        }
        done();
      });
    });
  }

  /**
   * draw a polyline from waypoints
   */
  drawARouteWithWaypoints(waypoints: any[]) {
    if (this.polyline) {
      this.polyline.setMap(null)
    }

    const route = waypoints
      .map(waypoint => ({ lat: waypoint.position.latitude, lng: waypoint.position.longitude }));
    this.polyline = new google.maps.Polyline({
      path: route,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    this.polyline.setMap(this.map);
    this.zoomToObject(this.polyline);
  }

  /**
   * center map around a map object
   */
  zoomToObject(mapObject) {
    const bounds = new google.maps.LatLngBounds();
    const points = mapObject.getPath().getArray();
    for (var n = 0; n < points.length; n++) {
      bounds.extend(points[n]);
    }
    this.map?.fitBounds(bounds);
  }


  //Tracking Methods

  rotationIconMethod(location) {
    if (this.last_location) {
      const PI = 3.14159;
      const lat1 = location.lat * PI / 180;
      const lng1 = location.lng * PI / 180;
      const lat2 = this.last_location.lat * PI / 180;
      const lng2 = this.last_location.lng * PI / 180;
      const dLon = (lng2 - lng1);
      const y = Math.sin(dLon) * Math.cos(lat2);
      const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
        * Math.cos(lat2) * Math.cos(dLon);
      let brng = Math.atan2(y, x);
      brng = brng * 180 / PI;
      brng = (brng + 360) % 360;
      this.rotation = brng - 180;
    }
    if (this.rotation === -180) return;
    this.last_location = location;
    this.driverMarker.rotation = this.rotation;
  }

  //@@DEPRECATED@@
  // async addMarkerTruck(data) {
  //   console.log(data)
  //   if (data) {
  //     this.addTruckTracking(data)
  //     let { lat, lng } = data;
  //     //this.lastLocation[data.driverId] = new google.maps.Marker();
  //     // let position = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
  //     // this.lastLocation.setPosition(position);
  //     // this.lastLocation.setIcon(this.driverMarker);
  //     // this.lastLocation.setMap(this.map);
  //     // // this.lastLocation = new google.maps.Marker({
  //     // //   map: this.map,
  //     // //   position: new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
  //     // //   icon: this.driverMarker
  //     // // });
  //     let shipment: Shipment = await this.micro.DexCore.Shipment.get(data.shipmentId);
  //     console.log(shipment)
  //     // let driver = await this.micro.getUser(data.driverId, localStorage.getItem('token'));
  //     // this.equipmentLogo = this.arrayEquipments.find(({ name }) => name.includes(shipment.loadInfo.equipmentType[0])).icon;
  //     // // const pt = new google.maps.LatLng(data.lat, data.lng);
  //     // // this.bounds.extend(pt);
  //     // const userPictureProfile = driver.profile.picture || '../../assets/icon/user.png';
  //     // let content = //InfoWindow with a css in line.
  //     //   '<div style="padding:8px;">' +
  //     //   '<div style="display:grid; grid-template-rows:1fr 1fr; grid-row-gap:15px;">' +
  //     //   '<div style="display:grid; grid-template-columns:1fr 2fr;">' +
  //     //   '<div style="justify-self:center;">' +
  //     //   '<img class="rounded-circle" src="' + userPictureProfile + '" height="45" width="45">' +
  //     //   '</div>' +
  //     //   '<div style="display:grid; grid-template-rows:1fr 1fr; justify-items:center; align-items:center;">' +
  //     //   '<div style="font-weight:600;">' +
  //     //   driver.profile.name + ' ' + driver.profile.lastname +
  //     //   '</div>' +
  //     //   '<div>' +
  //     //   'adsdadasd' + ' ' + '<a href="#/app/chatting" style="color:#CB2A36;"><i class="fa fa-comments"></i></a>' +
  //     //   '</div>' +
  //     //   '</div>' +
  //     //   '</div>' +
  //     //   '<div style="display:grid; grid-template-columns:1fr 2fr;">' +
  //     //   '<div>' +
  //     //   '<img src="../../assets/icon/' + this.equipmentLogo + '" height="55" width="60">' +
  //     //   '</div>' +
  //     //   '<div style="display:grid; grid-template-rows:1fr 1fr; justify-items:center; align-items:center;">' +
  //     //   '<div style="font-weight:600;">Kenwort T680</div>' +
  //     //   '<div>' +
  //     //   shipment.loadInfo.equipmentType[0];
  //     // '</div>' +
  //     //   '</div>' +
  //     //   '</div>' +
  //     //   '</div>' +
  //     //   '</div>';
  //     // this.infoWindowLocation = new google.maps.InfoWindow({
  //     //   content: content,
  //     //   maxWidth: 350
  //     // });
  //     // this.lastLocation.addListener('click', () => {
  //     //   this.infoWindowLocation.open(this.map, this.lastLocation);
  //     // });
  //     // this.lastLocation.addListener('click', () => {
  //     //   this.ms.listener.dispatchEvent('trackWithAClick@Tracking', shipment.uniqueId);
  //     //   this.map.setZoom(15);
  //     // });
  //     // this.lastLocation.addListener('dblclick', () => {
  //     //   this.infoWindowLocation.close();
  //     // });
  //     //this.rotationIconMethod(data);
  //   }
  // }


  /**
   *
   * @param data Data from realtime, lat and lng.  This function draw a route on the road where the driver past ago
   */
  async drawAPastRoute(shipmentId, type: 'FTL' | 'Drayage', time: { start: number, end: number, src: string } = { start: 0, end: Date.now(), src: 'dexfreight' }) {
    try {
      this.history = type == 'FTL'
        ? await this.micro.RealTime.Tracking.getLocationHistory(shipmentId, localStorage.token, { ...time })
        : await this.micro.RealTime.Tracking.getDrayageLocationHistory(shipmentId, localStorage.token, { ...time });

      this.previousLines = [];
      this.findDownTracking(this.history, time.src);
    } catch (e) {
      console.log(e)
    }
  }
  /**
   * @description Map the array of positions history to calculate the distance between the previous position and the next position
   * and if the distance it's the biggest 10KM we draw a new line.
   */
  findDownTracking(positions: Array<any>, type: string) {
    let lines = [];
    let coordinates = [];

    if (positions?.length > 0) {
      positions.reduce((prevPosition, newPosition, currentIndex) => {
        let x = this.getDistanceFromLatLonInKm({ prev: positions[currentIndex - 1], new: newPosition });
        if (x > 10) {
          lines.push(coordinates)
          coordinates = [];
        }
        coordinates.push(new google.maps.LatLng(parseFloat(newPosition[0]), parseFloat(newPosition[1])));
      });

    }

    let path = lines.length > 0 ? lines : [coordinates]
    for (const line of path) {
      const newLine = new google.maps.Polyline({
        path: line,
        strokeOpacity: 1,
        strokeColor: type !== 'keeptruckin' ? '#cb2a36' : '#a5ce58',
        strokeWeight: 2
      });
      newLine.setMap(this.map);
      this.previousLines.push(newLine);
    };
  }

  getDistanceFromLatLonInKm(positions) {
    if (!positions.prev) return;
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(positions.new[0] - positions.prev[0]);  // deg2rad below
    var dLon = this.deg2rad(positions.new[1] - positions.prev[1]);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(dLat) * Math.cos(dLon) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
  /**
   * @description Map array of previus routes and clean it
   */
  deleteSourceRoute() {
    try {
      (<Array<google.maps.Polyline>>this.previousLines).map(lines => lines.setMap(null));
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * @description Draw a marker where driver stop emit position.
   * @todo Add icon
   */
  // drawADisconnectionMarker(position){
  //   let marker:google.maps.Marker = new google.maps.Marker({
  //     position: position[0],
  //     map: this.map
  //   })
  // }
  /**
   * this draw past route in tracking super admin
   * @param shipmentId
   * @param time
   */
  async drawAdminPastRoute(shipmentId, time = { start: 0, end: Date.now() }) {
    (await this.micro.DexManager.Shipment.getTrackingLocationHistory(shipmentId, time, localStorage.token)).pipe(
      toArray()
    ).subscribe(history => {
      for (let loc of history) {
        this.lineCoordinates.push(new google.maps.LatLng(parseFloat(loc.lat), parseFloat(loc.lng)));
      }

      this.line = new google.maps.Polyline({
        path: this.lineCoordinates,
        strokeOpacity: 1,
        geodesic: true,
        strokeColor: '#00000090',
        strokeWeight: 2
      });
      this.line.setMap(this.map);
    });
  }

  /**
   *
   * @param data Lat and Lng in realtime. This function is trigger when subscribe to tracking realtime
   * and set a new position at Marker driver and draw a route on him steps.
   */

  redraw({ lat, lng, driverId }) {
    this.lineCoordinates.push(new google.maps.LatLng(parseFloat(lat), parseFloat(lng)));
    // let lineSymbol = {
    //   path: 'M 0,-1 0,1',
    //   strokeOpacity: 1,
    //   scale: 4
    // }
    this.line2 = new google.maps.Polyline({
      path: this.lineCoordinates,
      strokeOpacity: 1,
      geodesic: true,
      strokeColor: '#00000090',
      strokeWeight: 2
    });
    this.line2.setMap(this.map);
    const newPosition = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    this.rotationIconMethod({ lat, lng });
    this.markers2[driverId].setPosition(newPosition);
    this.markers2[driverId].setIcon(this.driverMarker);
    this.markers2[driverId].setMap(this.map);
    // this.map.panTo(newPosition);
  }

  /**
   * Subscribes
   *
   */

  lastLocationSubscribe() {
    this.pastData.lastLocation$.subscribe(data => {
      if (data && data.length === 0) return;
      if (data && data.length >= 1) data.map(location => this.addTruckTracking(location));
      if (data && !data.length) this.addTruckTracking(data);
    });
    this.pastData.tracking$.subscribe(data => {
      this.redraw(data);
    });
  }

  /**
   * Clear the map of all routes and set a general zoom and center;
   */

  goBackToTheNormalMap() {
    this.map.setCenter({ lat: 29.749907, lng: -95.358421 });
    this.map.setZoom(4);
    this.displayFromTrackingWithStops.setMap(null);
    this.directionDisplay.setMap(null);
    for (let key in this.markers2) {
      this.markers2[key].setMap(null)
    }
    // this.polyline.setMap(null)
    if (!this.line || !this.line2) return;
    this.line.setMap(null);
    this.line2.setMap(null);
  }

  /**
   * Remove all polylines created with the method "routeWithStops"
   */
  removeAllPolylines() {
    this.directionsDisplays.forEach(directionDisplay => {
      directionDisplay.setMap(null);
    })
    this.directionsDisplaysKepped = [];
  }

  changeColorToKeepedPolyline(color, index) {
    const display = this.directionsDisplaysKepped[index];
    display.setMap(null)
    const polylineOptions = new google.maps.Polyline({ strokeColor: color, strokeWeight: 6 });
    display.setOptions({ polylineOptions });
    display.setMap(this.map);
    this.directionsDisplays.push(display)
  }

  //Draw a route with the stops in the tracking
  routeWithStops(locations, color = '#0088FF', keepRoutes = false, suppressPolylineMarkers = false, isTourPlanning = false) {
    return new Promise<void>((done, error) => {
      const directionsServiceT = new google.maps.DirectionsService;
      const polylineOptions = new google.maps.Polyline({ strokeColor: color, strokeWeight: 6, strokeOpacity: 1 });
      const directionsDisplayT: google.maps.DirectionsRenderer = new google.maps.DirectionsRenderer({
        preserveViewport: true,
        map: this.map,
        suppressMarkers: suppressPolylineMarkers
      });

      isTourPlanning && directionsDisplayT.setOptions({ polylineOptions });

      this.directionsDisplays.push(directionsDisplayT);
      if (keepRoutes) {
        this.directionsDisplaysKepped.push(directionsDisplayT)
      }
      let waypoints = []
      let waypts = [];
      waypoints = !locations.type ? [...locations.shipper.stops, ...locations.consignee.stops] : [...locations.location.delivery.stops];
      for (let i = 0; i < waypoints.length; i++) {
        let latLng = !locations.type ? [waypoints[i].lat, waypoints[i].lng] : [waypoints[i].latitude, waypoints[i].longitude];
        waypts.push({
          location: new google.maps.LatLng(latLng[0], latLng[1]),
        });
      }
      directionsServiceT.route({
        origin: (!locations.type ? locations.shipper.lat : locations.location.pickup.latitude) + ',' + (!locations.type ? locations.shipper.lng : locations.location.pickup.longitude),
        destination: (!locations.type ? locations.consignee.lat : locations.location.delivery.latitude) + ',' + (!locations.type ? locations.consignee.lng : locations.location.delivery.longitude),
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: 'DRIVING'
      }, (response, status) => {
        if (status === 'OK') {
          directionsDisplayT.setDirections(response);
          this.map.setCenter(response.routes[0].bounds.getCenter());
          this.map.setZoom(5)
          this.totalDuration = response.routes[0].legs[0].duration.text;
          this.totalDurationValue = response.routes[0].legs[0].duration.value;
          this.displayFromTrackingWithStops = directionsDisplayT;
          done();
        } else {
          error(status);
        }
      });
    });
  }

  /**
   * this is to center map with markers
   * @param lat
   * @param lng
   * @param map
   * @param zoom
   */
  /**
   * Convierte grados a radianes.
     *
   *
     *
   *
     *
   * @param degrees Number of degrees.
   */
  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  /**
  * Devuelve la distancia entre 2 puntos de coordenadas en Google Maps
  *
  *
  *
  *
  *
  *
  *
  * @see https://stackoverflow.com/a/1502821/4241030
  * @param lat1 Latitude of the point A
  * @param lng1 Longitude of the point A
  * @param lat2 Latitude of the point B
  * @param lng2 Longitude of the point B
  */
  getDistanceBetweenPoints(origin, destination) {
    return new Promise<void>((resolve, reject) => {
      let directionsService = new google.maps.DirectionsService();
      let directionsRenderer = new google.maps.DirectionsRenderer();
      // Create route from existing points used for markers
      const route = {
        origin: origin,
        destination: destination,
        travelMode: 'DRIVING'
      }

      directionsService.route(route,
        function (response, status) { // anonymous function to capture directions
          if (status !== 'OK') {
            return;
          } else {
            directionsRenderer.setDirections(response); // Add route to the map
            let directionsData = response.routes[0].legs[0]; // Get data about the mapped route
            if (!directionsData) {
              console.log('Directions not found');
            }
            else {
              resolve(directionsData);
            }
          }
        });
    })
  }
  zoomOut(data) {
    this.map.setZoom(data);
  }

  deleteRouteAgo() {
    if (this.line) {
      this.line.setMap(null);
    }
  }

  /**
   * TODO: MEJORAR AÑADIENDO LAS HORAS.
   * @param actualTime timestamp from the stages of shipment
   * @param futureTime time from shipment info
   * @param location this is neccesary if you want call the function out of tracking
   */
  async knowIfADriverGoingDelay(actualTime, futureTime, location?) {
    if (actualTime === undefined) return;
    if (location) await this.drawARoute(location);
    let q = new Date(futureTime);
    let u = new Date(this.totalDurationValue).getHours();
    let x = new Date(actualTime);
    let y = x.setHours(x.getHours() + u);
    let z = new Date(y);
    if (z > q) {
      let delay = true;
      return delay;
    } else {
      let onTime = false;
      return onTime;
    }
  }

  // New methods
  async addTruckTracking({ lat, lng, driverId, shipmentId, drayageId }) {
    this.ms.hideOrShowLoading(true);
    try {
      let position = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
      if (!this.markers2[driverId]) {
        this.markers2[driverId] = new google.maps.Marker({
          position,
          map: this.map,
          icon: this.driverMarker,
        });
        //PENDING TRACK USING CLICK
        // let info = await this.addInfoWindow(shipmentId || drayageId, driverId);
        // this.markers2[driverId].addListener('click', () => {
        //   info.open(this.map, this.markers2[driverId]);
        // });
      } else {
        this.markers2[driverId].setPosition(position);
        this.markers2[driverId].setIcon(this.driverMarker);
        this.markers2[driverId].setMap(this.map);
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.ms.hideOrShowLoading(false)
    }
  }

  async addInfoWindow(shipmentId, driverId) {
    try {
      let shipment = await this.micro.DexCore.Shipment.get(shipmentId);
      let driver = await this.micro.getUser(driverId, localStorage.getItem('token'));
      this.equipmentLogo = shipment.loadInfo ? this.arrayEquipments.find(({ name }) => name.includes(shipment.loadInfo.equipmentType[0])).icon : '';
      const userPictureProfile = driver.profile.picture || '../../assets/icon/user.png';
      return this.infoWindowLocation = new google.maps.InfoWindow({
        content:
          `<div class="flex flex_col" style="width:13em;">` +
          '<div class="flex">' +
          '<div>' +
          '<img class="rounded-circle" src = "' + userPictureProfile + '" height="45" width="45" > ' +
          '</div>' +
          '<div class="flex flex_col ml-4 mb-3">' +
          driver.profile.name +
          '<div>' +
          '<small>' + 'Go to the chat ' + '<a href="#/app/chatting" style="color:#CB2A36;"><i class="fa fa-comments"></i></a>' + '</small>' +
          '</div>' +
          '</div>' +
          '</div>' +
          '<div>' +
          '<div>' +
          '<img src="../../assets/icon/' + this.equipmentLogo + '" height="55" width="60">' +
          '<b>' + (!shipment.isDrayage ? shipment.loadInfo.equipmentType[0] : shipment.containers[0].equipment) + '</b>' +
          '</div>' +
          '</div>' +
          '</div>',
        maxWidth: 500,

      });
    } catch (e) {
      console.log(e)
    }
  }

  deleteMarkersOnMap(): void {
    for (let key in this.markers2) {
      this.markers2[key].setMap(null);
    }
  }

  /** Return a Object with Lat and Lng */
  latAndLngR({ lat, lng, latitude, longitude }): any { return !lat && !lng ? { latitude: parseFloat(latitude), longitude: parseFloat(longitude) } : { lat: parseFloat(lat), lng: parseFloat(lng) } };
  /** Map the stops array and return it */
  latAndLngStops(stops: any[] = []): any[] { let waypoints = []; stops.map((way) => { waypoints.push(way) }); return waypoints };
  /** Map the details of shipments and return an array with the hazmat classes */
  hazmatClass(details: any[] = [], type: "FTL" | "Drayage"): any[] { let arr = []; type == 'FTL' ? details.map(({ hazmatDetails }) => { arr.push(hazmatDetails.class) }) : details.map(({ hazardousMaterial }) => arr.push(hazardousMaterial.class)); return arr }

  /**
   *
   * @param load Shipment Object
   * HAY QUE MEJORARLO
   */
  async routeUsingHereMaps(load) {
    let token = localStorage.token;
    this.ms.hideOrShowLoading(true);
    try {
      if (!load.type) {
        let { consignee, shipper, loadDetails } = load;
        let origin = this.latAndLngR(shipper);
        let stops = this.latAndLngStops([...consignee.stops, ...shipper.stops]);
        let destination = this.latAndLngR(consignee);
        let hazmat = this.hazmatClass(loadDetails, 'FTL');
        this.drawARouteWithWaypoints(await (await dexFreight.Integrations.Heremaps.calculateRoute(origin, stops, destination, token, hazmat)).leg[0].maneuver)
      } else {
        let { delivery, pickup, containers } = load.location
        let origin = this.latAndLngR(pickup);
        let stops = this.latAndLngStops(delivery.stops);
        let destination = this.latAndLngR(load.location.return)
        let hazmat = this.hazmatClass(containers, 'Drayage');
        this.drawARouteWithWaypoints(await (await dexFreight.Integrations.Heremaps.calculateRoute(origin, stops, destination, token, hazmat)).leg[0].maneuver);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.ms.hideOrShowLoading(false);
    }
  }


  //// 🄽🄴🅆 🅁🄾🅄🅃🄴🅂

  //Draw a route for point A to point B from array
  drawRoutes(locations: { latitude: number, longitude: number }[]) {
    const directionsService = new google.maps.DirectionsService;
    const polylineOptions = new google.maps.Polyline({ strokeColor: '#0088FF', strokeWeight: 0, strokeOpacity: 0 });

    const directionsDisplayT = new google.maps.DirectionsRenderer({
      preserveViewport: true,
      map: this.map,
      polylineOptions
    });

    const originLocation = locations[0];
    const destinationLocation = locations[locations.length - 1];
    let returnLocation;

    const request: any = {
      origin: new google.maps.LatLng(originLocation.latitude, originLocation.longitude),
      destination: new google.maps.LatLng(destinationLocation.latitude, destinationLocation.longitude),
      travelMode: 'DRIVING'
    }

    if (locations.length === 3) {
      returnLocation = locations[1];
      request.waypoints = [{
        location: new google.maps.LatLng(returnLocation.latitude, returnLocation.longitude),
        stopover: false
      }];
    }

    directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        this.polyline = new google.maps.Polyline(
          {
            strokeColor: '#0088FF',
            strokeWeight: 6,
            strokeOpacity: 1,
            path: response.routes[0].overview_path
          }
        );
        this.polyline.setMap(this.map);
        directionsDisplayT.setDirections(response);
        this.map?.fitBounds(response.routes[0].bounds);
        this.map?.setZoom(5);
      } else {
        console.log('Directions not found');
      }
    });

  }

  getCityByCoords({ latitude, longitude }) {
    try {
      let geocoder = new google.maps.Geocoder();
      return geocoder.geocode({ location: { lat: latitude, lng: longitude } }).then(({ results }) => results[0]);
    } catch (e) {
      console.error(e)
    }
  }

  drawPolylineByWaypoints(waypoints: { lat: number, lng: number }[], zoomToObject = true) {
    const path = waypoints.map(({ lat, lng }) => new google.maps.LatLng(lat, lng))

    this.line = new google.maps.Polyline({
      path,
      strokeOpacity: 1,
      geodesic: true,
      strokeColor: '#0088FF',
      strokeWeight: 6
    });
    this.line.setMap(this.map);
    if (zoomToObject) {
      this.zoomToObject(this.line);
    }
  }
}
