import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Microservices } from '@microservices';
import { LangEN } from 'interfaces/common/meta/interfaces';
import { LanguageServices } from './services/back-services/language.service';
import { ListenerService } from './services/listener/listener.service';
import { UserService } from './user.service';
import { CompanySettingsService } from './company-settings.service';
import { DocumentsService } from './documents.service';
import { EnumsService } from './enums.service';
import { MainService } from './services/main/main.service';
import { KeyEventsService } from './key-events.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  env = environment;
  bannerUrl = '';

  constructor(
    private micro: Microservices,
    private languageService: LanguageServices,
    private userService: UserService,
    private companySettingsService: CompanySettingsService,
    private documentsService: DocumentsService,
    private enumsService: EnumsService,
    private mainService: MainService,
    private keyEventsService: KeyEventsService
  ) { }

  async ngOnInit() {
    this.micro.setupListeners();
    this.mainService.setupListeners();
    this.userService.setupListeners();
    this.companySettingsService.setupListeners();
    this.documentsService.setupListeners();
    this.languageService.setupListeners();
    this.enumsService.setupListeners();
    this.keyEventsService.setupListeners();
    
    this.languageService.language.subscribe(lang => this.getDictionary(lang));
  }

  async getDictionary(lang?: string) {
    let defaultByHost = location.host.split('.').includes('dexcarga') ? 'es' : 'en';

    try {
      const language = lang ? lang : defaultByHost;
      this.micro.dictionary = sessionStorage.dictionary && sessionStorage.dictionary != 'undefined' && lang === undefined
        ? JSON.parse(sessionStorage.dictionary)
        : (<LangEN>await this.languageService.getDictionary(language));
      sessionStorage.dictionary = JSON.stringify(this.micro.dictionary);
      setTimeout(() => {
        this.bannerUrl = sessionStorage.getItem('lang') === 'en' ? '/assets/banner-en.png' : '/assets/banner-es.png';
      }, 300);
    } catch (error) {
      console.log(error)
    }
  }
}
