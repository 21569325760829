import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, takeLast, tap } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from '../../error-handlers/error-handler-service';
import { environment } from '../../../environments/environment';
import { Microservices } from '../../microservices';
import { of } from 'rxjs';

@Injectable()
export class MatchingToolService {
    private handleError: HandleError;
    previousId: string;
    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        public micro: Microservices
    ) {
        this.handleError = httpErrorHandler.createHandleError('Mathching tool service')
    }


    /**
     * Get maching percentage details
     * @param uniqueId 
     * @returns  "analysis": {
      "carrier_preference": "Medium Impact",
      "equipment_capacity": "Medium Impact",
      "negative_weights": "Low Impact"
    }
     */
    getMachingPercentageDetails(uniqueId: string) {
        const companyVariables = JSON.parse(localStorage.companyVariables)
        if (this.previousId != uniqueId) {
            const obj =
            {
                companyId: localStorage.companyId,
                database: environment.production ? 'production' : 'development',
                shipments: [{ shipments: { uniqueId: [uniqueId] } }],
                countLimit: 15,
                mode: "dictionary",
                matchPercentage: 0,
                "carrierPreference": companyVariables.variables.carrier_preference,
                equipmentCapacity: companyVariables.variables.equipment_capacity,
                historicData: companyVariables.variables.historic_data,
            }
            const apiUrl = `${environment.recommendationEngineHost}/recommendation_engine/model/analysis/`;
            this.previousId = uniqueId
            return this.http.post<any[]>(apiUrl, obj)
                .pipe(
                    catchError(this.handleError('getMachingPercentageDetails')),
                    takeLast(1),
                    tap(() => {
                        this.previousId = uniqueId
                    })
                );
        } else {
            const obsfrom1 = of(this.micro.matchingDetails);
            return obsfrom1
        }

    }

    /**
 * Get maching percentage details
 * @param uniqueId 
 * @returns  "analysis": {
  "carrier_preference": "Medium Impact",
  "equipment_capacity": "Medium Impact",
  "negative_weights": "Low Impact"
}
 */
    async getMachingPercentageDetailsTesting(uniqueId: string) {
        const companyVariables = localStorage.companyVariables ? JSON.parse(localStorage.companyVariables) : await this.getCompanyVariables()
        const obj =
        {
            companyId: localStorage.companyId,
            database: environment.production ? 'production' : 'development',
            shipments: [{ shipments: { uniqueId: [uniqueId] } }],
            countLimit: 15,
            mode: "dictionary",
            matchPercentage: 0,
            "carrierPreference": companyVariables.variables.carrier_preference,
            equipmentCapacity: companyVariables.variables.equipment_capacity,
            historicData: companyVariables.variables.historic_data,
        }

        const apiUrl = `${environment.recommendationEngineHost}/recommendation_engine/model/analysis/`;
        return this.http.post<any[]>(apiUrl, obj)
            .pipe(
                catchError(this.handleError('getMachingPercentageDetails',))
            );
    }

    getCompanyVariables() {
        const apiUrl = `${environment.recommendationEngineHost}/recommendation_engine/model/session_variables/`;
        this.http.post<any[]>(apiUrl, {}).toPromise().then((variables: any) => {
            localStorage.setItem('companyVariables', JSON.stringify({ ...variables }));
            return variables
        });


    }

}