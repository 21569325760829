import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { RoutesApp } from '../constants/Routes.constants';

@Injectable({
  providedIn: 'root'
})
export class NotDashboardGuard implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute) {
  }

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('token');
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParams = urlParams.get('token');
    let tokenInfo
    let redirectUrl;
    let matchFull;

    if (tokenParams || next.queryParams.token) {
      try {
        tokenInfo = jwt_decode(tokenParams || next.queryParams.token);
        if (next.queryParams && next.queryParams.redirectUrl) redirectUrl = next.queryParams.redirectUrl
        if (next.queryParams && next.queryParams.matchFull) matchFull = next.queryParams.matchFull
      } catch (error) {
        console.log({ error });
      }
    }

    if (!token) {
      if (next.queryParams.accessToken &&
        next.queryParams.token) {

        try {
          localStorage.setItem('token', next.queryParams.accessToken);
          let userInfo = jwt_decode(next.queryParams.token);
          localStorage.setItem('user', JSON.stringify(userInfo.user));
          localStorage.setItem('role', userInfo.user.role);
          localStorage.setItem('companyId', userInfo.user.companyId);
          localStorage.setItem('userId', userInfo.user._id);

          if (next.queryParams.shipmentId) {
            next.queryParams.drayage ? this.router.navigate([RoutesApp.Shipment.Drayage.Details(next.queryParams.shipmentId)]) : this.router.navigate([RoutesApp.Shipment.FTL.Details(next.queryParams.shipmentId)])
          } else {
            // Next action in details, check if the url has any action to do in details (sign rate, add Driver, sign agreement)
            if (tokenInfo && redirectUrl && !matchFull) {
              let shipType = redirectUrl.split('/')[4]
              let action = redirectUrl.split('/')[2]
              let id = redirectUrl.split('/')[5]
              shipType == 'ftl' ? this.router.navigate([`${RoutesApp.Shipment.FTL.Details(tokenInfo.shipmentId || id || next.queryParams.shipmentId)}`], { queryParams: { action: action } }) : this.router.navigate([`${RoutesApp.Shipment.Drayage.Details(tokenInfo.shipmentId || id || next.queryParams.shipmentId)}`], { queryParams: { action: action } });
            }

            if (tokenInfo && redirectUrl && matchFull) {
              this.router.navigate([redirectUrl])
            }
          }
        } catch (error) {
          console.log({ error });
        }
        return false
      } else if (next.queryParams.trackingToken) {
        localStorage.setItem('trackingToken', next.queryParams.trackingToken)
        if (next.queryParams.isFTL) {
          localStorage.setItem('isFTL', next.queryParams.isFTL)
        }
      }
      return true;
    } else {
      if (tokenInfo && !redirectUrl) {
        let shipType = redirectUrl.split('/')[4]
        let action = redirectUrl.split('/')[2]
        let id = redirectUrl.split('/')[5]
        if ((tokenInfo.shipmentId || next.queryParams.shipmentId) && (!tokenInfo.drayage || tokenInfo.drayage === undefined)) {
          // TODO WAIT FOR BACK TO SEND THE RIGHT COMPANY ID// if ((tokenInfo && (tokenInfo.user ? tokenInfo.user.companyId === localStorage.companyId : tokenInfo.companyId === localStorage.companyId)) && state.url.split('?')[0] === '/registry') {
          return this.router.navigate([`${RoutesApp.Shipment.FTL.Details(tokenInfo.shipmentId || next.queryParams.shipmentId)}`]);
        } else if (tokenInfo.drayage) {
          return this.router.navigate([`${RoutesApp.Shipment.Drayage.Details(tokenInfo.shipmentId || next.queryParams.shipmentId)}`]);
        } else if (state.url.includes('invite')) {
          return true
        }
      }
      // Next action in details, check if the url has any action to do in details (sign rate, add Driver, sign agreement)
      if (tokenInfo && redirectUrl && !matchFull) {
        let shipType = redirectUrl.split('/')[4]
        let action = redirectUrl.split('/')[2]
        let id = redirectUrl.split('/')[5]
        shipType == 'ftl' ? this.router.navigate([`${RoutesApp.Shipment.FTL.Details(tokenInfo.shipmentId || next.queryParams.shipmentId || id)}`], { queryParams: { action: action } }) : this.router.navigate([`${RoutesApp.Shipment.Drayage.Details(tokenInfo.shipmentId || next.queryParams.shipmentId || id)}`], { queryParams: { action: action } });
      } else if (tokenInfo && redirectUrl && matchFull) {
        this.router.navigate([redirectUrl])
      } else {
        if (['#/pricing-tool', '#/tour-planning'].includes(window.location.hash)) {
          const isTour: boolean = window.location.hash == '#/tour-planning' ? true : false;
          this.router.navigate([isTour ? RoutesApp.Premium.TourPlanning : RoutesApp.Premium.Pricing])
        }
        else this.router.navigate([RoutesApp.Shipment.Drayage.List]);
      }
      return false;
    }
  }
}
