import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutenticationType, ReportFiltersType } from './reports/reports.type';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsDashMexService {

  apiUrl = environment.analyticsURL;

  constructor(private _http: HttpClient) { }

  dashboardAuthentication(port: string, payload: AutenticationType): Promise<any> {
    return this._http.post(`${this.apiUrl}:${port}/api/v1/dashboard/login_dashboard`, payload).toPromise();
  }

  reportsAuthentication(port: string, payload: AutenticationType): Promise<any> {
    return this._http.post(`${this.apiUrl}:${port}/api/v1/reports/user/authentication`, payload).toPromise();
  }

  getReport(port: string, payload: ReportFiltersType, endpoint: string): Promise<any> {
    return this._http.post(`${this.apiUrl}:${port}/api/v1/reports/${endpoint}`, payload, { responseType: 'text' }).toPromise();
  }

  generateExcel(txtContent: string, fileName: string) {
    const lines = txtContent.split('\n');
    const data = lines.map((line) => line.split(','));

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, fileName + '.xlsx');
  }
}
