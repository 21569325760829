import { Injectable, Injector } from "@angular/core";
import dexFreightMS from "interfaces";
import { LangEN } from "interfaces/common/meta/interfaces/languages";
import { environment } from "../environments/environment";
import { AppConfig } from "./app.config";
import { UneteIO } from "./backDebugger";
import { CompanySettingsService } from "./company-settings.service";
import { Subscription } from "rxjs";

@Injectable()
export class Microservices {
  companySettingsService: CompanySettingsService;
  dexFreight: dexFreightMS;
  DexAuth;
  DexCore;
  RealTime;
  DexFuel;
  DexCoin;
  DexManager;
  Integrations;
  Stats;
  DocumentHub;
  Obs: dexFreightMS;
  Promises: any = {};
  Companies: any;
  Users: any;
  Shipments: any;
  companyVariables: any;
  matchingDetails: any;
  dictionary: LangEN;
  weightUnits: any;
  lengthUnits: any;
  coin: any;

  Modules = {};
  private userSettings: any;
  listeners: Subscription[] = [];

  constructor(private ac: AppConfig, private injector: Injector) {
    this.DexAuth = UneteIO.socket(environment.authUrl);
    this.DexCore = UneteIO.socket(environment.coreUrl);
    this.RealTime = UneteIO.socket(environment.realtimeUrl);
    this.Integrations = UneteIO.socket(environment.integrationsUrl);
    this.DocumentHub = UneteIO.socket(environment.docsUrl);
    this.DexManager = UneteIO.socket(environment.managerUrl);
    this.Obs = UneteIO.socket(environment.srcUrl);

    this.Companies = {};
    this.Users = {};
    this.Shipments = {};
  }

  setupListeners() {
    this.companySettingsService = this.injector.get(CompanySettingsService);
    
    this.listeners.push(
      this.companySettingsService.companySettings.subscribe(res => {
        this.userSettings = res;
        this.getDefaultSettings();
      })
    );
  }

  removeListeners() {
    this.listeners.forEach(listener => listener.unsubscribe());
    this.listeners = [];
  }

  async removeShipment(id: string) {
    const { _id, uniqueId } = this.Shipments[id];

    delete this.Shipments[_id];
    delete this.Shipments[uniqueId];
  }

  setShipment(ship: any) {
    var shipment = this.Shipments[ship._id];
    // console.log(this.Shipments)

    if (!shipment)
      return (this.Shipments[ship._id] = this.Shipments[ship.uniqueId] = ship);

    for (const i in ship) shipment[i] = ship[i];

    return shipment;
  }

  async sendTimeToLoad(module: string, time: number): Promise<void> {
    if (Object.keys(this.Modules).length > 0) {
      let last = Object.keys(this.Modules).slice(-1)[0];
      this.Modules[module] = this.Modules[last] + time;
      let key = Object.keys(this.Modules);
      let value = Object.values(this.Modules);
      let newObj = key.map((v, i) => {
        let previusValue = value[i - 1];
        return {
          event: v,
          totalTime: value[i],
          currentTime: previusValue
            ? Math.abs(
              parseFloat(<string>previusValue) - parseFloat(<string>value[i])
            )
            : value[i],
        };
      });
      // this.dexFreight.Events.registerLoadData(newObj, localStorage.getItem('token'))
    } else {
      this.Modules[module] = time;
    }
  }

  async updateShipment(
    _id: string,
    token: string = localStorage.getItem("token")
  ) {
    let shipment = await this.DexCore.Shipment.get(_id, token);
    await this.setBids(shipment);
    return this.setShipment(shipment);
  }

  async setBids(load) {
    if (!["new"].includes(load?._tradeRegistry?.state)) {
      //* Carrier case
      if (localStorage.getItem("role") === "carrier") {
        if (
          load?._tradeRegistry?.stakeholders?.carriers.indexOf(
            localStorage.getItem("userId")
          ) !== -1
        ) {
          load.bids = await this.DexCore.Bid.negotiationTonu(
            load._id,
            localStorage.getItem("token")
          );
        } else {
          load.bids = null;
        }
      } else if (
        load?.companyId === localStorage.getItem("companyId") &&
        load?._tradeRegistry?.stakeholders?.carriers.length > 0
      ) {
        load.bids = await this.DexCore.Bid.negotiations(
          load._id,
          localStorage.getItem("token")
        );
      }
    } else {
      load.bids = null;
    }
  }

  async getCompany(_id: string, token: string = localStorage.getItem("token")) {
    // return (
    //   this.Companies[_id] ||
    //   (this.Companies[_id] = await this.DexCore.Company.get(_id))
    // );
    const prefix = "Company:";
    if (this.Companies[_id]) return this.Companies[_id];
    if (this.Promises[prefix + _id]) return await this.Promises[prefix + _id];
    this.Promises[prefix + _id] = this.DexCore.Company.get(_id);
    this.Promises[prefix + _id]
      .then(() => delete this.Promises[prefix + _id])
      .catch(() => delete this.Promises[prefix + _id]);

    return (this.Companies[_id] = await this.Promises[prefix + _id]);
  }
  async getUser(_id: string, token: string = localStorage.getItem("token")) {
    // return (
    //   this.Users[_id] || (this.Users[_id] = await this.DexAuth.get(_id, token))
    // );
    const prefix = "User:";
    if (this.Users[_id]) return this.Users[_id];
    if (this.Promises[prefix + _id]) return await this.Promises[prefix + _id];
    this.Promises[prefix + _id] = this.DexAuth.get(_id, token);
    this.Promises[prefix + _id]
      .then(() => delete this.Promises[prefix + _id])
      .catch(() => delete this.Promises[prefix + _id]);

    return (this.Users[_id] = await this.Promises[prefix + _id]);
  }
  async getShipment(
    _id: string,
    token: string = localStorage.getItem("token")
  ) {
    // return this.Shipments[_id] || this.updateShipment(_id, token);
    const prefix = "Shipment:";
    if (this.Shipments[_id]) return this.Shipments[_id];
    if (this.Promises[prefix + _id]) return await this.Promises[prefix + _id];
    this.Promises[prefix + _id] = this.updateShipment(_id, token);
    this.Promises[prefix + _id]
      .then(() => delete this.Promises[prefix + _id])
      .catch(() => delete this.Promises[prefix + _id]);

    return (this.Shipments[_id] = await this.Promises[prefix + _id]);
  }

  static translate(name: string, data: any = {}) {
    let msg = JSON.parse(sessionStorage.dictionary)[name];

    for (const fieldName in data) {
      msg = msg.replace(new RegExp(`{{${name}}}`, "g"), data[fieldName]);
    }

    return msg;
  }

  /**
     * @param token
     * @returns User settings like language and configurations
     */
  async getUserSettings(token) {
    try {
      let userRole = localStorage.getItem('role');

      if (userRole === "super-admin") return {
        lang: 'en'
      };

      return await this.companySettingsService.companySettings.sync();
    } catch (e) {
      console.log(e)
    }
  }

  getDefaultSettings() {
    this.coin = this.userSettings.coin ?? "USD";
    this.weightUnits = this.userSettings.weight ?? "lb";
    this.lengthUnits = this.userSettings.length ?? "in";
  }

  get getCurrentSetting() {
    return this.userSettings
  }
}

export default <dexFreightMS>(
  UneteIO.socket(environment.srcUrl)
);
export const observablesSocket = <dexFreightMS>(
  UneteIO.socket(environment.srcUrl)
);
