import { Injectable } from '@angular/core';
import { Microservices } from '@microservices';
import { Subscription } from 'rxjs';
import { UserService } from './user.service';
import { CompanySettingsService } from './company-settings.service';
import { CacheListener } from './@utils/cache-listener';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  public companyDocuments: CacheListener<any> = new CacheListener(null, this.syncCompanyDocuments.bind(this));
  public hasCompanyRequiredDocsForBidding: CacheListener<boolean> = new CacheListener(null, this.syncHasCompanyRequiredDocsForBidding.bind(this));
  public docsEnum: Array<string> = ["w9", "insurance", "authority", "tax_constancy", "terms_and_conditions", "default_t&c"]

  private listeners: Subscription[] = [];

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private micro: Microservices,
    private companySettingsService: CompanySettingsService
  ) { }

  setupListeners() {
    this.listeners.push(
      this.companyDocuments.syncWhenChanges(this.companyService.company),
      this.hasCompanyRequiredDocsForBidding.syncWhenChanges(this.companyDocuments),
      this.hasCompanyRequiredDocsForBidding.syncWhenPropertiesChanges(
        this.companySettingsService.companySettings,
        ['workingRules', 'publicShipment']
      ),
      
      this.companyDocuments.bind(this.companyService.company),
      this.hasCompanyRequiredDocsForBidding.bind(this.companyDocuments)
    );
  }
  
  //* Syncs everything
  async syncCompanyDocuments () {
    const user = await this.userService.user.get();

    return await this.micro.DocumentHub.documents(user.companyId);
  }

  //* Syncs if the user has the required documents to accept or decline bids
  async syncHasCompanyRequiredDocsForBidding() {
    const settings = await this.companySettingsService.companySettings.get();
    const user = await this.userService.user.get();
    const role = user.role;
    let result: boolean = true;

    if (role !== "super-admin") {
      try {
        let doc = [];
        let { groups } = await this.companyDocuments.get();

        for (let docHub of groups) {
          if (docHub.docs.length && this.docsEnum.includes(docHub.type)) {
            doc.push(docHub.type);
          }
        }

        if (role == "shipper") {
          result = true;
        } else {
          result = await this.hasRequiredDocs(doc, settings.workingRules, settings.publicShipment);
        }
      } catch (e) {
        console.log(e);
        result = role === "shipper";
      }

      localStorage.setItem("@haveDocuments", result.toString());
    }
  }

  /** Determines if the user has the required documents to accept or decline bids */
  private async hasRequiredDocs(doc: string[], operation: string, isB2b: boolean) {
    const user = await this.userService.user.get();
    const role = user.role;

    if (operation === 'mx') {
      if (role !== 'carrier') {
        return doc.includes('tax_constancy') && (doc.includes('default_t&c') || doc.includes('terms_and_conditions'));
      }

      return doc.includes('tax_constancy');
    }
    
    return role !== 'carrier' ? ((isB2b && doc.includes('b2b') || !isB2b) && doc.includes('agreement') && doc.length >= 4) : (doc.includes('cp') || !doc.includes('cp') && doc.length >= 2);
  }
}
