import { Injectable, Renderer2, RendererFactory2, OnDestroy } from '@angular/core';
import { Subject, Subscription, filter } from 'rxjs';
import KeyEventCommand from './@enums/KeyEventCommand.enum';
import { Router } from '@angular/router';
import { RoutesApp } from './constants/Routes.constants';
import { MainService } from './services/main/main.service';
import { AuthService } from './services/back-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class KeyEventsService implements OnDestroy {

  commands: Subject<KeyEventCommand> = new Subject<KeyEventCommand>();

  private renderer: Renderer2;
  private listeners: Function[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    rendererFactory: RendererFactory2,
    private router: Router,
    private authService: AuthService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setupListeners() {
    this.listeners.push(
      this.renderer.listen('document', 'keyup', (event: KeyboardEvent) => { this.handleKeyUp(event); })
    );

    this.subscriptions.push(
      this.commands
        .pipe(filter(command => command === KeyEventCommand.DrayageCreate))
        .subscribe(() => this.router.navigate([RoutesApp.Shipment.Drayage.Create])),

      this.commands
        .pipe(filter(command => command === KeyEventCommand.DrayageList))
        .subscribe(() => this.router.navigate([RoutesApp.Shipment.Drayage.List])),

      this.commands
        .pipe(filter(command => command === KeyEventCommand.DrayageImport))
        .subscribe(() => this.router.navigate([RoutesApp.Shipment.Drayage.Import])),

      this.commands
        .pipe(filter(command => command === KeyEventCommand.Tracking))
        .subscribe(() => this.router.navigate([RoutesApp.Tracking.General])),

      this.commands
        .pipe(filter(command => command === KeyEventCommand.Invoices))
        .subscribe(() => this.router.navigate([RoutesApp.Invoice.List])),

      this.commands
        .pipe(filter(command => command === KeyEventCommand.Settings))
        .subscribe(() => this.router.navigate([RoutesApp.Settings.Main])),

      this.commands
        .pipe(filter(command => command === KeyEventCommand.Logout))
        .subscribe(() => this.authService.logout()),

      this.commands
        .pipe(filter(command => command === KeyEventCommand.Dashboard))
        .subscribe(() => this.router.navigate([RoutesApp.Dashboard]))
    )
  }

  private handleKeyUp(event: KeyboardEvent) {
    if (event.metaKey || event.ctrlKey) {
      if (Object.values(KeyEventCommand).includes(event.key as KeyEventCommand)) {
        this.commands.next(<any>event.key as KeyEventCommand);
      } else {
        console.log("Unknown key command: ALT + " + event.key, event); 
      }
    }
  }

  removeListeners() {
    this.listeners.forEach(unlisten => unlisten());
    this.listeners = [];

    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ngOnDestroy() {
    this.removeListeners();
  }
}