import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MainService } from '@services';
import { RoutesApp } from '../constants/Routes.constants';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(private router: Router, private activatedRouteSnapshot: ActivatedRoute, private ms: MainService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('token');
    if (token) {

      const { c: companyId } = next.queryParams;
      if (companyId) {
        if (companyId !== localStorage.companyId) {
          this.ms.clear();
          localStorage.setItem('shipmentListFilters', JSON.stringify(next.queryParams));
          this.router.navigate([RoutesApp.Login]);
        }
        return companyId === localStorage.companyId

      } else if (localStorage.role === 'super-admin') {
        return true;
      }
      return true;
    } else {
      if (next.queryParams) {
        localStorage.setItem('shipmentListFilters', JSON.stringify(next.queryParams));
      }
      this.router.navigate([RoutesApp.Login]);
      return false;
    }
  }
}
