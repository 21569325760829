import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../error-handlers/error-handler-service';
import { toursPayload } from '../../premium/tour-planning/tour-planning.component';

@Injectable({
  providedIn: 'root'
})
export class TourPlanningService {
  private handleError: HandleError
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('TourPlanning Service');
  }

  /**
   * Get all tous based on toursPayload
   * @param toursPayload json with the payload to seach the tour 
   * @returns array with all de tous availables
   */
  getTours(toursPayload: toursPayload) {
    const apiUrl = `${environment.tourPlanningHost}/tour_planning/model/tour_planning/`;
    return this.http.post(apiUrl, toursPayload)
      .pipe(
        catchError(this.handleError('getTours',))
      );
  }

  /**
   * Get tour history, returns an array with all the tours in a day
   */
  getTourHistory() {
    const apiUrl = `${environment.tourPlanningHost}/tour_planning/model/tour_history/`;
    return this.http.post(apiUrl, {})
      .pipe(
        catchError(this.handleError('GetTourHistory',))
      );

  }

  /**
   * Get the an array with all de possibles destinations based on origin equipment and date
   * @param payload 
   * @returns host destinations based on the payload
   */
  getHostDestinations(payload) {
    const apiUrl = `${environment.tourPlanningHost}/tour_planning/model/lane_search/`
    return this.http.post(apiUrl, payload)
      .pipe(
        catchError(this.handleError('GetHostDestinations',))
      );

  }

  /**
   *Get lane details 
   * @param laneId Id of the lane to get details
   * @returns lane details
   */
  getLaneDetails(laneId: string, tourQuery: any) {
    const payload = { ...tourQuery, options: { settings: { laneId } } }
    const apiUrl = `${environment.tourPlanningHost}/tour_planning/model/tour_details/`;
    return this.http.post(apiUrl, payload)
      .pipe(
        catchError(this.handleError('getLaneDetails',))
      );
  }


}

export interface historyPayload {
  "userId": string,
  "companyId": string,
  "database": string,
  "origin"?: string,
  "destination"?: string,
  "equipment"?: string,
  "deadhead"?: number,
  "pickups"?: number,
  "legLimit"?: number,
  "tourLength"?: number,
  "roundTrip"?: boolean,
  "availability"?: number,
  "expectedRevenue"?: number,
  "paginationLimit"?: number
}