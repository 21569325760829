<!--
  TODO: se comenta debido a futuros banners solo descomentar y reemplazar
  <div class="w-full">
  <a
    href="https://republic.com/dexfreight?utm_source=dexfreight&utm_medium=webapp&utm_campaign=republic"
    target="_blank"
  >
    <img [src]="bannerUrl" class="pointer w-100" />
  </a>
</div> -->
<router-outlet></router-outlet>
