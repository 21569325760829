import { Injectable } from '@angular/core';
import { Microservices } from '../../microservices';
declare let google;

@Injectable({
    providedIn: 'root'
})
export class UtilsServices {
    private Utils;
    constructor(public micro: Microservices) {
        this.Utils = this.micro.DexCore.Utils;
    }

    /**
     * @description Send a address | zipCode and get an Array will all suggestions from backend. If not find suggestions we go pass to search through Google maps and return the same obj
     * @param value The object that receives a property calls it "address". Check the GeoCode interface
     * @param type Can be a 'Filter' it'll return zip code, states and workingZones or 'Creation' only return zipcode and states
     * @returns Array Object
     */
    async getGeoCodeSuggestion(value: GeoCode, type: 'Filter' | 'Creation'): Promise<any[]> {
        let name = Object.keys(value)[0];
        let suggFinal = [];
        try {
            let suggestions: FilterSuggs = await this.Utils.geocodeSuggestionsExtended(value[name], this.micro.getCurrentSetting.workingRules);

            if (suggestions.zipCodes.length || suggestions.workingZones.length || suggestions.states.length) {
                if (type == 'Filter') {
                    const zipCodes = suggestions.zipCodes.map(suggestion => {
                        return {
                            ...suggestion,
                            formatted: `${suggestion.city}, ${suggestion.state}, ${suggestion.zip_code}`
                        }
                    });
                    const states = suggestions.states.map(suggestion => {
                        return {
                            ...suggestion,
                            formatted: `${suggestion.name}, ${suggestion.state}`
                        }
                    });
                    const workingZones = suggestions.workingZones;

                    suggFinal.push(...zipCodes, ...states, ...workingZones);
                    return suggFinal;
                } else {
                    const zipCodes = suggestions.zipCodes.map(suggestion => {
                        return {
                            ...suggestion,
                            formatted: `${suggestion.city}, ${suggestion.state}, ${suggestion.zip_code}`
                        }
                    });
                    const states = suggestions.states.map(suggestion => {
                        return {
                            ...suggestion,
                            formatted: `${suggestion.name}, ${suggestion.state}`
                        }
                    });
                    suggFinal.push(...zipCodes, ...states);
                    return suggFinal;
                }
            } else {
                suggFinal = (<any[]>await this.searchGoogle(name, value[name]));
                return suggFinal;
            }
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * This function is triggered only if the backend don't have suggestions 
     * @param name It's necessary to create a dinamic query to geoCode Google. IT'S INUSED FOR MOMENT
     * @param value Address | Zipcode
     */
    private async searchGoogle(name: string, value: string) {
        let sugg = [];
        let geocode = new google.maps.Geocoder();

        let suggGoogle = await new Promise(async (d, r) => {
            await geocode.geocode({ 'address': value }, (res, status) => {
                if (status === 'ZERO_RESULTS') return status;
                if (status === 'OK') {
                    let addressComponent = res[0].address_components
                    for (let x = 0; x < addressComponent.length; x++) {
                        let chk = addressComponent[x];
                        if (chk.types[0] == 'postal_code') {
                            sugg.push({
                                formatted: res[0].formatted_address,
                                latitude: res[0].geometry.location.lat(),
                                longitude: res[0].geometry.location.lng(),
                                zip_code: chk.long_name
                            });
                        }

                        d(sugg);
                    }
                }
            })
        });
        return suggGoogle;
    }

    addGoogleAutocompleteTo(elementId: string, country: string[] = ['US', 'CA', 'MX']): Promise<SuggestionPlace> {
        return new Promise((resolve, reject) => {
            const options = {
                strictBounds: true,
                componentRestrictions: { country },
                fields: ["formatted_address", "geometry", "name", "address_components"],
                types: ["address"]
            }
            const element = document.getElementById(elementId);
            let places = new google.maps.places.Autocomplete(element, options)
            places.addListener('place_changed', () => {
                let place = places.getPlace();

                let { lat, lng } = place.geometry.location;
                let state = place.address_components.filter(addr => addr.types.includes("administrative_area_level_1"))[0]?.short_name;
                let city = place.address_components.filter(addr => addr.types.includes("locality"))[0]?.short_name;
                let zip_code = place.address_components.filter(addr => addr.types.includes("postal_code"))[0]?.long_name;

                const latitude = lat();
                const longitude = lng();
                resolve({
                    latitude,
                    longitude,
                    address: place.name,
                    city,
                    state,
                    zip_code
                });
            })
        })
    }
}

type GeoCode = {
    [key in 'address']?: string
}
type FilterSuggs = {
    zipCodes: [{
        city: string
        country: string
        county: string
        latitude: number
        longitude: number
        state: string
        zip_code: string
        _id: string
    }],
    workingZones: string[],
    states: [{
        name: string,
        state: string,
        formatted?: string
    }]
};

type SuggestionPlace = {
    latitude: number,
    longitude: number,
    address: string,
    city: string,
    state: string,
    zip_code: string
}