import { Injectable } from '@angular/core';
import { Microservices } from '../../microservices';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(public micro: Microservices) { }

  /**
   * get rates by company id
   * @param companyId 
   */
  getRatingByCompany(companyId: string) {
    return this.micro.DexCore.Rate.getRates(companyId, localStorage.token);
  }

  /**
   * se rate to shipper, carrier or driver depends on payload
   * @param payload 
   * @returns 
   */
  setRate(payload: object) {
    return this.micro.DexCore.Rate.setRate(payload, localStorage.token);
  }
}
