import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgreedRatesService {

  agreedRatesToDuplicate = [];

  constructor() { }

  /**
   * Function to verify if the agreed rate contains more agreed rates or not
   * @param agreedRate 
   * @param param1 
   */
  validateMainAgredRate(agreedRate, checked): void {
    if (!agreedRate?.rates) {
      this.addAgreetRateToDuplicate(agreedRate, checked)
    } else {
      agreedRate?.rates?.forEach(ar => {
        this.addAgreetRateToDuplicate(ar, checked);
        let element = document.getElementById(`agreedId${ar._id}`) as HTMLInputElement | null;
        element.checked = checked;
      });
    }
  }

  addAgreetRateToDuplicate(agreedRate, checked): void {
    try {
      const RATE_ID = agreedRate?._id;

      if (checked) {
        const findResult = this.agreedRatesToDuplicate.find(ar => ar?._id === RATE_ID);
        !findResult && this.agreedRatesToDuplicate.push(agreedRate);
      } else {
        let index = null;
        this.agreedRatesToDuplicate.forEach((ar, i) => {
          index = ar?._id === RATE_ID ? i : null;
          return;
        });
        this.agreedRatesToDuplicate.splice(index, 1);
      }
    } catch (error) {
      console.log(error);
    }
  }

  getAgreedRatesToDuplicate(): any[] {
    return this.agreedRatesToDuplicate;
  }

  cleanAgreedRateToDuplicate(): void {
    this.agreedRatesToDuplicate = [];
  }
}
