import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BsModalService, ModalModule, TabsModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { environment } from '../environments/environment';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ROUTES } from './app.routes';
import { HttpErrorHandler } from './error-handlers/error-handler-service';
import { MessageService } from './error-handlers/message-service';
import { ErrorComponent } from './error/error.component';
import { Microservices } from './microservices';
import { MainService, ConstantsService, HeremapsService, ListenerService, MapsService, ObservablesService, PastDataService, RouterService } from '@services';
import { LanguageServices } from './services/back-services/language.service';
import TokenInterceptor from './services/interceptor/interceptor.interceptor';
// import {IConfig, NgxMaskModule} from 'ngx-mask';

const APP_PROVIDERS = [
  AppConfig
];

// export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
      useHash: true
    }),
    TabsModule.forRoot()
    // NgxMaskModule.forRoot(options)
  ],
  providers: [
    APP_PROVIDERS,
    MainService,
    ListenerService,
    PastDataService,
    ConstantsService,
    RouterService,
    BsModalService,
    Microservices,
    MapsService,
    HeremapsService,
    ObservablesService,
    AngularMaterialModule,
    HttpErrorHandler,
    MessageService,
    LanguageServices,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ]
})
export class AppModule { }
