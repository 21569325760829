import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MainService } from '../main/main.service';
import { ObservablesService } from '../observables/observables.service';

import { Microservices } from '../../microservices';
import { NotificationType } from 'src/app/@enums/NotificationType.enum';
import { NotificationTag } from 'src/app/@enums/NotificationTag.enum';
import { Notification } from 'src/app/@types/Notification.type';
import { AuthService } from '../back-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  notifications: Notification[] = [];
  listeners: Subscription[] = [];
  
  private queue: { skip: number, limit: number }[] = [];
  private queuePromise?: Promise<void>;


  constructor(
    private ms: MainService,
    public micro: Microservices,
    private obsService: ObservablesService,
    private authService: AuthService
  ) { }

  setupListeners () {
    this.listeners.push(
      this.obsService.notifications.subscribe(notification => this.notifications.unshift(notification)),
      this.authService.token.onClear(() => this.clear.bind(this))
    );
  }

  removeListeners () {
    this.listeners.forEach(unlisten => unlisten.unsubscribe());
    this.listeners = [];
  }

  async manageNotification(notification) {
    const { type, tags, data } = notification;

    switch (type) {
      case NotificationType.invoiceConfiguration:
        this.updateCartaPorteSection(type, data);
        break;

      case NotificationType.documentsAccepted,
        NotificationType.driverChanged,
        NotificationType.rateConfirmationGeneratedDrayage:
        this.ms.listener.dispatchEvent('updateShipmentDetails@drayage');
        if (type !== NotificationType.createDrayage) {
          this.ms.listener.dispatchEvent('update@drayageList', { show: true });
          this.ms.listener.dispatchEvent('updateShipmentDetails@drayage');
        }
        break;

      case NotificationType.trackingDrayage:
        this.ms.listener.dispatchEvent('trackingDrayage@drayage');
        break;

      case NotificationType.trackingDrayageSilent:
        this.ms.listener.dispatchEvent('trackingDrayageDelivered@drayage');
        break;

      case NotificationType.messageChat:
        this.ms.listener.dispatchMultiEvent('updateAllMessages@chat', { _id: notification.data.chatId });
        break;

      case NotificationType.shipmentCreated:
        this.ms.listener.dispatchEvent('isInShipmentList@@', { show: true });
        break;

      case NotificationType.shipmentStageChange:
        this.ms.listener.dispatchEvent('updateTimeline@timeline', notification.data.shipment);
        if (notification.data.action == 'DELIVERED'
          && localStorage.role === 'carrier') {
          this.ms.listener.dispatchEvent('openDeliveryAlert@notification', notification);
        }
        break;

      case NotificationType.documentSignedRate,
        NotificationType.documentSigned:
        if (tags.includes(NotificationTag.drayage)) {
          this.ms.listener.dispatchEvent('updateShipmentDetails@drayage');
          if (type !== NotificationType.createDrayage) {
            this.ms.listener.dispatchEvent('getOffers@dashboard-mex')
            this.ms.listener.dispatchEvent('getNotifications@dashboard-mex');
            this.ms.listener.dispatchEvent('getUnreadMessages@dashboard-mex')
            this.ms.listener.dispatchEvent('update@drayageList', { show: true });
            this.ms.listener.dispatchEvent('updateShipmentDetails@drayage');
          }
        } else {
          this.ms.listener.dispatchEvent('getCertificates@shipmentDetails');
        }
        break;

      case NotificationType.documentsAcceptedDrayage:
        this.ms.listener.dispatchEvent('getDocsCompany@drayageDetails');
        break;

      case NotificationType.bidAccepted:
        setTimeout(() => {
          this.obsService.updateShipment(data.shipmentId ? data.shipmentId : data.shipment)
        })
        break;

      case NotificationType.shipmentReadyForPickup:
        setTimeout(() => { this.ms.listener.dispatchEvent('getLoad@shipment/details'); }, 2000);
        break;

      case NotificationType.bidDeclined:
        this.ms.listener.dispatchEvent('activyLog@ShipDetails');
        break;

      case NotificationType.newAgreementUploaded,
        NotificationType.documentDeclined:
        if (notification.data.validation == 'ftl') {
          this.ms.listener.dispatchEvent('getCertificates@shipmentDetails');
          this.ms.listener.dispatchEvent('getLoad@shipment/details');
        } else {
          this.ms.listener.dispatchEvent('getDocsCompany@drayageDetails');
          this.ms.listener.dispatchEvent('updateShipmentDetails@drayage');
        }
        break;

      case NotificationType.quickbooksAuthUrl:
        window.open(notification.data.url, '', 'menubar=yes,location=yes,' +
          'resizable=yes,scrollbars=yes,status=no, width=700, height=700')
        break;

      case NotificationType.informationEditDrayage:
        await this.obsService.updateDrayage(data.shipmentId);
        this.ms.listener.dispatchEvent('updateInformation@NewDrayage');
        break;

      case NotificationType.editedServicesPaymentTableDrayageDetails:
        await this.obsService.updateDrayage(data.shipmentId);
        this.ms.listener.dispatchEvent('updatePayment@NewDrayage');
        this.ms.listener.dispatchEvent('Re-GeneratedRate@NewDetails');
        break;

      default:

        break;
    }

    /**
     * @description All this notifications trigger the reorden on new details
     */
    if (['informationEditDrayageSilent', 'informationEditDrayage', 'documentSignedDrayage',
      'saverRateConfirmationDrayage', 'editServicesDrayages', 'acceptedServicesDrayages', 'agreementDrayage'].includes(type)) {
      this.ms.listener.dispatchEvent("orderSeccion@NewDrayage");
    }

    /**
     * @description Update Table of payment when user accepted the new charges
     */
    if (['acceptedServicesDrayages'].includes(type)) {
      await this.obsService.updateDrayage(data.shipmentId);
      this.ms.listener.dispatchEvent('updatePayment@NewDrayage');
      this.ms.listener.dispatchEvent('Re-GeneratedRate@NewDetails');
    }

    /**
     * @description Update load when user generate invoice.
     */
    if (['generateDrayageInvoice'].includes(type)) {
      this.ms.listener.dispatchEvent("updateFindPreInvoice@NewDrayage", notification?.data?.shipmentId);
      this.ms.listener.dispatchEvent("updateTransactionModule@NewDrayage");
      this.ms.listener.dispatchEvent("refreshTracking@Project");
      this.ms.listener.dispatchEvent("updateInformation@ProjectDetails");
    }

    /**
     * @description Refresh rate seccion when shipment information change
     * Re-Generate Rate Confirmation when shipment information it's changed
     *
     */
    if ([NotificationType.informationEditDrayageSilent, NotificationType.informationEditDrayage].includes(type)) {
      await this.obsService.updateDrayage(data?.uniqueId);
      this.ms.listener.dispatchEvent('Re-GeneratedRate@NewDetails');
      this.ms.listener.dispatchEvent("RefreshRate@NewDetails");
      this.ms.listener.dispatchEvent('updateInformation@NewDrayage');
      this.ms.listener.dispatchEvent('updateShipment@DrayageList', notification?.data?.shipmentId)
    }
    /**
     * @description Update bids when negotiable
     */
    if ([NotificationType.auctionStartDrayage].includes(type)) {
      this.ms.listener.dispatchEvent('updateNegotiations@NewDrayage');
    }

    if ([NotificationType.bidAcceptedDrayageChat].includes(type)) {
      this.ms.listener.dispatchEvent('getOffers@dashboard-mex')
      this.ms.listener.dispatchEvent('getNotifications@dashboard-mex');
      this.ms.listener.dispatchEvent('getUnreadMessages@dashboard-mex')
      this.ms.listener.dispatchEvent('updateShipment@DrayageList', notification?.data?.drayage)
      this.ms.listener.dispatchEvent('updatePayment@NewDrayage');
      this.ms.listener.dispatchEvent('updateReferences@NewDrayage');
    }

    /**
     * @description Update the shipment docs for both sides and refresh the driver section for Carrier
     */
    if ([NotificationType.saverRateConfirmationDrayage, NotificationType.rateConfirmationGeneratedDrayage, NotificationType.generateBol].includes(type)) {
      this.ms.listener.dispatchEvent("RefreshRate@NewDetails");
      this.ms.listener.dispatchEvent("RefreshDriverAssigned@NewDetails")
      this.ms.listener.dispatchEvent('getNotification@NewDrayage');
      this.ms.listener.dispatchEvent("RefreshDocuments@NewDetails");
      this.ms.listener.dispatchEvent("RefreshCartaPorte");
    }

    /**
     * @description Update the permissions to show shipment information to Carrier
     */
    if ([NotificationType.saverRateConfirmationDrayage].includes(type)) {
      this.ms.listener.dispatchEvent("IsRateSigned@NewDrayage")
    }

    /**
     * @description Refresh the top bar when sign rate, generate rate and assign driver
     */
    if ([NotificationType.saverRateConfirmationDrayage, NotificationType.rateConfirmationGeneratedDrayage, NotificationType.driverAssigned].includes(type)) {
      if ([NotificationType.driverAssigned].includes(type)) {
        await this.obsService.updateDrayage(data?.shipment);
      }
      this.ms.listener.dispatchEvent("RefreshTopBar@NewDetails");
      this.ms.listener.dispatchEvent('updateShipment@DrayageList', notification?.data?.shipment)
    }

    if ([NotificationType.driverAssigned, NotificationType.driverChanged, NotificationType.driverAssignedLog].includes(type)) {
      await this.obsService.updateDrayage(data?.uniqueId || data?.shipment);

      this.ms.listener.dispatchEvent("RefreshDriver@NewDetails", notification?.data?.driverId);
      this.ms.listener.dispatchEvent("RefreshTopBar@NewDetails");
      this.ms.listener.dispatchEvent("refreshTracking@Project");
      this.ms.listener.dispatchEvent('updateShipment@DrayageList', notification?.data?.shipment)
      this.ms.listener.dispatchEvent("updateInformation@ProjectDetails");

      this.updateCartaPorteSection(type, data);
    }

    /**
    * @description Refresh documents/notification seccion and rate seccion when agreement has been signed
    */
    if ([NotificationType.documentSignedDrayage, NotificationType.agreementDrayage].includes(type)) {
      this.ms.listener.dispatchEvent("RefreshDocuments@NewDetails");
      this.ms.listener.dispatchEvent("RefreshCartaPorte");
      this.ms.listener.dispatchEvent("RefreshRate@NewDetails");
      this.ms.listener.dispatchEvent('getNotification@NewDrayage');
    }

    if ([NotificationType.bidDeclinedDrayage, NotificationType.bidAcceptedDrayage].includes(type)) {
      let id = notification?.data?.uniqueId || notification?.data?.drayageId;
      await this.obsService.updateDrayage(id);

      this.ms.listener.dispatchEvent('updateShipment@DrayageList', id)
      this.ms.listener.dispatchEvent('getOffers@dashboard-mex')
      this.ms.listener.dispatchEvent('getNotifications@dashboard-mex');
      this.ms.listener.dispatchEvent('getUnreadMessages@dashboard-mex')
      this.ms.listener.dispatchEvent('updateNegotiations@NewDrayage');
      this.ms.listener.dispatchEvent('updateInformation@NewDrayage');
    }

    /**
     * @description Update user permission on details
     */
    if ([NotificationType.bidAcceptedDrayageLog].includes(type)) {
      await this.obsService.updateDrayage(data?.drayage);
      this.ms.listener.dispatchEvent("updateUsers");
      this.ms.listener.dispatchEvent('updateReferences@NewDrayage');
    }

    /**
     * @description Order the seccion in details when carrier assign driver. Update notifications seccion.
     */
    if ([NotificationType.driverAssignedLog].includes(type)) {
      this.ms.listener.dispatchEvent("orderSeccion@NewDrayage");
      this.ms.listener.dispatchEvent('getNotification@NewDrayage');
    }

    /**
     * @description Order when drayage is delivered.
     */
    if ([NotificationType.deliveredDrayage, NotificationType.trackingDrayageSilent].includes(type)) {
      if (NotificationType.trackingDrayageSilent === type) {
        await this.obsService.updateDrayage(data?._id);
      }
      this.ms.listener.dispatchEvent("orderSeccion@NewDrayage");
    }

    /**
     * @description Reload Rate
     */
    if (['acceptedServicesDrayages', 'editServicesDrayages'].includes(type)) {
      this.ms.listener.dispatchEvent('updateReferences@NewDrayage');
    }
    // if (type === NotificationType.messageChat) {
    //   this.ms.listener.dispatchMultiEvent('updateAllMessages@chat', { _id: notification.data.chatId });
    // }

    if (tags.includes(NotificationTag.blockchain)) {
      const [type, _id] = notification.data.id.split(":");

      if (type === NotificationType.shipment) {
        notification.data.shipment = _id;
      }
    }

    if (notification && notification.tags && notification.tags.includes(NotificationTag.extracharge)) {
      this.ms.listener.dispatchEvent('getLoad@shipment/details');
      this.ms.listener.dispatchEvent('updateExtraChargeDetails');
    }

    await this.obsService.updateDrayage(data?.uniqueId || data?.shipmentId);
    this.ms.listener.dispatchEvent('getOffers@dashboard-mex')
    this.ms.listener.dispatchEvent('getNotifications@dashboard-mex');
    this.ms.listener.dispatchEvent('getUnreadMessages@dashboard-mex')
    this.ms.listener.dispatchEvent('updateShipmentDetails@drayage');
    this.ms.listener.dispatchEvent('getLoad@shipment/details');
    this.ms.listener.dispatchEvent('updateAllShipperStatus');
    this.ms.listener.dispatchEvent('updateAllCarrierStatus');
    this.ms.listener.dispatchEvent('isInShipmentList@@', { show: true });
  }

  async getUnreadNotifications() {
    const notifs = await this.getNotifications();
    const count = notifs.filter(notif => !notif.seen).length;

    return count;
  }

  getNotificationStream(): Observable<any> {
    return this.obsService.notifications;
  }

  async getNotifications({ limit, skip }: { limit: number, skip: number } = { limit: 50, skip: 0 }) {
    this.queue.push({ limit, skip });

    await this.processQueue();

    return await this.loadNotifications({ limit, skip });
  }

  async processQueue() {
    if (this.queue.length === 0) {
      delete this.queuePromise;
      return;
    }

    if (!this.queuePromise) {
      this.queuePromise = new Promise(async (resolve) => {
        while (this.queue.length > 0) {
          const { limit, skip } = this.queue.shift();
          
          await this.loadNotifications({ limit, skip });
        }
        resolve();
      });
    }

    await this.queuePromise;
    this.processQueue();
  }

  async loadNotifications ({ limit, skip }: { limit: number, skip: number } = { limit: 50, skip: 0 }) {
    const lastIndex = limit + skip;
    let isAnythingMissing = false;

    for (let i=skip; i < lastIndex; i++) {
      if (!this.notifications[i]) {
        isAnythingMissing = true;
        break;
      }
    }

    if (!isAnythingMissing) {
      return this.notifications.slice(skip, lastIndex);
    }

    const notifications = await this.micro.RealTime.Notifications.notifications(localStorage.token, {
      limit, skip
    });

    for (let i=0; i < notifications.length; i++) {
      this.notifications[skip + i] = notifications[i];
    }

    return this.notifications.slice(skip, lastIndex); 
  }

  notifySeen() {
    return this.micro.RealTime.Notifications.notifySeen(localStorage.token);
  }

  notifyRead(notification) {
    return this.micro.RealTime.Notifications.notifyRead(notification._id);
  }

  translate(name, data = {}) {
    let msg = JSON.parse(sessionStorage.dictionary)[name];
    for (const fieldName in data) {
      msg = msg.replace(new RegExp(`\\{\\{${fieldName}\\}\\}`, "g"), data[fieldName]);
    }
    return msg;
  }

  translateWithMessage(name, data = {}, message) {
    const dictionary = JSON.parse(sessionStorage.dictionary);
    if (!dictionary) return message;

    let msg = dictionary[name];
    let namedData = dictionary.$variables;

    if (msg && msg !== "") {
      for (const fieldName in data) {
        let ndata = namedData && namedData[fieldName] || {};
        msg = msg.replace(new RegExp(`\\{\\{${fieldName}\\}\\}`, "g"), ndata[data[fieldName]] || data[fieldName]);
      }
      return msg;
    } else {
      return message;
    }
  }

  async updateCartaPorteSection(type, data) {
    const { uniqueId } = data

    await this.obsService.updateDrayage(uniqueId);

    this.ms.listener.dispatchEvent('updateInformation@NewDrayage');

    // setTimeout(() => {
    //   this.ms.listener.dispatchEvent('updateAllShipperStatus')
    //   this.ms.listener.dispatchEvent('updateAllCarrierStatus')
    // }, 500);
    setTimeout(() => location.reload(), 500);

    this.ms.listener.dispatchEvent('updateShipment@DrayageList', uniqueId)
  }

  async clear () {
    this.notifications = [];
  }
}
