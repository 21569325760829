import { BehaviorSubject, Observer, ReplaySubject, Subject, distinctUntilChanged, distinctUntilKeyChanged, filter } from "rxjs";

export class CacheListener<T> extends ReplaySubject<T> {
    private currentValue: T;
    private promise: Promise<T>;
    private _sync: (...args: any[]) => Promise<T>;
    private _isSyncing: boolean = false; // Lock flag
    private _clearSubject: Subject<void> = new Subject();

    constructor(value: T, sync: (...args: any[]) => Promise<T>) {
        super(1);
        this.currentValue = value; // Initialize currentValue
        this._sync = sync;
        
        if (value !== null) this.next(value);
    }

    async get() {
        if (this.promise) return await this.promise;
        if (this.currentValue == null) return await this.sync();

        return this.currentValue;
    }

    async sync(...args: any[]) {
        if (this._isSyncing) return await this.promise;
        this._isSyncing = true; // Lock
        try {
            if (!this.promise) {
                this.promise = this._sync(...args);
                this.currentValue = await this.promise;
                this.next(this.currentValue);
            }
            return this.currentValue;
        } finally {
            this._isSyncing = false; // Unlock
            this.promise = null; // Reset promise
        }
    }

    async clear() {
        this.currentValue = null;
        this.promise = null;

        this._clearSubject.next();
    }

    onClear (observer: Partial<Observer<void>> | (() => void)) {
        return this._clearSubject.pipe(filter(() => this.currentValue == null)).subscribe(observer);
    }

    bind (listener: CacheListener<any>) {
        return listener.onClear(this.clear.bind(this));
    }

    syncWhenPropertyChanges<X> (listener: CacheListener<X>, property: keyof X, ) {
        return listener.pipe(distinctUntilKeyChanged(property)).subscribe(() => this.sync());
    }

    syncWhenPropertiesChanges<X> (listener: CacheListener<X>, properties: Array<keyof X>) {
        return listener.pipe(distinctUntilChanged((prev, cur) =>
            properties.every(prop => prev[prop] === cur[prop])
        )).subscribe(() => this.sync());
    }

    syncWhenChanges (listener: CacheListener<any>) {
        return listener.pipe(distinctUntilChanged((prev, cur) =>
            prev === cur
        )).subscribe(() => this.sync());
    }

    syncWhen<X> (listener: CacheListener<X>, callback: (prev: X, cur: X) => boolean) {
        return listener.pipe(distinctUntilChanged(callback)).subscribe(() => this.sync());
    }
}